<template>
  <div class="wd-100p-force d-flex flex-column align-items-center justify-content-center">
    <icon-button :icon="icon" :label="label" :description="description" :date="date" :class-name="'btn btn-sm pd-0 tx-12 ' + className" padding="2.5px 10px" :size="12" style="pointer-events: none"></icon-button>
  </div>
</template>

<script>
import VueFeather from "vue-feather";
import {ContentLoader} from "vue-content-loader";
import DistributionClass from "@/entities/Distributions/DistributionClass";
import IconButton from "@/elements/IconLink";
import DateFormatted from "@/elements/DateFormatted.vue";
export default {
  components: {ContentLoader, VueFeather, IconButton, DateFormatted},
  props: {
    item: { type: Object },
  },
  computed: {
    date: {
      get() {
        let result = null;

        switch (this.item.status) {
          case 'created': result = this.item.created_at; break;
          case 'active': result = this.item.started_at; break;
          case 'finished':
          case 'stopped': result = this.item.finished_at; break;
          default: result = null; break;
        }

        return result;
      }
    },
    description: {
      get() {
        let result = null;

        switch (this.item.status) {
          case 'paused_out_time': result = 'вне времени'; break;
          case 'paused_lead_limit': result = 'прев дн лимит'; break;
          case 'paused_lead_price_limit': result = 'прев цены лида'; break;
          default: result = null; break;
        }

        return result;
      }
    },
    icon: {
      get() {
        let result = 'star';

        switch(this.item.status) {
          case 'active': result = 'play'; break;
          case 'paused': result = 'pause'; break;
          case 'paused_out_time': result = 'pause'; break;
          case 'paused_lead_limit': result = 'pause'; break;
          case 'paused_lead_price_limit': result = 'pause'; break;
          case 'stopped': result = 'stop-circle'; break;
          case 'finished': result = 'check'; break;
          default: result = 'star'; break;
        }

        return result;
      }
    },
    className: {
      get() {
        let result = 'btn-outline-info';

        switch(this.item.status) {
          case 'active': result = 'btn-primary'; break;
          case 'paused': result = 'btn-outline-warning'; break;
          case 'paused_out_time': result = 'btn-outline-warning'; break;
          case 'paused_lead_limit': result = 'btn-outline-warning'; break;
          case 'paused_lead_price_limit': result = 'btn-outline-warning'; break;
          case 'stopped': result = 'btn-outline-danger'; break;
          case 'finished': result = 'btn-outline-success'; break;
          default: result = 'btn-outline-info'; break;
        }

        return result;
      }
    },
    label: {
      get() {
        let result = 'tx-info';

        switch(this.item.status) {
          case 'active': result = 'Запущено'; break;
          case 'paused': result = 'Пауза'; break;
          case 'paused_out_time': result = 'Пауза'; break;
          case 'paused_lead_limit': result = 'Пауза'; break;
          case 'paused_lead_price_limit': result = 'Пауза'; break;
          case 'stopped': result = 'Остановлено'; break;
          case 'finished': result = 'Закончено'; break;
          default: result = 'Создано'; break;
        }

        return result;
      }
    }
  }
}
</script>