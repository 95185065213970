<template>
  <div class="d-flex flex-row align-items-start justify-content-between wd-100p-force">
    <div class="card wd-600-force mx-wd-100p-force">
      <div class="card-body pd-2 stats-block-parent">
        <div class="d-flex flex-row align-items-start justify-content-end mg-rem-b-4 wd-100p-force">
          <icon-button :padding="'5px 10px'"
                       :disabled="!(items && items.length)"
                       :label="'Скачать Excel'"
                       :class-name="'btn btn-sm  tx-12 btn-outline-info'"
                       icon="download"
                       v-on:click="downloadExcel()"
          >
          </icon-button>
        </div>
        <div class="stats-block-expenses wd-100p-force">
          <data-table :columns="columns" :items="items" :loading="loading" :show-search="false" :items-cnt="count"></data-table>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
  .stats-block-expenses table {
    table-layout: fixed;
  }
</style>

<script>
import {h} from "vue";
import DistributionClass from "@/entities/Distributions/DistributionClass";
import DataTable from "@/elements/Table";
import IconButton from "@/elements/IconLink";
import {createConfirmDialog} from "vuejs-confirm-dialog";
import DialogDownloadExpenses from "@/elements/DialogDownloadExpenses";
import NumberFormatted from "@/elements/NumberFormatted.vue";

export default {
  components: {IconButton, DataTable, NumberFormatted },
  data() {
    return {
      Distributions: null,
      loading: false,
      count: 1,

      toggleInProgress: false,

      columns: [
        {
          header: 'Промежуток',
          width: '150px',
          value: (item) => { return h('div', {class: 'd-flex flex-row align-items-center justify-content-center wd-100p-force'}, [
              h('span', {class: 'tx-secondary wd-fit-content', style: 'margin-right: 2px;'}, item.days ?? ''),
              h('span', {}, item.period.replaceAll('.', '-'))
          ]) }
        },
        {
          header: 'Расходы',
          value: (item) => { return h(NumberFormatted, {class: 'wd-100p-force text-align-center', value: item.expense}) }
        }
      ],

      items: []
    }
  },
  methods: {
    init() {
      this.Distributions.expenses((items) => {
        this.items = items;
      });
    },
    downloadExcel() {
      if (this.items.length) {
        let pks = [];
        this.items.forEach((item) => {
          if(item.checked) {
            pks.push(item.id);
          }
        });

        const { reveal } = createConfirmDialog(h(DialogDownloadExpenses));
        reveal();
      }
    }
  },
  mounted() {
    this.Distributions = new DistributionClass(this);
    this.init();
  }
}
</script>