<template>
  <data-table :columns="columns" :items="items" :loading="loading" :items-cnt="count"></data-table>
</template>

<script>
import RegionsClass from "@/entities/Regions/RegionsClass";
import DataTable from "@/elements/Table";
import {h} from "vue";
import NumberFormatted from "@/elements/NumberFormatted.vue";

export default {
  components: { DataTable, NumberFormatted },
  data() {
    return {
      Regions: null,
      loading: false,
      count: 1,

      columns: [
        {
          header: 'ID',
          width: '100px',
          value: (item) => { return h(NumberFormatted, {class: 'wd-100p-force text-align-center', value: item.id}); }
        },
        {
          header: 'Размер',
          width: '150px',
          value: (item) => { return h(NumberFormatted, {class: 'wd-100p-force text-align-center', value: item.number_cnt}); }
        },
        {
          header: 'Название',
          value: (item) => { return item.region; }
        }
      ],
      items: []
    }
  },
  methods: {
    init() {
      this.Regions.list();
    }
  },
  mounted() {
    this.Regions = new RegionsClass(this);
    this.init();

    this.emitter.on('regions-list-changed', () => {
      this.init();
    });
    this.emitter.on('region-changed', (options) => {
      if (options.id) {
        this.Databanks.listItem(options.id, (changedItem) => {
          if (this.items.length && changedItem) {
            this.items.forEach((item, index) => {
              if (parseInt(item.id) === parseInt(changedItem.id)) {
                this.items[index] = changedItem;
              }
            })
          }
        })
      }
    })
  }
}
</script>