import BaseClass from "@/entities/BaseClass";
import {h} from "vue";
import Edit from "@/entities/Projects/Edit.vue";
import ModalCard from "@/elements/ModalCard.vue";
import Create from "@/entities/Projects/Create.vue";

export default class ProjectsClass extends BaseClass {
    urls = {
        list:   '/rest/projects/list',
        single: '/rest/projects/single',
        create: '/rest/projects/save',
        update: '/rest/projects/save',
        remove: '/rest/projects/delete',

        count:  '/rest/projects/count',
        empty:  '/rest/projects/empty'
    }

    list(resolve = null, reject = null, customFilter = {}) {
        super.count(() => {
            super.list(resolve, reject, customFilter)
        }, null, customFilter);
    }

    listItem(pk, resolve = null, reject = null, customFilter = {}) {
        this.makeRequest('list', null, Object.assign({filter: {id: pk}}, this.filter, customFilter), (data) => {
            const items = JSON.parse(JSON.stringify(data));
            const item = items.length > 0 ? items[0] : null;
            if (typeof resolve === 'function') {
                resolve(item);
            }
        }, reject);
    }

    update(resolve, reject) {
        super.update({model: this.instance.model}, (data) => {
            if (parseInt(data)) {
                this.instance.emitter.emit('project-changed', {id: data});
            }
            if (typeof resolve === 'function') {
                resolve(data);
            }
        }, reject);
    }

    create(resolve, reject) {
        super.create({model: this.instance.model}, (data) => {
            this.instance.emitter.emit('projects-list-changed');
            this.instance.emitter.emit('close-global-modal-force', {identity: 'create-project'});
            if (typeof resolve === 'function') {
                resolve(data);
            }
        }, reject);
    }

    remove(pk, resolve, reject) {
        super.remove(pk, () => {
            this.instance.emitter.emit('projects-list-changed');
            this.instance.emitter.emit('close-global-modal-force', {identity: 'project-edit-' + pk});
        }, reject);
    }

    createForm() {
        return this.instance.emitter.emit(
            'open-global-modal',
            {
                view: h(Create),
                header: h(ModalCard, {main: 'Создание нового проекта', additional: 'Заполните все обязательные поля'}),
                identity: 'create-project'
            }
        );
    }
    detailForm(item) {
        return this.instance.emitter.emit(
            'open-global-modal',
            {
                view: h(Edit),
                header: h(ModalCard, {main: item.name, additional: 'ID: ' + item.identifier}),
                identity: 'project-edit-' + item.id,
                id: item.id,
            }
        );
    }
}
