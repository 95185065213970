<script>
import VueFeather from "vue-feather";
import DatabankClass from "@/entities/Databanks/DatabanksClass";
import DistributionClass from "@/entities/Distributions/DistributionClass";
export default {
  components: { VueFeather },
  emits: ['confirm', 'cancel'],
  props: { distribution: { type: Object } },
  setup(props, ctx) {
    const confirmEvent = () => { ctx.emit('confirm'); }
    const cancelEvent = () => { ctx.emit('cancel'); }
    return {
      confirmEvent,
      cancelEvent
    }
  },
  data() {
    return {
      inProgress: false,
      action: 'add',
      databank_id: '',
      visible: false,

      databanks: [],
    }
  },
  methods: {
    sendDatabank() {
      this.inProgress = true;
      const Distribution = new DistributionClass(this);
      Distribution.attachDatabank(this.$props.distribution.id, this.databank_id, this.action, (response) => {
        this.emitter.emit('distribution-changed', {id: this.$props.distribution.id});
        this.emitter.emit('distribution-numbers-list-changed');
        this.hireCancel();
      }, () => {
        this.inProgress = false;
      });
    },
    hireConfirm() {
      this.visible = false;
      setTimeout(() => {
        this.confirmEvent();
      }, 300);
    },
    hireCancel() {
      this.visible = false;
      setTimeout(() => {
        this.cancelEvent();
      }, 300);
    }
  },
  mounted() {
    this.visible = true;
    (new DatabankClass({})).list((databanks) => {
      this.databanks = databanks;
    })
  }
}
</script>

<style>
.dialog-window .modal-body { background-color: white; height: 100vh; position: relative; }
.dialog-window {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2040;
  background-color: transparent;
}
.dialog-window_background {
  background-color: rgba(0,0,15,0.6);
  width: 100%;
  height: 100%;
}
.dialog-window_body_parent {
  width: 100%;
  height: 100%;
  position: absolute;
  top: -10vh;
  left: 0;
}
.dialog-window_body {
  display: block;
  width: 500px;
  height: fit-content;
}
.dialog-window_body .card,
.dialog-window_body .card-body {
  border-radius: 15px;
  padding: 20px 30px;
}
.dialog-window_body .card {
  border: none;
  box-shadow: none;
}
</style>

<template>
  <div class="dialog-window">
    <div class="dialog-window_background" @click="hireCancel"></div>
      <div class="dialog-window_body_parent d-flex flex-row align-items-center justify-content-center">
        <transition name="bounce">
          <div class="dialog-window_body" v-show="visible">
          <div class="card">
            <div class="card-body">
              <div class="d-flex flex-column align-items-center justify-content-start">
                <vue-feather :type="inProgress ? 'loader' : 'upload'" :size="50" :class="{'rotating': inProgress}" class="tx-info mg-rem-b-4"></vue-feather>
                <h4 class="tx-center">Загрузка базы номеров в рассылку</h4>
                <p class="tx-center">Выберите необходимую базу номеров и действие, которое будет выполнено с номерами в данной базе</p>
                <div class="form-group wd-100p-force pd-l-40 pd-r-40">
                  <select class="form-control form-control-select" v-model="action" :disabled="inProgress">
                    <option value="add">Добавить номера в рассылку</option>
                    <option value="delete">Удалить номера из рассылки</option>
                  </select>
                </div>
                <div class="form-group wd-100p-force pd-l-40 pd-r-40 mg-rem-t-1">
                  <select class="form-control mg-rem-r-2" v-model="databank_id" :disabled="inProgress">
                    <option value="">Не выбрано</option>
                    <option v-for="databank in databanks" :key="databank" v-bind:value="databank.id" v-html="databank.name"></option>
                  </select>
                </div>
                <div class="d-flex flex-row align-items-center justify-content-between pd-l-40 pd-r-40 mg-t-30 wd-100p-force">
                  <a href="javascript:void(0)" class="btn btn-sm" :class="{'btn-outline-info': action === 'add', 'btn-outline-warning': action === 'delete'}" :disabled="!databank_id || !action || inProgress" @click="sendDatabank">Загрузить базу</a>
                  <a href="javascript:void(0)" class="btn btn-sm btn-outline-secondary" :disabled="inProgress" @click="hireCancel">Отмена</a>
                </div>
              </div>

            </div>
          </div>
        </div>
        </transition>
      </div>
  </div>
</template>