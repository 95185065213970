<template>
  <div class="d-flex flex-row align-items-start justify-content-between wd-100p-force">
    <div class="wd-60p-force">
      <div class="card">
        <div class="card-body pd-2 mg-rem-b-4">
          <create :disabled="loading" :some-selected="someItemSelected" :search-enabled="searchEnabled" :create-in-progress="createInProgress"
                  @search="searchDbNumber"
                  @search-close="closeSearchDbNumber"
                  @create="addDbNumber"
                  @remove-founded="removeFounded"
                  @remove-all="removeAll"
                  @remove-selected="removeSelected"
                  @download="downloadExcel"
          ></create>
        </div>
        <div class="card-body pd-2">
          <div class="wd-100p-force">
            <data-table :columns="columnsActions" :items="[{}]" :max-height="16" :show-header="false" :show-search="false" :loading="false" :items-cnt="1" :full-height="false"></data-table>
          </div>
          <div class="wd-100p-force">
            <data-table :columns="columns" :items="items" :max-height="16" :show-header="false" :show-search="false" :loading="loading" :items-cnt="count"></data-table>
          </div>
          <div class="d-flex flex-row align-items-center justify-content-start wd-100p-force mg-rem-t-2 tx-right table-navigator" v-show="!loading && items.length">
            <div class="d-flex flex-column align-items-end justify-content-start wd-100p-force mg-rem-r-2">
              <b  style="color: var(--cui-dark) !important;">Страница:</b>
              <small  style="color: var(--cui-dark) !important;">{{ (count - ((page - 1) * maxOnPage)) - items.length + 1 }} - {{ count - ((page - 1) * maxOnPage) }} / {{ count }}</small>
            </div>
            <div class="form-group mg-l-auto flex-shrink-none">
              <select class="form-control form-control-select pagination-select" :disabled="loading" v-model="page">
                <option v-for="_page in maxPage" v-bind:value="_page" v-html="maxPage - _page + 1"></option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.pagination-select {
  border-color: var(--cui-gray-500) !important;
  text-align: right;
}
</style>

<script>
import {h} from 'vue';
import Create from "@/entities/Blacklist/Create";
import BlackList from "@/entities/Blacklist/BlackListClass";
import DataTable from "@/elements/Table";
import IconButton from "@/elements/IconLink";
import ByCallIcon from "@/entities/Blacklist/ByCallIcon";
import Distribution from "@/entities/Blacklist/Distribution";
import {createConfirmDialog} from "vuejs-confirm-dialog";
import DialogDownloadBlacklist from "@/elements/DialogDownloadBlacklist.vue";

export default {
  components: {IconButton, DataTable, Create, ByCallIcon, Distribution },
  props: {
  },
  data() {
    return {
      Blacklist: null,
      loading: false,
      count: 1,

      searchNumber: null,
      page: 1,
      maxOnPage: 100,

      totalSelected: false,
      searchEnabled: false,
      createInProgress: false,

      filter: {},

      columns: [
        {
          header: '',
          width: '25px',
          value: (item) => {
            return h('input', {
              class: 'flex-shrink-none',
              checked: item.checked,
              type: 'checkbox',
              onClick: () => { item.checked = !item.checked; }
            })
          }
        },
        {
          header: '',
          width: '25px',
          value: (item) => { return h(ByCallIcon, {item: item}) }
        },
        {
          header: 'Номер телефона',
          width: '200px',
          value: (item) => {
            return h('div', {
              class: 'wd-100p-force',
              style: 'min-width: 200px',
              onClick: () => { item.checked = !item.checked; }
            }, this.formatNumber(item.number + ''))
          }
        },
        {
          header: '',
          value: (item) => { return h(Distribution, {item: item}); }
        },
      ],

      items: [],
      createHandler: null,
    }
  },
  watch: {
    page: {
      handler() {
        this.init();
      }
    },
    totalSelected: {
      handler(val) {
        if (this.items.length) {
          this.items.forEach((item) => {
            item.checked = val;
          })
        }
      }
    }
  },
  computed: {
    columnsActions: {
      get() {
        return [
          {
            header: '',
            width: '25px',
            value: () => {
              return h('input', {
                class: 'flex-shrink-none',
                checked: this.totalSelected,
                type: 'checkbox',
                disabled: this.loading,
                onClick: () => { if (!this.loading) { this.totalSelected = !this.totalSelected } }
              })
            }
          },
          {
            header: '',
            float: 'right',
            value: () => {
              return h(IconButton, {
                className: 'btn btn-sm btn-link tx-12',
                icon: 'trash',
                label: 'Удалить выбранные',
                disabled: !this.someItemSelected,
                style: {'opacity': this.someItemSelected ? '1' : '0.3'},
                padding: '0',
                onClick: () => { this.removeSelected() }
              })
            }
          },
        ]
      }
    },
    someItemSelected: {
      get() {
        let result = false;
        if (this.items.length) {
          this.items.forEach((item) => {
            if (item.checked) { result = true; }
          })
        }
        return result;
      }
    },
    maxPage: { get() {
      const division = parseInt(parseInt(this.count) / this.maxOnPage);
      const reside = parseInt(this.count) - division * this.maxOnPage;
      return division + (reside > 0 ? 1 : 0);
    } }
  },
  methods: {
    formatNumber(number) {
      return '+' + number.substr(0, 1)+ ' (' + number.substr(1, 3)+ ') ' + number.substr(4,3) + '-' + number.substr(7,2) + '-' + number.substr(9, 2);
    },
    init() {
      let filter = {limit: this.maxOnPage, offset: (this.page - 1) * this.maxOnPage};
      if (this.searchNumber) {
        let search = this.searchNumber + '';
        if (search.length >= 11) {
          filter.filter = {'number': parseInt(search)};
        } else {
          let searchMin = search;
          let searchMax = search;
          for (let i = 0; i < (11-search.length); i++) {
            searchMin += '0';
            searchMax += '9';
          }
          filter.range = { '>=': ['number', searchMin], '<=': ['number', searchMax]};
        }
      }
      this.Blacklist.list(() => {
        this.totalSelected = false;
      }, null, filter);
    },
    searchDbNumber(number) {
      this.page = 1;
      this.searchEnabled = true;
      this.searchNumber = number;
      this.init();
    },
    closeSearchDbNumber() {
      this.page = 1;
      this.searchEnabled = false;
      this.searchNumber = '';
      this.init();
    },
    addDbNumber(number) {
      this.createInProgress = true;
      this.Blacklist.create(number, () => {
        this.emitter.emit('blacklist-changed');
        this.createInProgress = false;
        this.searchEnabled = false;
        this.init();
      }, () => {
        this.createInProgress = false;
        this.loading = false;
      });
    },
    removeFounded() {
      if (this.searchNumber) {
        let filter = null;

        let search = this.searchNumber + '';
        if (search.length >= 11) {
          filter = {filter: {'number': parseInt(search)}};
        } else {
          let searchMin = search;
          let searchMax = search;
          for (let i = 0; i < (11-search.length); i++) {
            searchMin += '0';
            searchMax += '9';
          }
          filter = {range: { '>=': ['number', searchMin], '<=': ['number', searchMax]}};
        }

        this.Blacklist.removeAll(() => {
          this.totalSelected = false;
          this.searchNumber = '';
          this.searchEnabled = false;
          this.emitter.emit('blacklist-changed')
          this.init();
        }, () => {
          this.loading = false;
          this.searchEnabled = false;
        }, filter);
      }
    },
    removeAll() {
      this.Blacklist.removeAll(() => {
        this.emitter.emit('blacklist-changed');
        this.init();
      }, () => {
        this.loading = false;
      });
    },
    removeSelected() {
      let pks = [];
      if (this.items.length) {
        this.items.forEach((item) => {
          if (item.checked) {
            pks.push(parseInt(item.number));
          }
        })
      }

      if (pks.length) {
        this.totalSelected = false;
        this.Blacklist.remove(pks, () => {
          this.emitter.emit('blacklist-changed');
          this.init();
        });
      }
    },
    downloadExcel() {
      if (this.items.length) {
        const { reveal } = createConfirmDialog(h(DialogDownloadBlacklist));
        reveal();
      }
    },
  },
  mounted() {
    this.Blacklist = new BlackList(this);
    this.init();

    this.emitter.on('blacklist-changed', () => {
        this.init();
    });
  }
}
</script>