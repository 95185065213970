<template>
  <form>
    <div v-full-height="true">
      <div class="form-group mb-4">
        <label class="form-label">Название <sup class="tx-danger">*</sup></label>
        <input type="text" v-model="model.name" class="form-control">
      </div>
      <div class="form-group mb-4">
        <label class="form-label">ID <sup class="tx-danger">*</sup></label>
        <input type="number" v-model="model.identifier" class="form-control">
      </div>
      <div class="d-flex flex-row align-items-start justify-content-between wd-100p-force mb-4">
        <div class="form-group wd-100p-force">
          <label class="form-label d-flex flex-row align-items-center justify-content-start" >
            <input type="checkbox" v-model="model.actual_bool">
            <div class="fw-500-force mg-rem-l-1" style="display: inline-block; font-size: 0.8rem;" >Актуальный проект</div>
          </label>
        </div>
      </div>
    </div>
    <div class="d-flex flex-row align-items-center justify-content-start pd-t-30 pd-b-30">
      <a href="javascript:void(0)" :disabled="!saveAllowed || loading" v-on:click="save" class="btn btn-sm btn-outline-primary d-flex flex-row align-items-center justify-content-start">
        <vue-feather class="mg-rem-r-1" type="plus" :size="14" v-if="!loading"></vue-feather>
        <vue-feather class="mg-rem-r-1" type="loader" :size="14" v-if="loading"></vue-feather>
        Добавить
      </a>
    </div>
  </form>
</template>

<script>
import ProjectsClass from "@/entities/Projects/ProjectsClass";
import VueFeather from "vue-feather";
export default {
  components: { VueFeather },
  data() {
    return {
      Projects: null,
      loading: false,
      uploadInProgress: false,

      model: {
        name: null,
        login: null,
        password: null,
      }
    }
  },
  computed: {
    saveAllowed: { get() { return this.model.name && this.model.identifier } }
  },
  mounted() {
    this.Projects = new ProjectsClass(this);
    this.Projects.empty();
  },
  methods: {
    save() { return this.Projects.create() }
  }
}
</script>