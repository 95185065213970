<template>
  <data-table :columns="columns" :items="items" :on-create-click="createHandler" :loading="loading" :items-cnt="count"></data-table>
</template>

<script>
import {h} from "vue";
import SoundClass from "@/entities/Sounds/SoundClass";
import DataTable from "@/elements/Table";
import DeleteButton from "@/entities/Sounds/DeleteButton";
import EditButton from "@/entities/Sounds/EditButton";
import Player from "@/entities/Sounds/Player";
import NumberFormatted from "@/elements/NumberFormatted.vue";
import DateFormatted from "@/elements/DateFormatted.vue";

export default {
  components: { DataTable, NumberFormatted, DateFormatted },
  data() {
    return {
      unmounted: false,

      Sounds: null,
      loading: false,
      count: 1,

      columns: [
        {
          header: 'ID',
          width: '100px',
          value: (item) => { return h(NumberFormatted, {class: 'wd-100p-force text-align-center', value: item.id}) }
        },
        {
          header: 'Дата создания',
          width: '200px',
          value: (item) => { return h(DateFormatted, {class: 'wd-100p-force text-align-center', value: item.created_at}) }
        },
        {
          header: '',
          width: '400px',
          value: (item) => { return h(Player, {
            file: item,
            filename: false,
            active: item.active ?? false,
            style: 'width: 400px'
          }) }
        },
        {
          header: 'Название',
          value: (item) => { return item.name; }
        },
        {
          header: '',
          width: '30px',
          float: 'right',
          value: (item) => { return h(EditButton, {item: item}) }
        },
        {
          header: '',
          width: '30px',
          float: 'right',
          value: (item) => { return h(DeleteButton, {item: item}) }
        },
      ],
      items: [],
      createHandler: null
    }
  },
  methods: {
    init() {
      this.Sounds.list(async () => {
        if (this.items.length) {
          for (const item of this.items) {
            if (this.unmounted) {
              break;
            }
            await new Promise(resolve => setTimeout(() => {
              item.active = true;
              resolve();
            }, 30));
          }
        }
      });
    }
  },
  unmounted() {
    this.unmounted = true;
  },
  mounted() {
    this.Sounds = new SoundClass(this);
    this.init();
    this.createHandler = () => {this.Sounds.createForm()};

    this.emitter.on('sounds-list-changed', () => {
      this.init();
    });

    this.emitter.on('sound-changed', (options) => {
      if (options.id) {
        this.Sounds.listItem(options.id, (changedItem) => {
          if (this.items.length && changedItem) {
            this.items.forEach((item, index) => {
              if (parseInt(item.id) === parseInt(changedItem.id)) {
                this.items[index] = changedItem;
              }
            })
          }
        })
      }
    })
  }
}
</script>