<template>
  <form>
    <div v-full-height="true">
      <div class="form-group mb-4">
        <label class="form-label">Название <sup class="tx-danger">*</sup></label>
        <input type="text" v-model="model.name" class="form-control">
      </div>
      <div class="form-group mb-4">
        <label class="form-label">Организатор получения базы на стороне Ф5</label>
        <multiselect class="multiselect-tagging ms-filter-orgs wd-100p-force"
                     placeholder="Введите новый элемент"
                     :close-on-select="false"
                     v-model="model.orgs"
                     :options="tagEmpty.orgs ? [] : tagsOrgs"
                     :multiple="true"
                     :taggable="true"
                     :show-no-options="false"
                     :show-no-result="false"
                     :hide-selected="true"
                     :options-limit="3"
                     :preserve-search="true"
                     @tag="addTagOrgs"
                     @search-change="searchChangedOrgs"
        >
        </multiselect>
      </div>
      <div class="form-group mb-4">
        <label class="form-label">Кто вёл РК, с которой пришёл контакт</label>
        <multiselect class="multiselect-tagging ms-filter-authors wd-100p-force"
                     placeholder="Введите новый элемент"
                     :close-on-select="false"
                     v-model="model.authors"
                     :options="tagEmpty.authors ? [] : tagsAuthors"
                     :multiple="true"
                     :taggable="true"
                     :show-no-options="false"
                     :show-no-result="false"
                     :hide-selected="true"
                     :options-limit="3"
                     :preserve-search="true"
                     @tag="addTagAuthors"
                     @search-change="searchChangedAuthors"
        >
        </multiselect>
      </div>
      <div class="form-group mb-4">
        <label class="form-label">Хэштеги откликов</label>
        <multiselect class="multiselect-tagging ms-filter-tags wd-100p-force"
                     placeholder="Введите новый элемент"
                     :close-on-select="false"
                     v-model="model.tags"
                     :options="tagEmpty.tags ? [] : tagsDefault"
                     :multiple="true"
                     :taggable="true"
                     :show-no-options="false"
                     :show-no-result="false"
                     :hide-selected="true"
                     :options-limit="3"
                     :preserve-search="true"
                     @tag="addTagTags"
                     @search-change="searchChangedTags"
        >
        </multiselect>
      </div>
      <block-group label="Номера" :components="numbersComponents"></block-group>
    </div>
    <div class="d-flex flex-row align-items-center justify-content-start pd-t-30 pd-b-30">
      <a href="javascript:void(0)" :disabled="!saveAllowed || loading" v-on:click="save" class="btn btn-sm btn-outline-primary d-flex flex-row align-items-center justify-content-start">
        <vue-feather class="mg-rem-r-1" type="plus" :size="14" v-if="!loading"></vue-feather>
        <vue-feather class="mg-rem-r-1" type="loader" :size="14" v-if="loading"></vue-feather>
        Добавить
      </a>
    </div>
  </form>
</template>

<script>
import DatabankClass from "@/entities/Databanks/DatabanksClass";
import VueFeather from "vue-feather";
import BlockGroup from "@/elements/BlockGroup";
import {h} from "vue";
import NumbersList from "@/entities/Databanks/Numbers/New/List";
import {createConfirmDialog} from "vuejs-confirm-dialog";
import DialogNewDatabankCreateWait from "@/elements/DialogNewDatabankCreateWait";
import Multiselect from 'vue-multiselect';

export default {
  components: { VueFeather, BlockGroup, Multiselect },
  data() {
    return {
      Databank: null,
      loading: false,
      uploadInProgress: false,

      numbersComponents: [
        h(NumbersList, {
          updateNumbers: (numbers) => { this.numbers = []; this.numbers = numbers ?? []; },
          updateFiles: (files) => { this.files = []; this.files = files ?? []; },
        })
      ],

      model: {
        name: null,
        orgs: [],
        authors: [],
        tags: []
      },

      tagEmpty: {
        orgs: true,
        authors: true,
        tags: true
      },

      tags: [],

      numbers: [],
      files: [],
    }
  },
  watch: {
    model: {
      handler(val) {
        if (val.name || val.path) {
          this.emitter.emit('add-close-break-point', {identity: 'create-databank', closeBreakPoint: true});
        } else {
          this.emitter.emit('add-close-break-point', {identity: 'create-databank', closeBreakPoint: false});
        }
      },
      deep: true
    }
  },
  computed: {
    saveAllowed: { get() { return this.model.name && ( this.numbers.length || this.files.length ) } },
    tagsOrgs: {
      get() {
        let result = [];

        if (this.tags.length) {
          this.tags.forEach((tag) => {
            if (tag.type === 'orgs') {
              result.push(tag.tag + ' ');
            }
          });
        }

        return result;
      }
    },
    tagsAuthors: {
      get() {
        let result = [];

        if (this.tags.length) {
          this.tags.forEach((tag) => {
            if (tag.type === 'authors') {
              result.push(tag.tag + ' ');
            }
          });
        }

        return result;
      }
    },
    tagsDefault: {
      get() {
        let result = [];

        if (this.tags.length) {
          this.tags.forEach((tag) => {
            if (tag.type === 'tags') {
              result.push(tag.tag + ' ');
            }
          });
        }

        return result;
      }
    }
  },
  mounted() {
    this.Databank = new DatabankClass(this);
    this.Databank.tags((data) => {
      this.tags = data;
      this.Databank.empty();
    })
  },
  methods: {
    searchChangedOrgs(query) { this.tagEmpty.orgs = (query.length === 0); },
    searchChangedAuthors(query) { this.tagEmpty.authors = (query.length === 0); },
    searchChangedTags(query) { this.tagEmpty.tags = (query.length === 0); },

    addTagOrgs(tag) { this.model.orgs.push(tag.trimEnd()); },
    addTagAuthors(tag) { this.model.authors.push(tag.trimEnd()); },
    addTagTags(tag) { this.model.tags.push(tag.trimEnd()); },

    save() {

      if (this.model.orgs.length) {
        this.model.orgs.forEach((tag, index) => {
          this.model.orgs[index] = tag.trimEnd();
        })
      }

      if (this.model.authors.length) {
        this.model.authors.forEach((tag, index) => {
          this.model.authors[index] = tag.trimEnd();
        })
      }

      if (this.model.tags.length) {
        this.model.tags.forEach((tag, index) => {
          this.model.tags[index] = tag.trimEnd();
        })
      }

      let numbers = [];
      let files = [];
      if (this.numbers.length) {
        this.numbers.forEach((number) => {
          numbers.push(number.number);
        });
      }
      if (this.files.length) {
        this.files.forEach((file) => {
          files.push(file.file);
        });
      }
      const { reveal } = createConfirmDialog(h(DialogNewDatabankCreateWait, {
        Class: this.Databank,
        model: this.model,
        numbers: numbers,
        files: files
      }));
      reveal();
    }
  }
}
</script>