<template>
  <div class="wd-100p-force text-align-center">
    <vue-feather v-if="item.actual == '1'" class="tx-success" type="check" :size="12"></vue-feather>
    <vue-feather v-if="item.actual !== '1'" class="tx-secondary" type="archive" :size="12"></vue-feather>
  </div>
</template>

<script>
import VueFeather from "vue-feather";
export default {
  components: { VueFeather },
  props: {
    item: { type: Object },
  }
}
</script>