<template>
  <div>
    {{value_formatted}}
  </div>
</template>
<script>
export default {
  props: ['value'],
  computed: {
    value_formatted: {
      get() {
        let result = [];
        let values = this.$props.value.toString().split(' ');

        if (values.length) {
          values.forEach((_value) => {
            let percent = false;
            if (_value.includes('%')) {
              _value = _value.replace('%', '');
              percent = true;
            }
            if (parseFloat(_value) && parseFloat(_value).toString() === _value) {
              result.push(parseFloat(_value).toLocaleString('ru-RU') + (percent ? '%' : ''));
            } else {
              result.push(_value + (percent ? '%' : ''));
            }
          })
        }

        return result.join(' ');
      }
    }
  }
}
</script>