<template>
  <form>
    <div v-full-height="true">
      <div class="form-group mb-4">
        <label class="form-label">Название <sup class="tx-danger">*</sup></label>
        <input type="text" v-model="model.name" class="form-control">
      </div>
      <div class="form-group wd-100p-force mb-5">
        <label class="form-label">Аудиозапись <sup  class="tx-danger" >*</sup></label>
        <player class="mg-rem-t-2" v-model:file="file" :upload="true"></player>
        <div class="d-flex flex-column align-items-start justify-content-start mt-4 wd-100p-force">
          <div class="d-flex flex-row align-items-start justify-content-between mb-3 wd-100p-force">
            <b class="d-flex flex-row align-items-center justify-content-start fw-400">
              <vue-feather class="mg-rem-r-1 tx-info" type="alert-circle" :size="14"></vue-feather>
              <span style="border-bottom: 1px solid var(--cui-gray-300)">Критерии загружаемых файлов:</span>
            </b>
          </div>
          <div class="d-flex flex-column align-items-start justify-content-start wd-100p-force fw-300 lh-em-3">
            <div class="d-flex flex-row align-items-start justify-content-start wd-100p-force">
              <b>Кодек:</b>
              <div class="mg-l-10 one-line-width">PCM_S16LE (Несжатый)</div>
            </div>
            <div class="d-flex flex-row align-items-start justify-content-start wd-100p-force">
              <b>Аудиоканалы:</b>
              <div class="mg-l-10 one-line-width">Моно (1.0)</div>
            </div>
            <div class="d-flex flex-row align-items-start justify-content-start wd-100p-force">
              <b>Частота:</b>
              <div class="mg-l-10 one-line-width">8000 Hz</div>
            </div>
            <div class="d-flex flex-row align-items-start justify-content-start wd-100p-force">
              <b>Громкость:</b>
              <div class="mg-l-10 one-line-width">Без изменений</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-row align-items-center justify-content-between pd-t-30 pd-b-30">
      <a href="javascript:void(0)" :disabled="!saveAllowed || loading" v-on:click="save" class="btn btn-sm btn-outline-success d-flex flex-row align-items-center justify-content-start">
        <vue-feather class="mg-rem-r-1" type="save" :size="14" v-if="!loading"></vue-feather>
        <vue-feather class="mg-rem-r-1" type="loader" :size="14" v-if="loading"></vue-feather>
        Сохранить
      </a>
      <a href="javascript:void(0)" class="btn btn-sm btn-link tx-danger d-flex flex-row align-items-center justify-content-start" v-on:click="remove">
        <vue-feather class="mg-rem-r-1" type="trash" :size="14"></vue-feather>
        Удалить
      </a>
    </div>
  </form>
</template>

<script>
import SoundClass from "@/entities/Sounds/SoundClass";
import VueFeather from "vue-feather";
import Player from "@/entities/Sounds/Player";
export default {
  props: { element: { type: Object }},
  components: { VueFeather, Player },
  data() {
    return {
      Sound: null,
      loading: false,
      uploadInProgress: false,

      model: {},
      pre_model: {},

      file: {
        path: null,
        filename: null,
        extension: null,
        size: null,
        allowed: null
      },
    }
  },
  watch: {
    file(val) {
      if (val.path) {
        this.model.path = val.path;
        this.model.filename = val.filename;
        this.model.extension = val.extension;
        this.model.size = val.size;
      }
    },
    saveAllowed: {
      handler(val) {
        this.emitter.emit('add-close-break-point', {identity: 'sound-edit-' + this.model.id, closeBreakPoint: val});
      }
    }
  },
  computed: {
    saveAllowed: {
      get() { return this.Sound ? this.Sound.itemHasBeenChanged() : false; }
    }
  },
  methods: {
    init() {
      this.Sound.item(this.$props.element.id, (data) => {
        this.file = JSON.parse(JSON.stringify(data));
      });
    },
    save() {
      this.Sound.update(() => {
        this.emitter.emit('add-close-break-point', {identity: 'sound-edit-' + this.model.id, closeBreakPoint: false});
        this.init();
      }, () => {
        this.loading = false;
      })
    },
    remove() {
      this.Sound.remove(this.$props.element.id);
    }
  },
  mounted() {
    this.Sound = new SoundClass(this);
    this.init();
  }
}
</script>