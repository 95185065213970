<template>
  <form>
    <div v-full-height="true">
      <block-group label="Базовые настройки" class="mb-4">
        <div class="form-group mb-4">
          <label class="form-label">Название <sup class="tx-danger">*</sup></label>
          <input type="text" v-model="model.name" class="form-control">
        </div>
        <div class="d-flex flex-row align-items-start justify-content-between wd-100p-force mb-4">
          <div class="form-group wd-48p-force">
            <label class="form-label">Скорость <sup class="tx-danger">*</sup></label>
            <select class="form-control mg-rem-r-2" v-model="model.speed">
              <option value="2400">2 400 / час</option>
              <option value="3200">3 200 / час</option>
              <option value="5800">5 800 / час</option>
              <option value="8000">8 000 / час</option>
              <option value="12000">12 000 / час</option>
              <option value="18000">18 000 / час</option>
              <option value="30000">30 000 / час</option>
            </select>
          </div>
          <div class="form-group wd-48p-force">
            <label class="form-label">Длительность набора</label>
            <input type="number" v-model="model.call_timeout" :class="{'readonly-distribution': isReadonly}" class="form-control">
          </div>
        </div>
        <div class="d-flex flex-row align-items-start justify-content-between wd-100p-force mb-2">
          <div class="form-group wd-100p-force">
            <label class="form-label d-flex flex-row align-items-center justify-content-start" >
              <input type="checkbox" v-model="model.unlimited_speed_bool">
              <div class="fw-500-force mg-rem-l-1" style="display: inline-block; font-size: 0.8rem;" >Без ограничений по скорости</div>
            </label>
          </div>
        </div>
        <div class="d-flex flex-row align-items-start justify-content-between wd-100p-force mb-2">
          <label class="form-label d-flex flex-row align-items-center justify-content-start"  :class="{'readonly-distribution': isReadonly}">
            <input type="checkbox" v-model="model.catch_press_bool">
            <div class="fw-500-force mg-rem-l-1" style="display: inline-block; font-size: 0.8rem;" >Запись нажатия клавиши "1"</div>
          </label>
        </div>
        <div class="d-flex flex-row align-items-start justify-content-between wd-100p-force mb-4">
          <label class="form-label d-flex flex-row align-items-center justify-content-start"  :class="{'readonly-distribution': isReadonly}">
            <input type="checkbox" v-bind:disabled="true" v-model="model.numbers_week_check_bool">
            <div class="fw-500-force mg-rem-l-1" style="display: inline-block; font-size: 0.8rem;" >Звонки не чаще чем раз в 3 дня</div>
          </label>
        </div>
        <div class="form-group wd-100p-force">
          <label class="form-label">Оператор <sup class="tx-danger">*</sup></label>
          <div class="d-flex flex-row align-items-start justify-content-between wd-100p-force ">
            <select class="form-control wd-100p-force mg-rem-r-2" v-model="model.call_operator">
              <option value="beeline" v-if="pre_model.call_operator === 'beeline'">Билайн - ИП Захаров</option>
              <option value="beeline-new">Билайн - ИП Язданов</option>
              <option value="megafon">Мегафон - ИП Язданов</option>
            </select>
          </div>
        </div>
      </block-group>

      <block-group label="Лиды" class="mb-4">
        <div class="form-group wd-100p-force mb-4">
          <label class="form-label">Необходимое число лидов <sup class="tx-danger">*</sup></label>
          <input type="number" v-model="model.leads_max" class="form-control">
        </div>
        <div class="d-flex flex-row align-items-start justify-content-between wd-100p-force">
          <div class="form-group wd-48p-force">
            <label class="form-label">Макс. лидов в день</label>
            <input type="number" v-model="model.leads_per_day" class="form-control">
          </div>
          <div class="form-group wd-48p-force mb-4">
            <label class="form-label">Макс. стоимость лида</label>
            <input type="number" v-model="model.leads_max_price" v-bind:disabled="!isAdmin" class="form-control">
          </div>
        </div>
        <div class="d-flex flex-row align-items-start justify-content-between wd-100p-force">
          <div class="form-group wd-100p-force">
            <label class="form-label d-flex flex-row align-items-center justify-content-start" >
              <input type="checkbox" v-model="model.leads_max_price_pause_bool">
              <div class="fw-500-force mg-rem-l-1" style="display: inline-block; font-size: 0.8rem;" >Пауза при превышении стоимости лида</div>
            </label>
          </div>
        </div>
      </block-group>

      <block-group label="Аудиозапись" class="mb-4">
        <div class="form-group wd-100p-force mb-4">
          <label class="form-label">Аудиозапись <sup class="tx-danger">*</sup></label>
          <div class="d-flex flex-row align-items-start justify-content-between wd-100p-force ">
            <select class="form-control wd-100p-force mg-rem-r-2" v-model="model.sound_id">
              <option value="">Не задано</option>
              <option v-for="sound in sounds" :key="sound" v-bind:value="sound.id" v-html="sound.name"></option>
            </select>
            <player class="wd-35p-force mg-rem-t-1" :file="sound"></player>
          </div>
        </div>
        <div>
          <div class="form-group wd-100p-force">
            <label class="form-label d-flex flex-row align-items-center justify-content-start" >
              <input type="checkbox" v-model="model.has_long_sound">
              <div class="fw-500-force mg-rem-l-1" style="display: inline-block; font-size: 0.8rem;" >Длинный ролик</div>
            </label>
          </div>
          <div class="d-flex flex-column align-items-start justify-content-start wd-100p-force mt-4" v-if="model.has_long_sound">
            <div class="d-flex flex-row align-items-start justify-content-between wd-100p-force">
              <div class="form-group wd-100p-force mg-rem-r-1">
                <label class="form-label">Длинный ролик <sup class="tx-danger">*</sup></label>
                <div class="d-flex flex-row align-items-start justify-content-between wd-100p-force ">
                  <select class="form-control wd-100p-force mg-rem-r-2" v-model="model.long_sound_id">
                    <option value="">Не задано</option>
                    <option v-for="sound in sounds" :key="sound" v-bind:value="sound.id" v-html="sound.name"></option>
                  </select>
                  <player class="wd-35p-force mg-rem-t-1" :file="long_sound"></player>
                </div>
              </div>
            </div>
            <div class="form-group wd-50p-force mg-rem-t-2">
              <label class="form-label">Задержка между роликами <sup class="tx-danger">*</sup></label>
              <input type="number" min="0" v-model="model.long_sound_timeout" class="form-control">
            </div>
          </div>
        </div>
      </block-group>

      <block-group label="Регионы" :components="regionsComponent" class="mb-4"></block-group>

      <block-group label="Планировщик" class="mb-4">
        <div class="mb-4">
          <div class="form-group wd-100p-force">
            <label class="form-label d-flex flex-row align-items-center justify-content-start">
              <input type="checkbox" v-model="model.planned">
              <div class="fw-500-force mg-rem-l-1" style="display: inline-block; font-size: 0.8rem;" >Запланировать рассылку</div>
            </label>
          </div>
          <div class="d-flex flex-row align-items-start justify-content-between wd-100p-force mt-4" v-if="model.planned">
            <div class="form-group wd-50p-force mg-rem-r-2">
              <label class="form-label">Дата начала <sup class="tx-danger">*</sup></label>
              <input type="date" v-model="model.planned_date" :disabled="model.status === 'active'" class="form-control">
            </div>
            <div class="form-group wd-50p-force mg-rem-l-2">
              <label class="form-label">Время начала <sup class="tx-danger">*</sup></label>
              <input type="time" v-model="model.planned_time" :disabled="model.status === 'active'" step="1" class="form-control">
            </div>
          </div>
        </div>
        <div>
          <div class="form-group wd-100p-force">
            <label class="form-label d-flex flex-row align-items-center justify-content-start">
              <input type="checkbox" v-model="model.restricted">
              <div class="fw-500-force mg-rem-l-1" style="display: inline-block; font-size: 0.8rem;" >Ограничить время и дни</div>
            </label>
          </div>
          <div class="d-flex flex-column align-items-start justify-content-start  mt-4" v-if="model.restricted">
            <div class="d-flex flex-row align-items-start justify-content-between wd-100p-force">
              <div class="form-group wd-50p-force mg-rem-r-2">
                <label class="form-label">Время начала <sup class="tx-danger">*</sup></label>
                <input type="time" v-model="model.restricted_time_start" :disabled="model.status === 'active'" step="1" class="form-control">
              </div>
              <div class="form-group wd-50p-force mg-rem-l-2">
                <label class="form-label">Время окончания <sup class="tx-danger">*</sup></label>
                <input type="time" v-model="model.restricted_time_stop" :disabled="model.status === 'active'" step="1" class="form-control">
              </div>
            </div>
            <div class="d-flex flex-row align-items-center justify-content-start mg-rem-t-2">
              <label class="form-label d-flex flex-row align-items-center justify-content-start mg-r-10">
                <input type="checkbox" v-model="model.restricts_days['1']">
                <div class="fw-500-force mg-rem-l-1" style="display: inline-block; font-size: 0.8rem;" >Пн.</div>
              </label>
              <label class="form-label d-flex flex-row align-items-center justify-content-start mg-r-10">
                <input type="checkbox" v-model="model.restricts_days['2']">
                <div class="fw-500-force mg-rem-l-1" style="display: inline-block; font-size: 0.8rem;" >Вт.</div>
              </label>
              <label class="form-label d-flex flex-row align-items-center justify-content-start mg-r-10">
                <input type="checkbox" v-model="model.restricts_days['3']">
                <div class="fw-500-force mg-rem-l-1" style="display: inline-block; font-size: 0.8rem;" >Ср.</div>
              </label>
              <label class="form-label d-flex flex-row align-items-center justify-content-start mg-r-10">
                <input type="checkbox" v-model="model.restricts_days['4']">
                <div class="fw-500-force mg-rem-l-1" style="display: inline-block; font-size: 0.8rem;" >Чт.</div>
              </label>
              <label class="form-label d-flex flex-row align-items-center justify-content-start mg-r-10">
                <input type="checkbox" v-model="model.restricts_days['5']">
                <div class="fw-500-force mg-rem-l-1" style="display: inline-block; font-size: 0.8rem;" >Пт.</div>
              </label>
              <label class="form-label d-flex flex-row align-items-center justify-content-start mg-r-10">
                <input type="checkbox" v-model="model.restricts_days['6']">
                <div class="fw-500-force mg-rem-l-1" style="display: inline-block; font-size: 0.8rem;" >Сб.</div>
              </label>
              <label class="form-label d-flex flex-row align-items-center justify-content-start">
                <input type="checkbox" v-model="model.restricts_days['7']">
                <div class="fw-500-force mg-rem-l-1" style="display: inline-block; font-size: 0.8rem;" >Вс.</div>
              </label>
            </div>
          </div>
        </div>
      </block-group>

      <block-group label="Webhook">
        <div class="form-group wd-100p-force">
          <label class="form-label d-flex flex-row align-items-center justify-content-start" >
            <input type="checkbox" v-model="model.has_webhook">
            <div class="fw-500-force mg-rem-l-1" style="display: inline-block; font-size: 0.8rem;" >Вебхук по окончанию звонка</div>
          </label>
        </div>
        <div class="d-flex flex-column align-items-start justify-content-start wd-100p-force mt-4" v-if="model.has_webhook">
          <div class="d-flex flex-row align-items-start justify-content-between wd-100p-force mb-2">
            <div class="form-group wd-100p-force mg-rem-r-1">
              <label class="form-label">URL <sup class="tx-danger">*</sup></label>
              <input type="text" v-model="model.webhook" class="form-control">
            </div>
          </div>
          <div class="d-flex flex-row align-items-start justify-content-between wd-100p-force">
            <div class="form-group wd-100p-force mg-rem-r-1">
              <label class="form-label">Проект <sup class="tx-danger">*</sup></label>
              <select class="form-control wd-100p-force mg-rem-r-2" v-model="model.project_id">
                <option value="">Не задано</option>
                <option v-for="project in projects" v-show="project && project.actual === '1'" :key="project" v-bind:value="project.id">
                  {{project.identifier}}: {{project.name}}
                </option>
              </select>
            </div>
            <div class="form-group wd-35p-force mg-rem-l-1">
              <label class="form-label">ID <sup class="tx-danger">*</sup></label>
              <input type="text" v-model="model.webhook_id" class="form-control">
            </div>
          </div>
          <div class="form-group wd-100p-force mg-rem-t-2">
            <label class="form-label d-flex flex-row align-items-center justify-content-start">
              <input type="checkbox" v-model="model.webhook_only_answered_bool">
              <div class="fw-500-force mg-rem-l-1" style="display: inline-block; font-size: 0.8rem;" >Только для нажавших "1"</div>
            </label>
          </div>
          <div class="form-group wd-100p-force mg-rem-t-2">
            <label class="form-label d-flex flex-row align-items-center justify-content-start">
              <input type="checkbox" v-model="model.webhook_sent_email_bool">
              <div class="fw-500-force mg-rem-l-1" style="display: inline-block; font-size: 0.8rem;" >Отправлять заявки на почту</div>
            </label>
          </div>
        </div>
      </block-group>
    </div>
    <div class="d-flex flex-row align-items-center justify-content-start pd-t-30 pd-b-30">
      <a href="javascript:void(0)" :disabled="!saveAllowed || loading" v-on:click="save" class="btn btn-sm btn-outline-primary d-flex flex-row align-items-center justify-content-start">
        <vue-feather class="mg-rem-r-1" type="plus" :size="14" v-if="!loading"></vue-feather>
        <vue-feather class="mg-rem-r-1" type="loader" :size="14" v-if="loading"></vue-feather>
        Добавить
      </a>
    </div>
  </form>
</template>

<script>
import DistributionClass from "@/entities/Distributions/DistributionClass";
import SoundsClass from "@/entities/Sounds/SoundClass";
import VueFeather from "vue-feather";
import Player from "@/entities/Sounds/Player";
import {TheMask,mask} from 'vue-the-mask';
import BlockGroup from "@/elements/BlockGroup";
import {h} from "vue";
import RegionsList from "@/entities/Distributions/Numbers/New/Regions";
import {createConfirmDialog} from "vuejs-confirm-dialog";
import DialogNewGeoDisitributionCreateWait from "@/elements/DialogNewGeoDisitributionCreateWait";
import Auth from "@/classes/Auth";

export default {
  components: { VueFeather, TheMask, Player, BlockGroup },
  directives: { mask },
  data() {
    return {
      Sounds: null,
      Distribution: null,
      loading: false,
      uploadInProgress: false,

      isAdmin: false,

      model: {},
      sounds: [],

      sound: { path: null },
      long_sound: { path: null },

      regions: [],

      regionsComponent: [
        h(RegionsList, {
          updateRegions: (regions) => { this.regions = []; this.regions = regions ?? []; },
        })
      ],
    }
  },
  watch: {
    'model.sound_id': {
      handler(val) {
        if (parseInt(val) > 0) {
          this.Sounds.item(val, (sound) => {
            this.sound = sound;
          })
        }
      }
    },
    'model.long_sound_id': {
      handler(val) {
        if (parseInt(val) > 0) {
          this.Sounds.item(val, (sound) => {
            this.long_sound = sound;
          })
        } else { this.long_sound = { path: null }; }
      }
    },
    model: {
      handler(val) {
        if (val.name || val.path) {
          this.emitter.emit('add-close-break-point', {identity: 'create-distribution', closeBreakPoint: true});
        } else {
          this.emitter.emit('add-close-break-point', {identity: 'create-distribution', closeBreakPoint: false});
        }
      },
      deep: true
    }
  },
  computed: {
    saveAllowed: { get() {
      if (this.model.name && this.model.sound_id && this.model.leads_max && this.model.speed) {
        if (this.model.planned) {
          if (!this.model.planned_date || !this.model.planned_time) {
            return false;
          }
        }

        if (this.model.restricted) {
          if (!this.model.restricted_time_start || !this.model.restricted_time_stop) {
            return false;
          }
        }

        if (this.model.has_webhook) {
          if (!this.model.webhook || !this.model.webhook_id) {
            return false;
          }
        }

        if (this.model.has_long_sound) {
          if (!this.model.long_sound_id || !this.model.long_sound_timeout) {
            return false;
          }
        }

        return true;
      }

      return false;
    } }
  },
  mounted() {
    this.Sounds = new SoundsClass({});
    this.Distribution = new DistributionClass(this);

    Auth.isAdmin().then((isAdmin) => {
      this.isAdmin = isAdmin;
      this.Distribution.empty(() => {
        this.Sounds.list((sounds) => {
          this.sounds = sounds;
        })
      });
    });
  },
  methods: {
    save() {
      const { reveal } = createConfirmDialog(h(DialogNewGeoDisitributionCreateWait, {
        Class: this.Distribution,
        model: this.model,
        regions: this.regions
      }));
      reveal();
    }
  }
}
</script>