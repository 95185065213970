<template>
  <div class="d-flex flex-row align-items-center justify-content-start mg-rem-b-2">
    <div class="card pd-0" style="margin-right: 20px; width: fit-content; min-width: 250px;">
      <div class="d-flex flex-row align-items-center justify-content-start">
        <div class="card-body d-flex flex-column align-items-center justify-content-start" style="color: black; padding: 0.7rem 2rem;">
          <number-formatted class="fw-400" v-bind:value="statsTotalCalls.value"></number-formatted>
          <small class="tx-black mt-1">звонков за текущий день</small>
          <date-formatted class="tx-secondary small" v-bind:value="statsTotalCalls.date"></date-formatted>
        </div>
      </div>
    </div>
    <div class="card pd-0" style="margin-right: 20px; width: fit-content; min-width: 250px;">
      <div class="d-flex flex-row align-items-center justify-content-start">
        <div class="card-body d-flex flex-column align-items-center justify-content-start" style="color: black; padding: 0.7rem 2rem;">
          <number-formatted class="fw-400" v-bind:value="statsSpeedAverage.value + ' / ч.'"></number-formatted>
          <small class="tx-black mt-1">ср. скорость за текущий день</small>
          <date-formatted class="tx-secondary small" v-bind:value="statsSpeedAverage.date"></date-formatted>
        </div>
      </div>
    </div>
    <div class="card pd-0" style="margin-right: 20px; width: fit-content; min-width: 250px;">
      <div class="d-flex flex-row align-items-center justify-content-start">
        <div class="card-body d-flex flex-column align-items-center justify-content-start" style="color: black; padding: 0.7rem 2rem;">
          <number-formatted class="fw-400" v-bind:value="statsTotalCallsHour.value"></number-formatted>
          <small class="tx-black mt-1">звонков за последний час</small>
          <date-formatted class="tx-secondary small" v-bind:value="statsTotalCallsHour.date"></date-formatted>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex flex-column align-items-start justify-content-start wd-100p-force">
    <div class="card wd-100p-force">
      <div class="card-body pd-2 mg-rem-b-4">
        <div class="d-flex flex-row align-items-center justify-content-start">
          <div class="input-group filter-input-group wd-30p-force">
            <input class="form-control" v-model="searchString" placeholder="Введите данные для поиска">
            <div class="input-group-append">
              <span class="input-group-text" v-on:click="searchString = null;">
                <vue-feather type="x" :size="14" :style="{
                  'color': searchString ? 'var(--cui-dark)' : 'var(--cui-light)'
                }"></vue-feather>
              </span>
            </div>
          </div>
          <div class="multiselect-parent mg-rem-l-1 d-flex flex-row align-items-center justify-content-start">
            <multiselect class="wd-450-force" :close-on-select="false" :disabled="loadInProgress" placeholder="Фильтр по статусам" :hide-selected="true"  v-model="filterStatus.model" track-by="name" label="name" :options="filterStatus.options" :searchable="false" :allow-empty="true" :multiple="true">
              <template #option="props">
                <div class="d-inline-block wd-fit-content" style="margin-right: 4px;">
                  <a class="d-flex flex-row align-items-center justify-content-center flex-shrink-none btn" :class="[props.option.btnClass]">
                    <vue-feather :type="props.option.icon" :size="11"></vue-feather>
                    <div class="mg-rem-l-1 option__desc" v-html="props.option.name"></div>
                  </a>
                </div>
              </template>
              <template #tag="props">
                <div class="d-inline-block wd-fit-content" style="margin-right: 4px;" @mousedown.prevent="">
                  <div class="d-flex flex-row align-items-start justify-content-center">
                    <a class="d-flex flex-row align-items-center justify-content-center flex-shrink-none btn" @mousedown.prevent="" :class="[props.option.btnClass]" style="padding: 3px 10px !important; border-top-right-radius: 0; border-bottom-right-radius: 0">
                      <vue-feather :type="props.option.icon" :size="14"></vue-feather>
                    </a>
                    <a class="d-flex flex-row align-items-center justify-content-center flex-shrink-none btn" @mousedown.prevent="filterStatus.removeOption(filterStatus, props.option)" :class="[props.option.className]" style="padding: 3px 10px !important; border-top-left-radius: 0; border-bottom-left-radius: 0">
                      <vue-feather v-show="!props.option.locked" type="x" :size="14" ></vue-feather>
                    </a>
                  </div>
                </div>
              </template>
            </multiselect>
            <icon-button :size="14"
                         :padding="'5px 10px'"
                         :disabled="loadInProgress"
                         :label="'Применить'"
                         :class-name="'multilselect-action btn btn-sm btn-outline-primary tx-12'"
                         icon="refresh-cw"
                         v-on:click="applyFilterStatus()"
            ></icon-button>
          </div>
          <icon-button v-on:click="createHandler"
                       label="Создать" icon="plus" :size="14" :padding="'5px 10px'" class-name="btn btn-sm btn-outline-primary tx-12" style="margin-left: auto;" />
          <icon-button v-on:click="createGeoHandler"
                       label="ГЕО-рассылка" icon="plus" :size="14" :padding="'5px 10px'" class-name="btn btn-sm btn-outline-primary tx-12" class="mg-rem-l-1" />
          <icon-button v-on:click="createTimezoneHandler"
                       label="TIMEZONE-рассылка" icon="plus" :size="14" :padding="'5px 10px'" class-name="btn btn-sm btn-outline-primary tx-12" class="mg-rem-l-1"  />

        </div>
      </div>
      <div class="card-body pd-2">
        <data-table :show-search="false" :columns="columns" :items="elements" :on-create-click="createHandler" :loading="loading" :items-cnt="count"></data-table>
      </div>
    </div>
  </div>
</template>

<script>
import {h} from "vue";
import DistributionClass from "@/entities/Distributions/DistributionClass";
import DataTable from "@/elements/Table";
import Status from "@/entities/Distributions/Status";
import Price from "@/entities/Distributions/Price";
import NameExtend from "@/entities/Distributions/NameExtend";
import EditButton from "@/entities/Distributions/EditButton";
import DeleteButton from "@/entities/Distributions/DeleteButton";
import ActionsButton from "@/entities/Distributions/ActionsButton";
import ReportButton from "@/entities/Distributions/ReportButton";
import LeadsCount from "@/entities/Distributions/LeadsCount";
import LeadPrice from "@/entities/Distributions/LeadPrice";
import Speed from "@/entities/Distributions/Speed";
import VueFeather from "vue-feather";
import IconButton from "@/elements/IconLink";
import Auth from "@/classes/Auth";
import Multiselect from 'vue-multiselect';

export default {
  components: { DataTable, VueFeather, IconButton, Multiselect },
  data() {
    return {
      Distributions: null,
      loading: false,
      count: 1,

      searchString: null,

      loadInProgress: false,
      filterStatus: {
        model: [
        ],
        options: [
          {name: 'Создано', value: 'created', icon: 'star', className: 'btn-outline-info', btnClass: 'btn-info'},
          {name: 'Запущено', value: 'active', icon: 'play', className: 'btn-outline-primary', btnClass: 'btn-primary'},
          {name: 'Пауза', value: 'pause', icon: 'pause', className: 'btn-outline-warning', btnClass: 'btn-warning'},
          {name: 'Остановлено', value: 'stopped', icon: 'stop-circle', className: 'btn-outline-danger', btnClass: 'btn-danger'},
          {name: 'Завершено', value: 'finished', icon: 'check', className: 'btn-outline-success', btnClass: 'btn-success'},
        ],
        removeOption: function(filter, option) {
          let _filter = JSON.parse(JSON.stringify(filter));
          _filter.model.forEach((_option, key) => {
            if (_option.value === option.value) {
              filter.model.splice(key, 1);
            }
          });
        }
      },

      globalStats: [],

      columns: [
        {
          header: 'Название',
          value: (item) => { return h(NameExtend, {item: item}); }
        },
        {
          header: 'Количество<br/>лидов',
          width: '150px',
          value: (item) => { return h(LeadsCount, {item: item}) }
        },
        {
          header: 'Стоимость<br/>лида',
          width: '150px',
          value: (item) => { return h(LeadPrice, {item: item}) }
        },
        {
          header: 'Состояние',
          width: '200px',
          value: (item) => { return h(Status, {item: item}) }
        },
        {
          header: 'Скорость',
          width: '150px',
          value: (item) => { return h(Speed, {item: item}) }
        },
        {
          header: 'Расход',
          width: '150px',
          value: (item) => { return h(Price, {item: item}); }
        },
        {
          header: '',
          width: '100px',
          value: (item) => { return h(ReportButton, {item: item}) }
        },
        {
          header: '',
          width: '120px',
          float: 'right',
          value: (item) => { return h(ActionsButton, {item: item}) }
        },
        {
          header: '',
          width: '30px',
          float: 'right',
          value: (item) => { return h(EditButton, {item: item}) }
        },
        {
          header: '',
          width: '30px',
          float: 'right',
          value: (item) => { return h(DeleteButton, {item: item}) }
        },

      ],
      items: [],
      createHandler: null,
      createGeoHandler: null,
      createTimezoneHandler: null
    }
  },
  computed: {
    elements: {
      get() {
        let result = [];
        if (this.items && this.items.length) {
          this.items.forEach((item) => {
            if (!this.searchString || JSON.stringify(item).toLowerCase().includes(this.searchString.toLowerCase())) {
              result.push(item);
            }
          })
        }

        return result;
      }
    },
    statsTotalCalls: {
      get() {
        let result = {value: '--', date: "--------- --:--:--"};
        if (this.globalStats.length > 0) {
          this.globalStats.forEach((globalStat) => {
            if (globalStat.name === 'total_calls_cur_day') {
              result = {value: globalStat.value, date: globalStat.updated_at};
            }
          })
        }

        return result;
      }
    },
    statsSpeedAverage: {
      get() {
        let result = {value: '--', date: "--------- --:--:--"};
        if (this.globalStats.length > 0) {
          this.globalStats.forEach((globalStat) => {
            if (globalStat.name === 'speed_average_cur_day') {
              result = {value: parseFloat(parseFloat(globalStat.value).toFixed(2)), date: globalStat.updated_at};
            }
          })
        }

        return result;
      }
    },
    statsTotalCallsHour: {
      get() {
        let result = {value: '--', date: "--------- --:--:--"};
        if (this.globalStats.length > 0) {
          this.globalStats.forEach((globalStat) => {
            if (globalStat.name === 'total_calls_cur_hour') {
              result = {value: globalStat.value, date: globalStat.updated_at};
            }
          })
        }

        return result;
      }
    }
  },
  methods: {
    applyFilterStatus() {
      localStorage.setItem('dists-filter-status', JSON.stringify(this.filterStatus.model));
      this.init();
    },

    reloadGlobalStats() {
      this.Distributions.globalStats((data) => {
        this.globalStats = data;
      })
    },
    init() {
      this.loadInProgress = true;

      let filter = {'filter': {'status': []}};
      let statuses = this.filterStatus.model.map(a => a.value);

      if (statuses.includes('created')) { filter.filter.status.push('created'); }
      if (statuses.includes('active')) { filter.filter.status.push('active'); }
      if (statuses.includes('pause')) {
        filter.filter.status.push('paused');
        filter.filter.status.push('paused_out_time');
        filter.filter.status.push('paused_lead_limit');
        filter.filter.status.push('paused_lead_price_limit');
      }
      if (statuses.includes('finished')) {
        filter.filter.status.push('finished');
      }
      if (statuses.includes('stopped')) {
        filter.filter.status.push('stopped');
      }

      this.Distributions.list((items) => {
        this.items = items;
        this.loadInProgress = false;
      }, null, filter);
    },
    refreshBlacklist() {
      this.Distributions.refreshBlacklist(() => {
        this.init();
      });
    },
    initTable() {
      Auth.isAdmin().then((isAdmin) => {
        if (isAdmin) {
          this.columns = this.columns.splice(0, 1).concat([{
            header: 'Автор',
            width: '200px',
            value: (item) => { return item.author?.name ?? '' }
          }]).concat(this.columns.splice(0, this.columns.length));
        }
      })
    }
  },
  mounted() {
    const storageFilter = localStorage.getItem('dists-filter-status');
    this.filterStatus.model = storageFilter ? JSON.parse(storageFilter) : [
      {name: 'Создано', value: 'created', icon: 'star', className: 'btn-outline-info', btnClass: 'btn-info'},
      {name: 'Запущено', value: 'active', icon: 'play', className: 'btn-outline-primary', btnClass: 'btn-primary'},
      {name: 'Пауза', value: 'pause', icon: 'pause', className: 'btn-outline-warning', btnClass: 'btn-warning'},
    ];

    this.Distributions = new DistributionClass(this);
    this.initTable();
    this.init();
    this.reloadGlobalStats();
    this.createHandler = () => {this.Distributions.createForm()};
    this.createGeoHandler = () => {this.Distributions.createGeoForm()};
    this.createTimezoneHandler = () => {this.Distributions.createTimezoneForm()};

    this.emitter.on('distributions-list-changed', () => {
      this.init();
    });

    this.emitter.on('distribution-changed', (options) => {
      if (options.id) {
        if (this.items.length) {
          this.items.forEach((item, index) => {
            if (parseInt(item.id) === parseInt(options.id)) {
              item.loading = true;
              this.Distributions.listItem(options.id, (changedItem) => {
                item.loading = false;
                this.items[index] = changedItem;
              });
            }
          })
        }
      }
    })
  }
}
</script>