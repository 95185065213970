import {h} from 'vue';
import BaseClass from "@/entities/BaseClass";
import ModalCard from "@/elements/ModalCard";
import Create from "@/entities/Distributions/Create";
import CreateGeo from "@/entities/Distributions/CreateGeo";
import CreateTimezone from "@/entities/Distributions/CreateTimezone";
import Edit from "@/entities/Distributions/Edit";
import Numbers from "@/entities/Distributions/Numbers";

export default class DistributionClass extends BaseClass {
    urls = {
        list:   '/rest/distributions/list',
        single: '/rest/distributions/single',
        create: '/rest/distributions/save',
        createGeo: '/rest/distributions/save-geo',
        update: '/rest/distributions/save',
        remove: '/rest/distributions/delete',

        count:  '/rest/distributions/count',
        empty:  '/rest/distributions/empty',

        listStats: '/rest/distributions/list-stats',
        listNames: '/rest/distributions/list-names',
        attachDatabank: '/rest/distributions/attach-databank',
        changeStatus: '/rest/distributions/change-status',
        cloneDistribution: '/rest/distributions/clone',
        report: '/rest/distributions/report',
        downloadStats: '/rest/distributions/stats',

        refreshBlacklist: '/rest/distributions/refresh-blacklist',

        expenses: '/rest/distributions/expenses',
        monthlyExpense: '/rest/distributions/monthly-expense',
        downloadExpenses: '/rest/distributions/download-expenses',

        globalStats: '/rest/distributions/global-stats',
        projects: '/rest/projects/list'
    }

    listNames(resolve = null, reject = null) {
        this.makeRequest('listNames', {}, {}, (response) => {
            if (typeof resolve === 'function') {
                resolve(response);
            }
        }, reject);
    }

    listProjects(resolve = null, reject = null, customFilter = {}) {
        this.loading = true;

        this.makeRequest('projects', {}, {}, (response) => {
            if (typeof resolve === 'function') {
                resolve(response);
            }
        }, reject);
    }

    globalStats(resolve = null, reject = null, customFilter = {}) {
        this.loading = true;

        this.makeRequest('globalStats', {}, {}, (response) => {
            if (typeof resolve === 'function') {
                resolve(response);
            }
        }, reject);
    }

    refreshBlacklist(resolve = null, reject = null, customFilter = null) {
        this.instance.loading = true;
        this.makeRequest('refreshBlacklist', null, Object.assign({}, this.filter, customFilter), (data) => {
            this.instance.loading = false;
            if (typeof resolve === 'function') {
                resolve();
            }
        }, reject);
    }

    list(resolve = null, reject = null, customFilter = {}) {
        super.count(() => {
            super.list(resolve, reject, customFilter)
        }, null, customFilter);
    }

    listItem(pk, resolve = null, reject = null, customFilter = {}) {
        this.makeRequest('list', null, Object.assign({filter: {id: pk}}, this.filter, customFilter), (data) => {
            const items = JSON.parse(JSON.stringify(data));
            const item = items.length > 0 ? items[0] : null;
            if (typeof resolve === 'function') {
                resolve(item);
            }
        }, reject);
    }

    listStats(resolve = null, reject = null, customFilter = {}) {
        super.count(() => {
            this.makeRequest('listStats', {}, Object.assign({}, this.filter, customFilter), (response) => {
                if (typeof resolve === 'function') {
                    resolve(response);
                }
            }, reject);
        }, null, customFilter);
    }

    expenses(resolve = null, reject = null) {
        this.makeRequest('expenses', {}, {}, (response) => {
            if (typeof resolve === 'function') {
                resolve(response);
            }
        }, reject);
    }

    monthlyExpense(resolve = null, reject = null) {
        this.makeRequest('monthlyExpense', {}, {}, (response) => {
            if (typeof resolve === 'function') {
                resolve(response);
            }
        }, reject);
    }

    cloneDistribution(pk, name, numbers, smart, resolve = null, reject = null, customFilter = {}) {
        this.loading = true;

        this.makeRequest('cloneDistribution', {pk: pk, name: name, numbers: numbers, smart: smart}, Object.assign({}, this.filter, customFilter), (response) => {
            if (typeof resolve === 'function') {
                resolve(response);
            }
        }, reject);
    }

    downloadStats(pks, resolve = null, reject = null, customFilter = {}) {
        this.loading = true;

        this.fileRequest('downloadStats', {pks: pks}, Object.assign({}, this.filter, customFilter), (response) => {
            if (typeof resolve === 'function') {
                resolve(response);
            }
        }, reject);
    }

    downloadExpenses(resolve = null, reject = null, customFilter = {}) {
        this.loading = true;

        this.fileRequest('downloadExpenses', {}, Object.assign({}, this.filter, customFilter), (response) => {
            if (typeof resolve === 'function') {
                resolve(response);
            }
        }, reject);
    }

    downloadReport(pk, resolve = null, reject = null, customFilter = {}) {
        this.loading = true;

        this.fileRequest('report', {pk: pk}, Object.assign({}, this.filter, customFilter), (response) => {
            if (typeof resolve === 'function') {
                resolve(response);
            }
        }, reject);
    }

    attachDatabank(pk, databank_id, action, resolve = null, reject = null, customFilter = {}) {
        this.loading = true;

        this.makeRequest('attachDatabank', {pk: pk, databank_id: databank_id, action: action}, Object.assign({}, this.filter, customFilter), (response) => {
            if (typeof resolve === 'function') {
                resolve(response);
            }
        }, reject);
    }

    update(resolve, reject) {
        super.update({model: this.instance.model}, (data) => {
            if (parseInt(data)) {
                this.instance.emitter.emit('distribution-changed', {id: data});
            }
            if (typeof resolve === 'function') {
                resolve(data);
            }
        }, reject);
    }

    changeStatus(pk, status, resolve, reject) {
        super.makeRequest('changeStatus', {pk: pk, status: status}, {}, (data) => {
            if (parseInt(data)) {
                this.instance.emitter.emit('distribution-changed', {id: data});
            }
            if (typeof resolve === 'function') {
                resolve(data);
            }
        }, reject);
    }

    create(model, numbers, databanks, resolve, reject) {
        super.create({model: model, numbers: numbers, databanks: databanks}, (data) => {
            this.instance.emitter.emit('distributions-list-changed');
            this.instance.emitter.emit('close-global-modal-force', {identity: 'create-distribution'});
            this.listItem(data, (item) => {
                this.numbersForm(item);
            }, null);
            if (typeof resolve === 'function') {
                resolve(data);
            }
        }, reject);
    }

    createGeo(model, regions, resolve, reject) {
        this.loading = true;

        this.makeRequest('createGeo', {model: model, regions: regions}, Object.assign({}, this.filter, {}), (data) => {
            this.instance.emitter.emit('distributions-list-changed');
            this.instance.emitter.emit('close-global-modal-force', {identity: 'create-distribution'});
            this.listItem(data, (item) => {
                this.numbersForm(item);
            }, null);
            if (typeof resolve === 'function') {
                resolve(data);
            }
        }, reject);
    }

    remove(pk, resolve, reject) {
        super.remove(pk, () => {
            this.instance.emitter.emit('distributions-list-changed');
            this.instance.emitter.emit('close-global-modal-force', {identity: 'distribution-edit-' + pk});
        }, reject);
    }

    createForm() {
        return this.instance.emitter.emit(
            'open-global-modal',
            {
                view: h(Create),
                header: h(ModalCard, {main: 'Создание новой рассылки', additional: 'Заполните все обязательные поля'}),
                identity: 'create-distribution'
            }
        );
    }
    createGeoForm() {
        return this.instance.emitter.emit(
            'open-global-modal',
            {
                view: h(CreateGeo),
                header: h(ModalCard, {main: 'Создание новой ГЕО-рассылки', additional: 'Заполните все обязательные поля'}),
                identity: 'create-distribution'
            }
        );
    }
    createTimezoneForm() {
        return this.instance.emitter.emit(
            'open-global-modal',
            {
                view: h(CreateTimezone),
                header: h(ModalCard, {main: 'Создание новой TIMEZONE-рассылки', additional: 'Заполните все обязательные поля'}),
                identity: 'create-distribution'
            }
        );
    }
    detailForm(item) {
        return this.instance.emitter.emit(
            'open-global-modal',
            {
                view: h(Edit),
                header: h(ModalCard, {main: item.name, additional: item.created_at}),
                identity: 'distribution-edit-' + item.id,
                id: item.id,
            }
        );
    }
    numbersForm(item) {
        return this.instance.emitter.emit(
            'open-global-modal',
            {
                view: h(Numbers),
                header: h(ModalCard, {main: item.name, additional: 'Список номеров для обзвона'}),
                identity: 'distribution-edit-' + item.id,
                id: item.id,
            }
        );
    }
}
