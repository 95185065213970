<template>
  <div class="d-flex flex-row align-items-center justify-content-start wd-100p-force">
    <div class="form-group wd-100p-force">
      <div class="input-group" :style="{'pointer-events': createInProgress ? 'none' : 'auto'}">
        <input type="text" v-mask="'# (###) ###-##-##'"  class="form-control tx-12 pd-l-10 pd-r-10 pd-t-4 pd-b-4" placeholder="Поиск номера" v-model="number" />
        <div class="input-group-append">
        <span class="input-group-text" v-on:click="number = ''; $emit('search-close')">
          <vue-feather type="x" :size="14" :style="{'opacity': searchEnabled ? '1' : '0', 'pointer-events': searchEnabled ? 'auto' : 'none'}" stroke="var(--cui-dark)"></vue-feather>
        </span>
        </div>
      </div>

    </div>
    <icon-button label="Найти" :disabled="!searchAllowed || disabled ||createInProgress" :icon="disabled ? 'loader' : 'search'" :size="14"
                 :class="{'btn-outline-info' : !searchEnabled, 'btn-info': searchEnabled}"
                 v-on:click="$emit('search', numberFormatted)" class="tx-12 btn btn-sm mg-rem-l-1"
                 :padding="'3px 10px'"
    ></icon-button>
    <icon-button :icon="'plus'"
                 v-on:click="openAddForm"
                 :size="14" :padding="'5px'" class="tx-12 btn-outline-success btn btn-sm mg-rem-l-3">
    </icon-button>
    <drop-down-button class="mg-rem-l-1" :items="links" :disabled="disabled || createInProgress"></drop-down-button>
  </div>
</template>

<script>
import IconButton from "@/elements/IconLink";
import {TheMask,mask} from 'vue-the-mask'
import VueFeather from "vue-feather";
import DropDownButton from "@/elements/DropDownButton";
import {createConfirmDialog} from "vuejs-confirm-dialog";
import {h} from "vue";
import DialogUploadExcel from "@/elements/DialogUploadExcel";
import DialogAddNumbers from "@/elements/DialogAddNumbers";
import LinksClass from "@/entities/Databanks/Numbers/LinksClass";

export default {
  components: {IconButton, TheMask, VueFeather, DropDownButton},
  emits: ['search', 'search-close', 'create', 'remove-founded'],
  directives: { mask },
  props: {
    databankId: { type: String },
    disabled: { type: Boolean, default: false },
    searchEnabled: false,
    createInProgress: false,
  },
  data() {
    return {
      number: ''
    }
  },
  methods: {
    openAddForm() {
      const { reveal } = createConfirmDialog(h(DialogAddNumbers, {
        NumbersClass: (new LinksClass(this)),
        entityId: this.$props.databankId,
        callback: () => {
          this.emitter.emit('databank-numbers-list-changed');
          this.emitter.emit('databank-changed', {id: this.$props.databankId});
        }
      }));
      reveal();
    }
  },
  computed: {
    links: {
      get() {
        const result = [
          {
            label: 'Загрузить excel',
            click: () => {
              const { reveal } = createConfirmDialog(h(DialogUploadExcel, {
                databank: { id: this.$props.databankId }
              }));
              reveal();
            }
          },
        ];

        if (this.searchEnabled) {
          result.push({
            label: 'Удалить найденные',
            click: () => { this.$emit('remove-founded'); }
          });
        }

        result.push({
              label: 'Удалить все',
              click: () => { this.$emit('remove-all'); }
        });

        return result;
      }
    },
    addAllowed: {
      get() {
        return this.numberFormatted.length === 11;
      }
    },
    searchAllowed: {
      get() {
        return this.numberFormatted.length >= 2;

      }
    },
    numberFormatted: {
      get() { return this.number ? this.number.replace(/\D/g,'') : ''; }
    }
  }
}
</script>