function calc(box, env) {
  if (env.value) {
    let heightInterval = null;
    const bodyHeight = document.body.clientHeight;
    const nextElement = box.nextSibling;

    if (parseInt(env.value) > 10) {
      box.style.height = parseInt(env.value) + 'vh';
      box.style.maxHeight = parseInt(env.value) + 'vh';
      box.style.overflowX = 'scroll';
    } else {
      clearInterval(heightInterval);
      heightInterval = setInterval(() => {
        const rect = box.getBoundingClientRect();
        if (rect.top) {
          let height = (bodyHeight - rect.top);
          if (nextElement) {
            height -= nextElement.clientHeight;
          }

          box.style.height = height + 'px';
          box.style.overflowX = 'auto';
          clearInterval(heightInterval);
        }
      },100);
    }
  }
}

const fullHeight = {
  mounted: (box, env) => {
    const _box = box;
    const _env = env;

    calc(_box, _env);

    window.addEventListener('resize', function() {
      calc(_box, _env);
    }, true);
  }
}

export default fullHeight;
