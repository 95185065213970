import { h} from 'vue';
import VueFeather from "vue-feather";
import Auth from "@/classes/Auth";

const NavItems = await Auth.isAdmin().then((isAdmin) => {
    return [
        {
            component: 'CNavTitle',
            allow: true,
            name: 'Панель управления',
        },
        {
            component: 'CNavItem',
            name: 'Рассылки',
            to: '/',
            allow: true,
            icon: h(VueFeather, {type: 'list', class: 'nav-icon'}),
        },
        {
            component: 'CNavItem',
            name: 'Статистика',
            to: '/stats',
            allow: true,
            icon: h(VueFeather, {type: 'activity', class: 'nav-icon'}),
        },
        {
            component: 'CNavItem',
            name: 'Стоп лист',
            to: '/blacklist',
            allow: true,
            icon: h(VueFeather, {type: 'alert-circle', class: 'nav-icon'}),
        },
        {
            component: 'CNavItem',
            name: 'Аудиозаписи',
            to: '/sounds',
            allow: true,
            icon: h(VueFeather, {type: 'mic', class: 'nav-icon'}),
        },
        {
            component: 'CNavItem',
            name: 'Базы номеров',
            to: '/numbers',
            allow: true,
            icon: h(VueFeather, {type: 'database', class: 'nav-icon'}),
        },
        {
            component: 'CNavItem',
            name: 'Номера по регионам',
            to: '/regions',
            allow: isAdmin,
            icon: h(VueFeather, {type: 'database', class: 'nav-icon'}),
        },
        {
            component: 'CNavItem',
            name: 'Статистика по номерам',
            to: '/stats-numbers',
            allow: true,
            icon: h(VueFeather, {type: 'activity', class: 'nav-icon'}),
        },
        {
            component: 'CNavItem',
            name: 'Расходы',
            to: '/expenses',
            allow: true,
            icon: h(VueFeather, {type: 'credit-card', class: 'nav-icon'}),
        },
        {
            component: 'CNavItem',
            name: 'Проекты',
            to: '/projects',
            allow: true,
            icon: h(VueFeather, {type: 'folder', class: 'nav-icon'}),
        },
        {
            component: 'CNavItem',
            name: 'Настройки',
            to: '/accounts',
            allow: isAdmin,
            icon: h(VueFeather, {type: 'users', class: 'nav-icon'}),
        },
        {
            component: 'CNavItem',
            name: 'Логи сервиса',
            to: '/logs',
            allow: isAdmin,
            icon: h(VueFeather, {type: 'box', class: 'nav-icon'}),
        },
    ]
});

export default NavItems;
