<template>
  <div v-if="item.distribution" class="one-line-width wd-95p-force">{{item.distribution}}</div>
</template>

<script>
export default {
  components: {  },
  props: {
    item: { type: Object },
  }
}
</script>