<template>
  <drop-down-button label="Действия" :items="links"></drop-down-button>
</template>

<script>
import VueFeather from "vue-feather";
import DropDownButton from "@/elements/DropDownButton";
import DistributionClass from "@/entities/Distributions/DistributionClass";
import {createConfirmDialog} from "vuejs-confirm-dialog";
import {h} from "vue";
import DialogCloneDistribution from "@/elements/DialogCloneDistribution";

export default {
  components: {DropDownButton, VueFeather},
  props: {
    item: { type: Object },
  },
  computed: {
    links: {
      get() {
        let result = [];

        if (this.$props.item && ( this.$props.item.status === 'created' || this.$props.item.status === 'paused')) {
          result.push({
            label: 'Запустить',
            click: () => {
              const Distribution = new DistributionClass(this);
              Distribution.changeStatus(this.$props.item.id, 'active');
            }
          });
        }

        if (this.$props.item && this.$props.item.status === 'active') {
          result.push({
            label: 'Пауза',
            click: () => {
              const Distribution = new DistributionClass(this);
              Distribution.changeStatus(this.$props.item.id, 'paused');
            }
          });
        }

        if (this.$props.item && this.$props.item.status !== 'stopped' && this.$props.item.status !== 'finished') {
          result.push({
            label: 'Остановить',
            click: () => {
              const Distribution = new DistributionClass(this);
              Distribution.changeStatus(this.$props.item.id, 'stopped');
            }
          });
        }

        result.push({
          label: 'Управление момерами',
          click: () => {
            const Distribution = new DistributionClass(this);
            Distribution.numbersForm(this.$props.item);
          }
        });

        result.push({
          label: 'Скопировать',
          click: () => {
            const { reveal } = createConfirmDialog(h(DialogCloneDistribution, {
              distribution: this.$props.item
            }));
            reveal();
          }
        });

        return result;
      }
    }
  }
}
</script>