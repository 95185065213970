function calc(box) {
  box.addEventListener('click', function() {
    let positionInterval = null;
    const bodyHeight = document.body.clientHeight;
    const bodyWidth = document.body.clientWidth;

    box.querySelector('.dropdown-menu').style.opacity = 0;
    box.querySelector('.dropdown-menu').style.position = 'fixed';
    clearInterval(positionInterval);
    positionInterval = setInterval(() => {
      const rect = box.getBoundingClientRect();
      if (rect.top) {
        if (parseInt(rect.top) <= parseInt(parseInt(bodyHeight) / 2)) {
          box.querySelector('.dropdown-menu').style.top = (rect.top + 25) + 'px';
          box.querySelector('.dropdown-menu').style.right = (bodyWidth - rect.right) + 'px';
        } else {
          box.querySelector('.dropdown-menu').style.bottom = 25 + 'px';
          box.querySelector('.dropdown-menu').style.right = (bodyWidth - rect.right) + 'px';
        }
        setTimeout(() => {
          box.querySelector('.dropdown-menu').style.opacity = 1;
        }, 10);
        clearInterval(positionInterval);
      }
    },10)
  });
}

const fullMaxIndexedHeight = {
  mounted: (box, env) => {
    calc(box);
  },
}

export default fullMaxIndexedHeight;
