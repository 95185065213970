import BaseClass from "@/entities/BaseClass";

export default class LogsClass extends BaseClass {
    urls = {
        count:  '/rest/logs/count',
        list:   '/rest/logs/list'
    }

    list(resolve = null, reject = null, customFilter = {}) {
        super.count(() => {
            super.list(resolve, reject, customFilter)
        }, null, customFilter);
    }
}
