import { createRouter, createWebHashHistory } from 'vue-router'
import {h} from 'vue';
import Auth from "@/classes/Auth";

import AuthView from '@/views/Auth';
import DefaultLayout from "@/layouts/DefaultLayout";
import Sounds from '@/views/Sounds';
import Numbers from "@/views/Numbers";
import Distributions from "@/views/Distributions";
import Stats from "@/views/Stats";
import Blacklist from "@/views/Blacklist";
import Expenses from "@/views/Expenses";
import StatsNumbers from "@/views/StatsNumbers.vue";
import Regions from "@/views/Regions.vue";
import Accounts from "@/views/Accounts.vue";
import Projects from "@/views/Projects.vue";
import Logs from "@/views/Logs.vue";

const routes = [
  {
    path: '/auth',
    name: 'Авторизация',
    component: AuthView
  },
  {
    path: '/logout',
    component: DefaultLayout
  },
  {
    path: '/',
    name: 'Рассылки',
    component: h(DefaultLayout, {view: Distributions})
  },
  {
    path: '/projects',
    name: 'Проекты',
    component: h(DefaultLayout, {view: Projects})
  },
  {
    path: '/stats',
    name: 'Статистика',
    component: h(DefaultLayout, {view: Stats})
  },
  {
    path: '/sounds',
    name: 'Аудиозаписи',
    component: h(DefaultLayout, {view: Sounds})
  },
  {
    path: '/numbers',
    name: 'Номера',
    component: h(DefaultLayout, {view: Numbers})
  },
  {
    path: '/regions',
    name: 'Номера по регионам',
    component: h(DefaultLayout, {view: Regions})
  },
  {
    path: '/accounts',
    name: 'Доп. аккаунты',
    component: h(DefaultLayout, {view: Accounts})
  },
  {
    path: '/stats-numbers',
    name: 'Статистика по номерам',
    component: h(DefaultLayout, {view: StatsNumbers})
  },
  {
    path: '/blacklist',
    name: 'Стоп лист',
    component: h(DefaultLayout, {view: Blacklist})
  },
  {
    path: '/expenses',
    name: 'Расходы',
    component: h(DefaultLayout, {view: Expenses})
  },
  {
    path: '/logs',
    name: 'Логи сервиса',
    component: h(DefaultLayout, {view: Logs})
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 }
  },
});

router.beforeEach((to, from, next)=>
{
  if (to.path === '/logout') {
    Auth.logout();
    next({
      path: '/auth',
      replace: true
    });
  } else if (to.path === '/auth') {
    Auth.validate()
        .then(() => {
          next({
            path: '/',
            replace: true
          });
        })
        .catch(() => {
          next();
        });
  } else {
    Auth.validate()
        .then(() => {
          next();
        })
        .catch(() => {
          next({
            path: '/auth',
            replace: true
          });
        });
  }
})

export default router
