<template>
  <div class="d-flex flex-row align-items-center justify-content-start wd-100p-force mg-rem-b-3">
    <div class="form-group wd-100p-force">
      <multiselect class="multiselect-search wd-100p-force"
                   placeholder="Выберите базу номеров"
                   v-model="selectedDatabank"
                   :options="shortDatabanksItems"
                   @select="selectDatabank"
                   track-by="id"
                   label="name"
      >
        <template #singleLabel="option">
          <span style="font-size: 12px">Выберите базу номеров</span>
        </template>
      </multiselect>
      {{ databankId }}
      <!--
      <select class="form-control" v-model="databankId" v-on:change="addNewDatabank">
        <option value="" disabled>Выберите базу номеров</option>
        <option v-for="databank in databanksItems" v-bind:value="databank.id">{{databank.name}}</option>
      </select>
      -->
    </div>
    <icon-button :icon="'plus'"
                 v-on:click="openAddForm"
                 :size="14" :padding="'5px'" class="tx-12 btn-outline-success btn btn-sm mg-rem-l-3">
    </icon-button>
  </div>
  <div class="wd-100p-force">
    <data-table :columns="columnsActions" :items="[{}]" :max-height="16" :show-header="false" :show-search="false" :loading="false" :items-cnt="1"></data-table>
  </div>
  <div class="wd-100p-force">
    <data-table :columns="columns" :full-height="false" :items="displayItems" :max-height="16" :show-header="false" :show-search="false"></data-table>
  </div>
  <div class="d-flex flex-row align-items-center justify-content-start wd-100p-force mg-rem-t-2">
    <div class="d-flex flex-row align-items-center justify-content-start wd-100p-force">
      <div>Номера: </div>
      <div class="mg-rem-l-1">{{ numbers.length }}</div>
    </div>
    <div class="d-flex flex-row align-items-center justify-content-end wd-100p-force">
      <div>Базы данных: </div>
      <div class="mg-rem-l-1">{{ databanks.length }}</div>
    </div>
  </div>
</template>

<style>
.tx-link {
  text-decoration: underline;
  color: var(--cui-info);
}
.multiselect-search,
.multiselect-search .multiselect__tags,
.multiselect-search .multiselect__select,
.multiselect-search .multiselect__option {
  min-height: 32px !important;
  height: 32px !important;
}
.multiselect-search .multiselect__option:last-child {
  margin-bottom: 5px;
}
.multiselect-search .multiselect__tags,
.multiselect-search .multiselect__content-wrapper,
.multiselect-search .multiselect__input {
  font-size: 12px !important;
}

.multiselect-search .multiselect__tag,
.multiselect-search .multiselect__tags {
  padding: 3px 6px !important;
}
.multiselect-search .multiselect__option--highlight:after {
  display: none !important;
}
.multiselect-search .multiselect__option--highlight,
.multiselect-search .multiselect__option--highlight:after {
  background-color: transparent;
  color: black;
  font-weight: bolder;
}
</style>

<script>
import {h} from 'vue';
import Create from "@/entities/Databanks/Numbers/Create";
import VueFeather from "vue-feather";
import {TheMask,mask} from 'vue-the-mask'
import DataTable from "@/elements/Table";
import IconButton from "@/elements/IconLink";
import DropDownButton from "@/elements/DropDownButton";
import {createConfirmDialog} from "vuejs-confirm-dialog";
import DialogNewDistitrubutionNumbers from "@/elements/DialogNewDistitrubutionNumbers";
import DialogNewDatabankIntoDitribution from "@/elements/DialogNewDatabankIntoDitribution";
import DatabankClass from "@/entities/Databanks/DatabanksClass";
import Multiselect from "vue-multiselect";

export default {
  components: {Multiselect, IconButton, DataTable, Create, TheMask, VueFeather, DropDownButton },
  directives: {mask},
  props: {
    updateNumbers: { type: Function },
    updateDatabanks: { type: Function }
  },
  data() {
    return {
      searchNumber: '',

      numbers: [],
      databanks: [],

      databanksItems: [],
      databankId: '',

      searchDatabank: '',
      selectedDatabank: {},

      totalSelected: false,

      columns: [
        {
          header: '',
          width: '25px',
          value: (item) => {
            return h('input', {
              class: 'flex-shrink-none',
              checked: item.checked,
              type: 'checkbox',
              onClick: () => { item.checked = !item.checked; }
            })
          }
        },
        {
          header: 'Номер телефона / База данных',
          value: (item) => {
            return h('div', {
              class: 'wd-100p-force ' + (item.id ? 'tx-link' : ''),
              onClick: () => { item.checked = !item.checked; }
            }, (item.id ? item.name : this.formatNumber(item.number)))
          }
        },
      ],
      columnsActions: [
        {
          header: '',
          width: '25px',
          value: () => {
            return h('input', {
              class: 'flex-shrink-none',
              checked: this.totalSelected,
              type: 'checkbox',
              disabled: this.loading,
              onClick: () => { this.toggleVisibleSelected() }
            })
          }
        },
        {
          header: '',
          float: 'right',
          value: () => {
            return h(IconButton, {
              className: 'btn btn-sm btn-link tx-12',
              icon: 'trash',
              label: 'Удалить выбранные',
              disabled: !this.someItemSelected,
              style: {'opacity': this.someItemSelected ? '1' : '0.3'},
              padding: '0',
              onClick: () => { this.removeSelected() }
            })
          }
        },
      ],

      links: [
        {
          label: 'Загрузить базу номеров',
          click: () => {
            const { reveal } = createConfirmDialog(h(DialogNewDatabankIntoDitribution, {
              type: this.$props.type
            }));
            reveal();
          }
        },
      ],
    }
  },
  computed: {
    shortDatabanksItems: {
      get() {
        let result = [];

        if (this.databanksItems.length) {
          this.databanksItems.forEach((item) => {
            result.push({id: item.id, name: item.name});
          })
        }

        return result;
      }
    },
    displayItems: {
      get() {
        let result = [];

        if (this.databanks.length) {
          this.databanks.slice().reverse().forEach((item) => {
            result.push(item);
          })
        }

        if (this.numbers.length) {
          this.numbers.slice().reverse().forEach((item) => {
            if (!this.searchNumber) {
              result.push(item);
            } else {
              if (item.number.includes(this.searchNumber.replace(/\D/g,''))) {
                result.push(item);
              }
            }
          })
        }

        return result;
      }
    },
    someItemSelected: {
      get() {
        let result = false;
        if (this.displayItems.length) {
          this.displayItems.forEach((item) => {
            if (item.checked) { result = true; }
          })
        }
        return result;
      }
    }
  },
  methods: {
    searchDatabank(searchQuery, id) {
      this.searchDatabank = searchQuery;
    },
    selectDatabank(selectedOption, id) {
      this.databankId = selectedOption.id + '';
      if (this.databankId && this.databanksItems.length) {
        let needAdd = true;
        if (this.databanks.length) {
          this.databanks.forEach((_databank) => {
            if (parseInt(_databank.id) === parseInt(this.databankId)) {
              needAdd = false;
            }
          })
        }

        if (needAdd) {
          this.databanksItems.forEach((databankItem) => {
            if (parseInt(databankItem.id) === parseInt(this.databankId)) {
              this.databanks.push(Object.assign({checked: false}, databankItem));
              this.$props.updateDatabanks(this.databanks);
            }
          });
        }
      }

      this.databankId = '';
      this.selectedDatabank = {};
    },
    addNewDatabank() {
      if (this.databankId && this.databanksItems.length) {
        let needAdd = true;
        if (this.databanks.length) {
          this.databanks.forEach((_databank) => {
            if (parseInt(_databank.id) === parseInt(this.databankId)) {
              needAdd = false;
            }
          })
        }

        if (needAdd) {
          this.databanksItems.forEach((databankItem) => {
            if (parseInt(databankItem.id) === parseInt(this.databankId)) {
              this.databanks.push(Object.assign({checked: false}, databankItem));
              this.$props.updateDatabanks(this.databanks);
            }
          });
        }
      }

      this.databankId = '';
    },
    openAddForm() {
      const { reveal } = createConfirmDialog(h(DialogNewDistitrubutionNumbers));
      reveal();
    },
    formatNumber(number) {
      return '+' + number.substr(0, 1)+ ' (' + number.substr(1, 3)+ ') ' + number.substr(4,3) + '-' + number.substr(7,2) + '-' + number.substr(9, 2);
    },
    removeSelected() {
      if (this.numbers.length) {
        this.numbers = this.numbers.filter((item) => {
          return !item.checked;
        });
        this.$props.updateNumbers(this.numbers);
      }
      if (this.databanks.length) {
        this.databanks = this.databanks.filter((item) => {
          return !item.checked;
        });
        this.$props.updateDatabanks(this.databanks);
      }
      this.totalSelected = false;
    },
    toggleVisibleSelected() {
      if (this.displayItems.length) {
        this.displayItems.forEach((item) => {
          if (this.numbers.length) {
            this.numbers.forEach((number, index) => {
              if (number.number === item.number) {
                this.numbers[index].checked = !this.totalSelected;
              }
            })
          }

          if (this.databanks.length) {
            this.databanks.forEach((databank, index) => {
              if (databank.id === item.id) {
                this.databanks[index].checked = !this.totalSelected;
              }
            })
          }
        })

        this.totalSelected = !this.totalSelected;
      }
    },
    isInNumbers(number) {
      let result = false;
      if (this.numbers && this.numbers.length) {
        this.numbers.forEach((item) => {
          if (item.number && parseInt(item.number) === parseInt(number)) {
            result = true;
          }
        })
      }
      return result;
    }
  },
  mounted() {
    (new DatabankClass({})).list((items) => {
      this.databanksItems = items;
    })
    this.emitter.on('new-distribution-add-numbers', (options) => {
      if (options.numbers && options.numbers.length) {
        options.numbers.forEach((number) => {
          if (!this.isInNumbers(number)) {
            this.numbers.push({
              checked: false,
              number: parseInt(number).toString()
            });
          }
        });
        this.$props.updateNumbers(this.numbers);
      }
    })

    this.emitter.on('new-distribution-add-databanks', (options) => {
      if (options.databank && options.databank.id) {
        this.databanks.push(Object.assign({checked: false}, options.databank));
        this.$props.updateDatabanks(this.databanks);
      }
    })
  }
}
</script>