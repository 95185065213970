import axios from 'axios'

export default class Auth
{
    static TOKEN_VAR = 'ElstarAuthToken';
    static USERNAME_VAR = 'ElstarUsername';

    static urls = {
        auth: '/rest/auth/simple',
        validate: '/rest/auth/validate',
        admin: '/rest/auth/admin'
    }

    static username() {
        return localStorage.getItem(Auth.USERNAME_VAR);
    }
    static token() {
        return localStorage.getItem(Auth.TOKEN_VAR);
    }

    static login(username, password) {
        return new Promise((resolve, reject) => {
            axios.post(Auth.urls.auth, { login: username, password: password }, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then((res) => {
                    if (res.data.token) {
                        localStorage.setItem(Auth.TOKEN_VAR, res.data.token);
                        localStorage.setItem(Auth.USERNAME_VAR, res.data.username);
                        resolve(res.data.token);
                    } else {
                        reject('Err');
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });

    }

    static validate() {
        const token = localStorage.getItem(Auth.TOKEN_VAR);
        return new Promise((resolve, reject) => {
            axios.post(Auth.urls.validate, { }, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'ControlManagerAuth': token
                }
            })
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    static isAdmin() {
        const token = localStorage.getItem(Auth.TOKEN_VAR);
        return new Promise((resolve, reject) => {
            axios.post(Auth.urls.admin, { }, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'ControlManagerAuth': token
                }
            })
                .then((res) => {
                    console.log(res);
                    resolve(res.data);
                })
                .catch((error) => {
                    resolve(false);
                });
        });
    }

    static logout() {
        localStorage.removeItem(Auth.TOKEN_VAR);
        localStorage.removeItem(Auth.USERNAME_VAR);
    }
}
