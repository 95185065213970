<template>
  <icon-button icon="settings" :size="14" class-name="btn btn-sm btn-outline-info" v-on:click="click"></icon-button>
</template>

<script>
import SoundClass from "@/entities/Sounds/SoundClass";
import VueFeather from "vue-feather";
import IconButton from "@/elements/IconLink";
export default {
  components: {IconButton, VueFeather},
  props: { item: { type: Object }},
  methods: {
    click() {
      (new SoundClass(this)).detailForm(this.$props.item);
    }
  }
}
</script>