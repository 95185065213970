import BaseClass from "@/entities/BaseClass";
import {h} from "vue";
import Edit from "@/entities/Accounts/Edit.vue";
import ModalCard from "@/elements/ModalCard.vue";
import Create from "@/entities/Accounts/Create.vue";

export default class RegionClass extends BaseClass {
    urls = {
        list:   '/rest/accounts/list',
        single: '/rest/accounts/single',
        create: '/rest/accounts/save',
        update: '/rest/accounts/save',
        remove: '/rest/accounts/delete',

        count:  '/rest/accounts/count',
        empty:  '/rest/accounts/empty',
        lock:  '/rest/accounts/lock',
    }

    list(resolve = null, reject = null, customFilter = {}) {
        super.count(() => {
            super.list(resolve, reject, customFilter)
        }, null, customFilter);
    }

    listItem(pk, resolve = null, reject = null, customFilter = {}) {
        this.makeRequest('list', null, Object.assign({filter: {id: pk}}, this.filter, customFilter), (data) => {
            const items = JSON.parse(JSON.stringify(data));
            const item = items.length > 0 ? items[0] : null;
            if (typeof resolve === 'function') {
                resolve(item);
            }
        }, reject);
    }

    lock(pk, resolve = null, reject = null, customFilter = {}) {
        this.instance.loading = true;

        this.makeRequest('lock', {pk: pk}, Object.assign({}, this.filter, customFilter), (response) => {
            this.instance.loading = false;
            this.instance.emitter.emit('account-changed', {id: pk});
            if (typeof resolve === 'function') {
                resolve(response);
            }
        }, reject);
    }

    update(resolve, reject) {
        super.update({model: this.instance.model}, (data) => {
            if (parseInt(data)) {
                this.instance.emitter.emit('account-changed', {id: data});
            }
            if (typeof resolve === 'function') {
                resolve(data);
            }
        }, reject);
    }

    create(resolve, reject) {
        super.create({model: this.instance.model}, (data) => {
            this.instance.emitter.emit('accounts-list-changed');
            this.instance.emitter.emit('close-global-modal-force', {identity: 'create-account'});
            if (typeof resolve === 'function') {
                resolve(data);
            }
        }, reject);
    }

    remove(pk, resolve, reject) {
        super.remove(pk, () => {
            this.instance.emitter.emit('accounts-list-changed');
            this.instance.emitter.emit('close-global-modal-force', {identity: 'account-edit-' + pk});
        }, reject);
    }

    createForm() {
        return this.instance.emitter.emit(
            'open-global-modal',
            {
                view: h(Create),
                header: h(ModalCard, {main: 'Создание нового аккаунта', additional: 'Заполните все обязательные поля'}),
                identity: 'create-account'
            }
        );
    }
    detailForm(item) {
        return this.instance.emitter.emit(
            'open-global-modal',
            {
                view: h(Edit),
                header: h(ModalCard, {main: item.login, additional: item.name}),
                identity: 'account-edit-' + item.id,
                id: item.id,
            }
        );
    }
}
