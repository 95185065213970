<template>
    <logs-list></logs-list>
</template>
<script>
import LogsList from "@/entities/Logs/List";
export default {
  name: 'Logs',
  components: { LogsList }
}
</script>
