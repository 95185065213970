<template>
  <div class="d-flex flex-row align-items-start justify-content-between wd-100p-force">
    <div class="wd-50p-force">
      <div class="card">
        <div class="card-body pd-2">
          <accounts-list></accounts-list>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AccountsList from "@/entities/Accounts/List";
export default {
  name: 'Accounts',
  components: { AccountsList }
}
</script>
