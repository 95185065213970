<template>
  <div>
    {{value_formatted}}
  </div>
</template>
<script>
export default {
  props: ['value'],
  computed: {
    value_formatted: {
      get() {
        let result = [];
        let values = this.$props.value.toString().split(' ');

        if (values.length) {
          values.forEach((_value) => {
            let date = new Date(_value);
            if (date && date.getTime()) {
              result.push(date.toLocaleDateString('ru-RU').split(' ')[0].replaceAll('.', '-'));
            } else {
              result.push(_value);
            }
          })
        }

        return result.join(' ');
      }
    }
  }
}
</script>