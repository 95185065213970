<template>
  <vue-feather v-if="item.is_by_call == '1'" class="mg-rem-r-1 tx-warning" type="phone-call" :size="12"></vue-feather>
  <vue-feather v-if="item.is_by_call == '0'" class="mg-rem-r-1 tx-black" type="edit-3" :size="12"></vue-feather>
</template>

<script>
import VueFeather from "vue-feather";
export default {
  components: { VueFeather },
  props: {
    item: { type: Object },
  }
}
</script>