<template>
  <list :distribution-id="element.id"></list>
</template>

<script>
import List from "@/entities/Distributions/Numbers/List";
export default {
  components: {List },
  props: { element: { type: Object }},
}
</script>