function calc(box, env) {
  if (env.value) {
    let heightInterval = null;
    const bodyHeight = document.body.clientHeight;
    const nextElement = box.closest('div.card-body').querySelector('.table-navigator');

    clearInterval(heightInterval);
    heightInterval = setInterval(() => {
      const rect = box.getBoundingClientRect();
      if (rect.top) {
        let height = (bodyHeight - rect.top) - 100;
        if (nextElement) {
          height -= nextElement.clientHeight;
        }
        box.style.maxHeight = height + 'px';
        box.style.overflowX = 'auto';
        clearInterval(heightInterval);
      }
    },100);
  }
}

const fullMaxHeight = {
  mounted: (box, env) => {
    const _box = box;
    const _env = env;

    calc(_box, _env);

    window.addEventListener('resize', function() {
      calc(_box, _env);
    }, true);
  }
}

export default fullMaxHeight;
