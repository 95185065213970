<template>
  <div class="card">
    <div class="card-body pd-2">
      <databank-list></databank-list>
    </div>
  </div>
</template>
<script>
import DatabankList from "@/entities/Databanks/List";
export default {
  name: 'Numbers',
  components: { DatabankList }
}
</script>
