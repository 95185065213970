<template>
  <router-view />
  <modal></modal>
  <DialogsWrapper />
</template>

<style lang="scss">
@import 'styles/style';
</style>

<script>
import {DialogsWrapper} from "vuejs-confirm-dialog";
import Modal from "@/elements/Modal";
export default {
  components: {Modal, DialogsWrapper}
}
</script>
