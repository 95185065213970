<template>
  <div class="d-flex flex-row align-items-start justify-content-between wd-100p-force">
    <div class="wd-40p-force">
      <div class="card">
        <div class="card-body pd-2">
          <projects-list></projects-list>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ProjectsList from "@/entities/Projects/List";
export default {
  name: 'Projects',
  components: { ProjectsList }
}
</script>
