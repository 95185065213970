<template>
  <div class="d-flex flex-column align-items-start justify-content-start wd-100p-force">
    <h5 class="one-line-width tx-14 mg-b-5-force" v-html='main'></h5>
    <small class="one-line-width" v-html="additional"></small>
  </div>
</template>

<style>
</style>

<script>
export default {
  props: {
      main: { type: String },
      additional: { type: String }
  }
}
</script>
