import axios from 'axios'
import Auth from '@/classes/Auth';
import { toast } from 'vue3-toastify';

export default class Http
{
  static post(url, content, params) {
    let headers = {'Content-Type': 'multipart/form-data'};
    headers[Auth.TOKEN_VAR] = Auth.token();
    headers['datafilter'] = JSON.stringify(params);

    return new Promise((resolve, reject) => {
        axios.post(url, content, {
            headers: headers
        }).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            if (error.name !== "CanceledError") {
                if (typeof reject === 'function') {
                    reject(error);
                }
            }
        });
    });
  }

  static file(url, content, params) {
        let headers = {'Content-Type': 'multipart/form-data'};
        headers[Auth.TOKEN_VAR] = Auth.token();
        headers['datafilter'] = JSON.stringify(params);

        return new Promise((resolve, reject) => {
            axios.post(url, content, {
                headers: headers,
                responseType: 'arraybuffer'
            }).then((response) => {
                resolve(response);
            }).catch((error) => {
                if (typeof reject === 'function') {
                    reject(error);
                }
            });
        });
    }

  static showError(error) {
      console.log(error);
    let err = error.response.data.message;
    let errShow = '';

    try {
      err = JSON.parse(err);
      if (Object.keys(err).length) {
        Object.keys(err).forEach((errIndex) => {
          errShow += err[errIndex] + '<br/>';
        })
      }
    } catch (e) {
      errShow = err;
    }

    toast(errShow, {
      dangerouslyHTMLString: true,
      autoClose: 5000,
      type: 'error'
    });
  }
}
