<template>
  <div class="wd-100p-force">
    <div class="d-flex flex-row align-items-center justify-content-center wd-100p-force" :class="[className]" v-if="loaded">
      <vue-feather :type="icon" :size="14" class="mg-rem-r-1"></vue-feather>
      <div v-html="label"></div>
    </div>
  </div>
</template>

<script>
import VueFeather from "vue-feather";
import {ContentLoader} from "vue-content-loader";
export default {
  components: { ContentLoader, VueFeather },
  props: {
    item: {type: Object},
  },
  computed: {
    icon: {
      get() {
        let result = 'star';

        switch(this.item.status) {
          case 'active': result = 'play'; break;
          case 'paused': result = 'pause'; break;
          case 'paused_out_time': result = 'pause'; break;
          case 'paused_lead_limit': result = 'pause'; break;
          case 'paused_lead_price_limit': result = 'pause'; break;
          case 'stopped': result = 'stop-circle'; break;
          case 'finished': result = 'check'; break;
          default: result = 'star'; break;
        }

        return result;
      }
    },
    className: {
      get() {
        let result = 'tx-info';

        switch(this.item.status) {
          case 'active': result = 'tx-primary'; break;
          case 'paused': result = 'tx-warning'; break;
          case 'paused_out_time': result = 'tx-warning'; break;
          case 'paused_lead_limit': result = 'tx-warning'; break;
          case 'paused_lead_price_limit': result = 'tx-warning'; break;
          case 'stopped': result = 'tx-danger'; break;
          case 'finished': result = 'tx-success'; break;
          default: result = 'tx-info'; break;
        }

        return result;
      }
    },
    label: {
      get() {
        let result = 'tx-info';

        switch(this.item.status) {
          case 'active': result = 'Запущено'; break;
          case 'paused': result = 'Пауза'; break;
          case 'paused_out_time': result = 'Пауза вне времени'; break;
          case 'paused_lead_limit': result = 'Пауза прев дн лимит'; break;
          case 'paused_lead_price_limit': result = 'Пауза прев цены лида'; break;
          case 'stopped': result = 'Остановлено'; break;
          case 'finished': result = 'Закончено'; break;
          default: result = 'Создано'; break;
        }

        return result;
      }
    },
    loaded: {
      get() {
        return typeof this.$props.item !== 'undefined' && typeof this.$props.item.status !== 'undefined';
      }
    }
  }
}
</script>