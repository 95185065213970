<template>
  <div class="dropdown pd-0" v-bind:dropdownid="uniqid" v-if="links && links.length" v-drop-down-position>
    <a href="javascript:void(0)" v-on:click="toggle"
            :disabled="disabled"
            :class="{
              'dropped': dropped
            }"
       :style="{'padding': label ? padding : '5px'}"
            class="dropdown-button d-block btn btn-sm btn-outline-primary d-flex flex-row align-items-center justify-content-center flex-shrink-none">
      <div class="mg-rem-r-1 mg-l-5 tx-12" v-if="label && typeof label != 'object'" v-html="label"></div>
      <component class="mg-rem-r-1 mg-l-5 tx-12" v-if="label && typeof label == 'object' && label.type" :is="label"></component>
      <vue-feather type="chevrons-down" :size="size"></vue-feather>
    </a>
    <div class="dropdown-menu" style="max-height: 300px; overflow-x: auto;" v-bind:style="{'display': dropped ? 'block' : 'none'}" >
      <div v-for="(item,index) in links" :class="['dropdownmenu-' + uniqid]" :key="index" @click="clickHandler(item)" class="wd-100p-force">
        <a class="dropdown-item"  href="javascript:void(0)" v-html="item.label" v-if="typeof item.label !== 'object'"></a>
        <component class="dropdown-item" :is="item.label" v-if="item.label && typeof item.label === 'object' && item.label.type"></component>
      </div>
    </div>
  </div>

</template>

<style>
.dropdown-menu {
  border: 1px solid var(--cui-primary);
  right: 0;
  z-index: 10;
  border-top-right-radius: 0;
  padding: 10px;
}

.dropdown-menu a {
  font-size: 12px;
  padding: 4px;
  background-color: transparent !important;
}

.dropdown-menu a:hover {
  background-color: transparent !important;
  text-decoration: underline;
  color: var(--cui-primary);
}

.dropdown-button.dropped {
  border-bottom: none;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  z-index: 12;
}

.dropdown-button.dropped,
.dropdown-button:hover {
  color: var(--cui-light) !important;
  background-color: var(--cui-primary) !important;
}
</style>

<script>
import VueFeather from "vue-feather";
export default {
  components: {VueFeather},
  props: {
    label: { type: String },
    size: {type: Number, default: 14},
    items: { type: Array },
    disabled: { type: Boolean, default: false },
    padding: {type: String, default: "3px 10px" },
  },
  methods: {
    toggle() {
      this.dropped = this.$props.disabled ? false : !this.dropped;
    },
    clickHandler(item) {
      if (!this.$props.disabled) {
        if(typeof item.click === 'function') {
          item.click();
        }
        if (typeof item.autoClose === 'undefined' || item.autoClose === true) {
          this.dropped = false;
        }
      }
    }
  },
  data() {
    return {
      uniqid: (()=>([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g,a=>(a^Math.random()*16>>a/4).toString(16)))(),
      dropped: false
    }
  },
  computed: {
    links: {
      get() {
        return this.$props.items ? this.$props.items : [];
      }
    }
  },
  mounted() {
    this.emitter.on('body-click', (event) => {
      if (this && this.dropped) {
        const target = event ? event.target : null;
        const parent = target ? target.closest('[dropdownid="' + this.uniqid + '"]') : null;
        const parentDiv = target ? target.closest('.dropdownmenu-' + this.uniqid) : null;

        if (!parent && !parentDiv) {
          this.dropped = false;
        }
      }
    })
  },
  unmounted() {
    this.emitter.off('body-click');
  }
}
</script>