import Http from "@/classes/Http";
import {createConfirmDialog} from "vuejs-confirm-dialog";
import {h} from "vue";
import DialogRemoveItem from "@/elements/DialogRemoveItem";

export default class BaseClass {

    instance = null;
    filter = {};
    urls = [];

    constructor(instance) {
        this.instance = instance;

        this.instance.model = {};
        this.instance.pre_model = {};
        this.instance.items = [];
        this.instance.count = 1;

        this.instance.loading = false;
        this.filter = this.instance.filter ?? {};
    }

    setUrls(urls) {
        this.urls = urls;
    }

    makeRequest(type = 'list', content = null, params = null, resolve = null, reject = null) {
        if (this.urls[type]) {
            Http.post(this.urls[type], content, params)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    Http.showError(error);
                    if (typeof reject === 'function') {
                        reject(error);
                    }
                })
        }
    }

    fileRequest(type = 'download', content = null, params = null, resolve = null, reject = null) {
        if (this.urls[type]) {
            Http.file(this.urls[type], content, params)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    Http.showError(error);
                    if (typeof reject === 'function') {
                        reject(error);
                    }
                })
        }
    }

    intersection(model, pre_model) {
        let result = {};
        if (typeof model === 'object' && typeof pre_model === 'object' && Object.keys(model).length && Object.keys(pre_model).length) {
            if (model && Object.keys(model).length) {
                Object.keys(model).forEach((model_key) => {
                    if (!Object.keys(pre_model).includes(model_key) || JSON.stringify(model[model_key]) !== JSON.stringify(pre_model[model_key])) {
                        result[model_key] = pre_model[model_key];
                    }
                })
            }
        }

        return result;
    }

    itemHasBeenChanged() {
        return Object.keys(this.intersection(this.instance.model, this.instance.pre_model)).length > 0;
    }

    count(resolve = null, reject = null, customFilter = {}) {
        this.instance.loading = true;
        this.makeRequest('count', null, Object.assign({}, this.filter, customFilter), (cnt) => {
            this.instance.loading = false;
            this.instance.count = parseInt(cnt);

            if (typeof resolve === 'function') {
                resolve(cnt);
            }
        }, reject);
    }

    empty(resolve = null, reject = null, customFilter = {}) {
        this.instance.model = {};
        this.instance.loading = true;
        this.makeRequest('empty', null, Object.assign({}, this.filter, customFilter), (data) => {
            this.instance.loading = false;
            this.instance.model = JSON.parse(JSON.stringify(data));

            if (typeof resolve === 'function') {
                resolve(data);
            }
        }, reject);
    }

    list(resolve = null, reject = null, customFilter = {}) {
        this.instance.loading = true;
        this.instance.items = [];

        this.makeRequest('list', null, Object.assign({}, this.filter, customFilter), (data) => {
            this.instance.loading = false;
            this.instance.items = JSON.parse(JSON.stringify(data));

            if (typeof resolve === 'function') {
                resolve(data);
            }
        }, reject);
    }

    listItem(pk, resolve = null, reject = null, customFilter = {}) {
        this.loading = true;
        this.makeRequest('list', null, Object.assign({filter: {id: pk}}, this.filter, customFilter), (data) => {
            this.instance.loading = false;
            const items = JSON.parse(JSON.stringify(data));
            if (typeof resolve === 'function') {
                resolve(items.length > 0 ? items[0] : null);
            }
        }, reject);
    }

    item(pk, resolve = null, reject = null, customFilter = {}) {
        this.loading = true;
        this.instance.model = {};
        this.instance.pre_model = {};

        this.makeRequest('single', {pk: pk}, Object.assign({}, this.filter, customFilter), (data) => {
            this.instance.loading = false;

            this.instance.model = JSON.parse(JSON.stringify(data));
            this.instance.pre_model = JSON.parse(JSON.stringify(data));

            if (typeof resolve === 'function') {
                resolve(data);
            }
        }, reject);
    }

    update(content, resolve = null, reject = null, customFilter = {}) {
        this.loading = true;

        this.makeRequest('update', content, Object.assign({}, this.filter, customFilter), (data) => {
            this.instance.loading = false;
            if (typeof resolve === 'function') {
                resolve(data);
            }
        }, reject);
    }

    create(content, resolve = null, reject = null, customFilter = {}) {
        this.loading = true;

        this.makeRequest('create', content, Object.assign({}, this.filter, customFilter), (data) => {
            this.instance.loading = false;
            if (typeof resolve === 'function') {
                resolve(data);
            }
        }, reject);
    }

    remove(pk, resolve = null, reject = null, customFilter = {}) {
        const { reveal } = createConfirmDialog(h(DialogRemoveItem, {
            header: 'Подтвердите удаление',
            description: 'После удаления данные не могут быть восстановлены. Вы уверены?',
            action: (modalResolve, modalReject) => {
                this.instance.loading = true;
                this.makeRequest('remove', {pk: pk}, Object.assign({}, this.filter, customFilter), (data) => {
                    this.instance.loading = false;

                    if (typeof modalResolve === 'function') {
                        modalResolve();
                    }
                    if (typeof resolve === 'function') {
                        resolve(data);
                    }
                }, (error) => {
                    this.instance.loading = false;

                    if (typeof modalResolve === 'function') {
                        modalResolve();
                    }
                    if (typeof resolve === 'function') {
                        resolve(error);
                    }
                });
            }
        }));
        reveal();
    }
}
