import BaseClass from "@/entities/BaseClass";
import {createConfirmDialog} from "vuejs-confirm-dialog";
import {h} from "vue";
import DialogRemoveItem from "@/elements/DialogRemoveItem";

export default class LinksClass extends BaseClass {
    urls = {
        count:  '/rest/blacklist/count',
        list:   '/rest/blacklist/list',
        create: '/rest/blacklist/save',
        single: '/rest/blacklist/single',
        update: '/rest/blacklist/save',
        remove: '/rest/blacklist/delete',

        saveGroup: '/rest/blacklist/save-group',
        removeAll: '/rest/blacklist/delete-all',
        upload: '/rest/blacklist/upload',
        download: '/rest/blacklist/download',
    }

    download(resolve = null, reject = null, customFilter = {}) {
        this.loading = true;

        this.fileRequest('download', {}, Object.assign({}, this.filter, customFilter), (response) => {
            if (typeof resolve === 'function') {
                resolve(response);
            }
        }, reject);
    }

    list(resolve = null, reject = null, customFilter = {}) {
        super.count(() => {
            super.list(resolve, reject, customFilter)
        }, null, customFilter);
    }

    uploadExcel(file, action, resolve = null, reject = null, customFilter = {}) {
        this.loading = true;

        this.makeRequest('upload', {action: action, file: file}, Object.assign({}, this.filter, customFilter), (response) => {
            if (typeof resolve === 'function') {
                resolve(response);
            }
        }, reject);
    }

    saveGroup(entity_id, numbers, resolve = null, reject = null, customFilter = {}) {
        this.loading = true;

        this.makeRequest('saveGroup', {numbers: numbers}, Object.assign({}, this.filter, customFilter), (response) => {
            if (typeof resolve === 'function') {
                resolve(response);
            }
        }, reject);
    }

    remove(pks, resolve, reject) {
        const { reveal } = createConfirmDialog(h(DialogRemoveItem, {
            header: 'Подтвердите удаление',
            description: 'После удаления данные не могут быть восстановлены. Вы уверены?',
            action: (modalResolve, modalReject) => {
                this.instance.loading = true;
                this.makeRequest('remove', {pks: pks}, Object.assign({}, this.filter), (data) => {
                    this.instance.loading = false;

                    if (typeof modalResolve === 'function') {
                        modalResolve();
                    }
                    if (typeof resolve === 'function') {
                        resolve(data);
                    }
                }, (error) => {
                    this.instance.loading = false;

                    if (typeof modalResolve === 'function') {
                        modalResolve();
                    }
                    if (typeof resolve === 'function') {
                        resolve(error);
                    }
                });
            }
        }));
        reveal();
    }

    removeAll(resolve, reject, customFilter = {}) {
        const { reveal } = createConfirmDialog(h(DialogRemoveItem, {
            header: 'Подтвердите удаление',
            description: 'После удаления данные не могут быть восстановлены. Вы уверены?',
            action: (modalResolve, modalReject) => {
                this.instance.loading = true;
                this.makeRequest('removeAll', {}, Object.assign({}, this.filter, customFilter), (data) => {
                    this.instance.loading = false;

                    if (typeof modalResolve === 'function') {
                        modalResolve();
                    }
                    if (typeof resolve === 'function') {
                        resolve(data);
                    }
                }, (error) => {
                    this.instance.loading = false;

                    if (typeof modalResolve === 'function') {
                        modalResolve();
                    }
                    if (typeof resolve === 'function') {
                        resolve(error);
                    }
                });
            }
        }));
        reveal();
    }

    create(number, resolve, reject) {
        super.create({model: {number: number}}, (data) => {
            this.instance.emitter.emit('blacklist-numbers-list-changed');
            if (typeof resolve === 'function') {
                resolve(data);
            }
        }, reject);
    }
}
