<script>
import VueFeather from "vue-feather";
import DatabankClass from "@/entities/Databanks/DatabanksClass";

export default {
  components: { VueFeather },
  emits: ['confirm', 'cancel'],
  props: {
    Class: { type: Object },
    model: { type: Object },
    numbers: { type: Array },
    files: { type: Array }
  },
  setup(props, ctx) {
    const confirmEvent = () => { ctx.emit('confirm'); }
    const cancelEvent = () => { ctx.emit('cancel'); }
    return {
      confirmEvent,
      cancelEvent
    }
  },
  data() {
    return {
      inProgress: false,
      visible: false
    }
  },
  methods: {
    hireConfirm() {
      this.visible = false;
      setTimeout(() => {
        this.confirmEvent();
      }, 300);
    },
    hireCancel() {
      this.visible = false;
      setTimeout(() => {
        this.cancelEvent();
      }, 300);
    }
  },
  mounted() {
    this.visible = true;
    this.inProgress = true;
    this.$props.Class.create(this.$props.model, this.$props.numbers, this.$props.files, () => {
      this.hireCancel();
    })
  }
}
</script>

<style>
.dialog-window .modal-body { background-color: white; height: 100vh; position: relative; }
.dialog-window {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2040;
  background-color: transparent;
}
.dialog-window_background {
  background-color: rgba(0,0,15,0.6);
  width: 100%;
  height: 100%;
}
.dialog-window_body_parent {
  width: 100%;
  height: 100%;
  position: absolute;
  top: -10vh;
  left: 0;
}
.dialog-window_body {
  display: block;
  width: 500px;
  height: fit-content;
}
.dialog-window_body .card,
.dialog-window_body .card-body {
  border-radius: 15px;
  padding: 20px 30px;
}
.dialog-window_body .card {
  border: none;
  box-shadow: none;
}
</style>

<template>
  <div class="dialog-window">
    <div class="dialog-window_background" @click="hireCancel"></div>
      <div class="dialog-window_body_parent d-flex flex-row align-items-center justify-content-center">
        <transition name="bounce">
          <div class="dialog-window_body" v-show="visible">
          <div class="card">
            <div class="card-body">
              <div class="d-flex flex-column align-items-center justify-content-start">
                <vue-feather :type="inProgress ? 'loader' : 'plus-circle'" :size="50" :class="{'rotating': inProgress}" class="tx-info mg-rem-b-4"></vue-feather>
                <h4 class="tx-center">Создание новой базы данных номеров</h4>
                <p class="tx-center">Ожидайте окончания загрузки. В случае загрузки excel-файла большого размера добавление новой базы может занять дополнительное время</p>
              </div>
            </div>
          </div>
        </div>
        </transition>
      </div>
  </div>
</template>