<template>
  <data-table :columns="columns" :items="items" :on-create-click="createHandler" :loading="loading" :items-cnt="count"></data-table>
</template>

<script>
import {h} from "vue";
import VueFeather from "vue-feather";
import DatabanksClass from "@/entities/Databanks/DatabanksClass";
import DataTable from "@/elements/Table";
import EditButton from "@/entities/Databanks/EditButton";
import DeleteButton from "@/entities/Databanks/DeleteButton";
import ActionsButton from "@/entities/Databanks/ActionsButton";
import {ContentLoader} from "vue-content-loader";
import NumberFormatted from "@/elements/NumberFormatted.vue";

export default {
  components: { DataTable, NumberFormatted },
  data() {
    return {
      Databanks: null,
      loading: false,
      count: 1,

      columns: [
        {
          header: 'ID',
          width: '100px',
          value: (item) => { return h(NumberFormatted, {class: 'wd-100p-force text-align-center', value: item.id})  }
        },
        {
          header: 'Размер',
          width: '150px',
          value: (item) => { return h(NumberFormatted, {class: 'wd-100p-force text-align-center', value: item.stats_numbers_count}) }
        },
        {
          header: 'Название',
          value: (item) => { return item.name; }
        },
        {
          header: '',
          width: '100px',
          float: 'right',
          value: (item) => { return h(ActionsButton, {item: item, padding: '2px 10px'}) }
        },
        {
          header: '',
          width: '30px',
          float: 'right',
          value: (item) => { return h(EditButton, {item: item}) }
        },
        {
          header: '',
          width: '30px',
          float: 'right',
          value: (item) => { return h(DeleteButton, {item: item}) }
        },
      ],
      items: [],
      createHandler: null
    }
  },
  methods: {
    init() {
      this.Databanks.list();
    }
  },
  mounted() {
    this.Databanks = new DatabanksClass(this);
    this.init();
    this.createHandler = () => {this.Databanks.createForm()};

    this.emitter.on('databanks-list-changed', () => {
      this.init();
    });
    this.emitter.on('databank-changed', (options) => {
      if (options.id) {
        this.Databanks.listItem(options.id, (changedItem) => {
          if (this.items.length && changedItem) {
            this.items.forEach((item, index) => {
              if (parseInt(item.id) === parseInt(changedItem.id)) {
                this.items[index] = changedItem;
              }
            })
          }
        })
      }
    })
  }
}
</script>