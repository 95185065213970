import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import CoreuiVue from '@coreui/vue'
import VueFeather from 'vue-feather';
import mitt from 'mitt';
import VueTippy from 'vue-tippy'

import fullHeightDirective from "@/directives/fullHeight";
import fullMaxHeightDirective from "@/directives/fullMaxHeight";
import dropDownPositionDirective from "@/directives/dropDownPosition";

import NumberFormatted from "@/elements/NumberFormatted.vue";
import DateFormatted from "@/elements/DateFormatted.vue";

import 'vue3-toastify/dist/index.css';
import 'vue-multiselect/dist/vue-multiselect.css';

const app = createApp(App);
const Vue = app;

app.use(store);
app.use(router);
app.use(CoreuiVue);
app.use(VueFeather.name, VueFeather);
app.use(VueTippy, {
    directive: 'tippy',
    component: 'tippy',
});

Vue.directive('fullHeight', fullHeightDirective);
Vue.directive('fullMaxHeight', fullMaxHeightDirective);
Vue.directive('dropDownPosition', dropDownPositionDirective);

Vue.component('numberFormatted', NumberFormatted);
Vue.component('dateFormatted', DateFormatted);

app.config.globalProperties.$filters = {
    numberFormat(value) {
        return parseFloat(value).toLocaleString('ru-RU');
    },
    dateFormat(value) {
        return new Date(value).toLocaleString('ru-RU');
    }
}

const emitter = mitt();
app.config.globalProperties.emitter = emitter;

window.onerror = function(message, source, lineno, colno, error) {
    console.log('Exception: ', error)
}

Vue.config.errorHandler = function (err, vm, info) {
    console.log(err);
}

app.mount('#app')
