<template>
  <CHeader position="sticky" class="mb-4 px-5">
    <div class="d-flex flex-row align-items-center justify-content-end wd-100p-force">
      <div class="d-flex flex-column align-items-end justify-content-center">
        <b class="wd-500 tx-dark tx-right" v-html="username"></b>
        <router-link to="/logout">Выход</router-link>
      </div>
    </div>
  </CHeader>
</template>

<script>
import AppBreadcrumb from './AppBreadcrumb';
import Auth from "@/classes/Auth";
export default {
  name: 'AppHeader',
  components: {
    AppBreadcrumb
  },
  computed: {
    username: {
      get() { return Auth.username() }
    }
  }
}
</script>
