<template>
  <div class="d-flex flex-column align-items-start justify-content-start">
    <b class="fw-400 mg-b-3" v-html="item.name"></b>
    <div class="d-flex flex-row align-items-center justify-content-start mg-b-3">
      <number-formatted title="Всего номеров" class="small" v-bind:value="item.stats.total"></number-formatted>
      <small class="mg-l-5 mg-r-5">/</small>
      <number-formatted  title="В стоп-листе" class="small tx-warning" v-bind:value="item.stats.blacklist"></number-formatted>
      <small class="mg-l-5 mg-r-5">/</small>
      <number-formatted title="Успешный звонок" class="small tx-success" v-bind:value="item.stats.called"></number-formatted>
      <small class="mg-l-5 mg-r-5">/</small>
      <number-formatted title="Неуспешный звонок" class="small tx-danger" v-bind:value="item.stats.uncalled"></number-formatted>
      <small class="mg-l-5 mg-r-5">/</small>
      <number-formatted title="Запрет вызова (повтор за 3 дня)" class="small tx-info"  v-bind:value="item.stats.weekly_not_call"></number-formatted>
      <small class="mg-l-5 mg-r-5">/</small>
      <number-formatted title="Номера в обработке" class="small tx-primary"  v-bind:value="item.stats.in_progress ?? 0"></number-formatted>
      <small class="mg-l-5 mg-r-5">/</small>
      <number-formatted title="Осталось номеров" class="small tx-secondary"  v-bind:value="item.stats.remain"></number-formatted>
    </div>
    <div class="d-flex flex-row align-items-start justify-content-center small tx-secondary">
      <div class="mg-rem-r-1">ID: {{item.id}},</div>
      <date-formatted  v-bind:value="item.stats.updated_at ?? ''"></date-formatted>
      <div v-if="item.megafon_only === '1'" >, <span class="mg-rem-l-1">Мегафон</span></div>
      <div v-if="item.timezoned === '1'" >, <span class="mg-rem-l-1">Time-zone</span></div>
    </div>

  </div>
</template>

<script>
import VueFeather from "vue-feather";
import {ContentLoader} from "vue-content-loader";
import DistributionClass from "@/entities/Distributions/DistributionClass";
export default {
  components: {ContentLoader, VueFeather },
  props: {
    item: { type: Object },
  },
}
</script>