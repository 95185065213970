<template>
  <data-table :columns="columns" :items="items" :on-create-click="createHandler" :loading="loading" :items-cnt="count"></data-table>
</template>

<script>
import {h} from "vue";
import AccountsClass from "@/entities/Accounts/AccountsClass";
import DataTable from "@/elements/Table";
import EditButton from "@/entities/Accounts/EditButton";
import LockedIcon from "@/entities/Accounts/LockedIcon.vue";
import NumberFormatted from "@/elements/NumberFormatted.vue";

export default {
  components: { DataTable, NumberFormatted },
  data() {
    return {
      Accounts: null,
      loading: false,
      count: 1,

      columns: [
        {
          header: 'ID',
          width: '100px',
          value: (item) => { return h(NumberFormatted, {class: 'wd-100p-force text-align-center', value: item.id}); }
        },
        {
          header: '',
          width: '25px',
          value: (item) => { return h(LockedIcon, {item: item}) }
        },
        {
          header: 'Логин',
          width: '200px',
          value: (item) => { return item.login; }
        },
        {
          header: 'Имя',
          value: (item) => { return item.name; }
        },
        {
          header: '',
          width: '30px',
          float: 'right',
          value: (item) => { return h(EditButton, {item: item}) }
        },
      ],
      items: [],
      createHandler: null
    }
  },
  methods: {
    init() {
      this.Accounts.list();
    }
  },
  mounted() {
    this.Accounts = new AccountsClass(this);
    this.init();
    this.createHandler = () => {this.Accounts.createForm()};

    this.emitter.on('accounts-list-changed', () => {
      this.init();
    });

    this.emitter.on('account-changed', (options) => {
      if (options.id) {
        this.Accounts.listItem(options.id, (changedItem) => {
          if (this.items.length && changedItem) {
            this.items.forEach((item, index) => {
              if (parseInt(item.id) === parseInt(changedItem.id)) {
                this.items[index] = changedItem;
              }
            })
          }
        })
      }
    })
  }
}
</script>