<template>
  <stats-main></stats-main>

</template>
<script>
import StatsMain from "@/entities/Stats/Main";
export default {
  name: 'Stats',
  components: { StatsMain }
}
</script>
