<template>
  <div class="card wd-100p-force">
    <div class="card-body wd-100p-force">
      <div class="d-flex flex-column align-items-start justify-content-start wd-100p-force" v-if="components && components.length">
        <div class="wd-100p-force" v-for="(component,index) in components">
          <component  v-if="component && component.type" :key="index" :is="component"></component>
        </div>
      </div>
      <h6 class="app-block-group-label app-block-group-label-right" v-html="label"></h6>
      <slot></slot>
    </div>
  </div>
</template>

<style scoped>
  .card { border: 1px solid var(--cui-gray-200); padding: 0.5rem; margin-top: calc(var(--cui-card-spacer-y) / 2); box-shadow: none; }
  .app-block-group-label {
    position: absolute;
    top: calc(-1 * var(--cui-card-spacer-y) / 2 - 2px);
    background-color: white;
    width: fit-content;
    padding: 0 var(--cui-card-spacer-x);
    color: var(--cui-gray-600);
  }

  .app-block-group-label-right {
    right: calc(var(--cui-card-spacer-x)); top: calc(-1 * var(--cui-card-spacer-y) / 2);
    font-size: 0.8rem;
    font-weight: 500;
    color: var(--cui-form-label-color);
  }
</style>

<script>
export default {
  props: {
    label: {type: String, default: null},
    components: { type: Array, default: [] },
  }
}
</script>
