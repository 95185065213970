<template>
  <div class="bg-light min-vh-100 d-flex flex-row align-items-center">
    <CContainer class="auth-container wd-500-force">
      <div class="row d-flex flex-column align-items-center justify-content-center">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <form class="mb-4 pd-l-40 pd-r-40" @submit.prevent="login()">
                <div class="d-flex flex-column align-items-center justify-content-center mb-4">
                  <img class="mg-rem-b-4 wd-50p-force" src="/images/logo_space.png"/>
                  <h5 class="tx-dark fw-400">Панель управления</h5>
                  <p class="tx-dark fw-200">Форма авторизации пользователя</p>
                </div>
                <input type="text" v-model="username" class="form-control mb-2 wd-100p-force" placeholder="Имя пользователя">
                <input type="password" v-model="password" class="form-control mb-4" placeholder="Пароль">
                <div class="d-flex flex-row align-items-center justify-content-end">
                  <input type="submit" class="btn btn-sm btn-primary pd-l-10 pd-r-10 tx-light" value="Авторизоваться" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </CContainer>
  </div>
</template>

<script>
import Auth from "@/classes/Auth";
import VueFeather from "vue-feather";
import { toast } from 'vue3-toastify';

export default {
  name: 'Auth',
  components: { VueFeather },
  data() {
    return {
      username: '',
      password: ''
    }
  },
  methods: {
    login() {
      Auth.login(this.username, this.password)
        .then(() => {
          window.location.href = '/';
          //this.$router.push({path: '/', replace: true});
        })
        .catch((err) => {
          toast('<b>Ошибка авторизации</b><br/>Проверьте введенные данные', {
            dangerouslyHTMLString: true,
            autoClose: 5000,
            type: 'error'
          });
        })
    }
  }
}
</script>
