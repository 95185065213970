<template>
  <div class="d-flex flex-row align-items-center justify-content-start wd-100p-force">
    <div class="form-group wd-350-force">
      <div class="input-group" :style="{'pointer-events': createInProgress ? 'none' : 'auto'}">
        <input type="text" v-mask="'# (###) ###-##-##'"  class="form-control tx-12 pd-l-10 pd-r-10 pd-t-4 pd-b-4" placeholder="Поиск номера" v-model="number" />
        <div class="input-group-append">
        <span class="input-group-text" v-on:click="number = ''; $emit('search-close')">
          <vue-feather type="x" :size="14" :style="{'opacity': searchEnabled ? '1' : '0', 'pointer-events': searchEnabled ? 'auto' : 'none'}" stroke="var(--cui-dark)"></vue-feather>
        </span>
        </div>
      </div>
    </div>
    <icon-button label="Найти"
                 :disabled="!searchAllowed || disabled ||createInProgress" :icon="disabled ? 'loader' : 'search'" :size="14" :padding="'3px 10px'"
                 :class="{'btn-outline-info' : !searchEnabled, 'btn-info': searchEnabled}"
                 v-on:click="$emit('search', numberFormatted)" class="tx-12 btn btn-sm mg-rem-l-1"></icon-button>
  </div>
</template>

<script>
import IconButton from "@/elements/IconLink";
import {TheMask,mask} from 'vue-the-mask'
import VueFeather from "vue-feather";
import DropDownButton from "@/elements/DropDownButton";

export default {
  components: {IconButton, TheMask, VueFeather, DropDownButton},
  emits: ['search', 'search-close', 'create', 'remove-founded'],
  directives: { mask },
  props: {
    someSelected: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    searchEnabled: false,
    createInProgress: false,
  },
  data() {
    return {
      number: ''
    }
  },
  computed: {
    searchAllowed: {
      get() {
        return this.numberFormatted.length >= 2;

      }
    },
    numberFormatted: {
      get() { return this.number ? this.number.replace(/\D/g,'') : ''; }
    }
  }
}
</script>