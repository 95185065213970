<template>
  <div class="wd-100p-force d-flex flex-column align-items-start justify-content-start">
    <number-formatted class="wd-100p-force text-align-center fw-400 mg-b-3" v-bind:value="item.stats.price"></number-formatted>
  </div>
</template>

<script>
import VueFeather from "vue-feather";
import {ContentLoader} from "vue-content-loader";
import DistributionClass from "@/entities/Distributions/DistributionClass";
export default {
  components: {ContentLoader, VueFeather },
  props: {
    item: { type: Object },
  }
}
</script>