<template>
  <div class="d-flex flex-row align-items-start justify-content-between wd-100p-force">
    <div style="width: calc(100% - 480px)" >
      <div class="card">
        <div class="card-body pd-2">
          <div class="wd-100p-force">
            <data-table :columns="columns" :show-search="false" :items="items" :loading="loading" :items-cnt="100"></data-table>
          </div>
          <div class="d-flex flex-row align-items-center justify-content-start wd-100p-force mg-rem-t-4 tx-right table-navigator" v-show="!loading && items.length">
            <div class="d-flex flex-column align-items-start justify-content-start">
              <b  style="color: var(--cui-dark) !important;">Всего:</b>
              <number-formatted class="small" v-bind:value="count + ''"  style="color: var(--cui-dark) !important;"></number-formatted>
            </div>
            <div class="d-flex flex-column align-items-end justify-content-start wd-100p-force mg-rem-r-2">
              <b  style="color: var(--cui-dark) !important;">Страница:</b>
              <small  style="color: var(--cui-dark) !important;">{{ page }} / {{ maxPage }}</small>
            </div>
            <div class="form-group mg-l-auto flex-shrink-none">
              <div class="d-flex flex-row align-items-center justify-content-end">
                <input type="number" v-model="newPage" :min="0" :max="maxPage" style="width: 80px; padding: 4px 7px;" class="form-control form-control-select pagination-select">
                <icon-button :icon="'corner-down-right'"
                             v-on:click="goToPage"
                             :size="14" :padding="'5px 7px'" class="tx-12 btn-outline-primary btn btn-sm mg-rem-l-1">
                </icon-button>
                <icon-button :icon="'arrow-left'"
                             :disabled="newPage === 1"
                             v-on:click="decreasePage"
                             :size="14" :padding="'5px 7px'" class="tx-12 btn-outline-info btn btn-sm mg-rem-l-3">
                </icon-button>
                <icon-button :icon="'arrow-right'"
                             :disabled="newPage === maxPage"
                             v-on:click="increasePage"
                             :size="14" :padding="'5px 7px'" class="tx-12 btn-outline-info btn btn-sm mg-rem-l-1">
                </icon-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="wd-450-force" style="margin-left: 25px;">
      <div class="card">
        <div class="card-body">
          <div class="wd-100p-force">
            <div class="form-group mb-4">
              <label class="form-label text-black">Вызовы </label>
              <div class="d-flex flex-row align-items-start justify-content-start wd-100p-force">
                <div class="input-group mg-r-3">
                  <input type="number" v-model="statsFilter.calls.min" class="form-control tx-12 pd-l-10 pd-r-10 pd-t-4 pd-b-4" />
                  <div class="input-group-append">
                    <span class="input-group-text tx-secondary" style="font-size: 10px;">min</span>
                  </div>
                </div>
                <div class="input-group mg-l-3">
                  <input type="number" v-model="statsFilter.calls.max" class="form-control tx-12 pd-l-10 pd-r-10 pd-t-4 pd-b-4" />
                  <div class="input-group-append">
                    <span class="input-group-text tx-secondary" style="font-size: 10px;">max</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group mb-4">
              <label class="form-label text-black">Нажатия #1 </label>
              <div class="d-flex flex-row align-items-start justify-content-start wd-100p-force">
                <div class="input-group mg-r-3">
                  <input type="number" v-model="statsFilter.leads.min" class="form-control tx-12 pd-l-10 pd-r-10 pd-t-4 pd-b-4" />
                  <div class="input-group-append">
                    <span class="input-group-text tx-secondary" style="font-size: 10px;">min</span>
                  </div>
                </div>
                <div class="input-group mg-l-3">
                  <input type="number" v-model="statsFilter.leads.max" class="form-control tx-12 pd-l-10 pd-r-10 pd-t-4 pd-b-4" />
                  <div class="input-group-append">
                    <span class="input-group-text tx-secondary" style="font-size: 10px;">max</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group mb-4">
              <label class="form-label text-black">Последний вызов</label>
              <div class="d-flex flex-row align-items-start justify-content-start wd-100p-force">
                <div class="input-group mg-r-3">
                  <input type="datetime-local" v-model="statsFilter.last_call.min" class="form-control tx-12 pd-l-10 pd-r-10 pd-t-4 pd-b-4" />
                  <div class="input-group-append">
                    <span class="input-group-text tx-secondary" style="font-size: 10px;">min</span>
                  </div>
                </div>
                <div class="input-group mg-l-3">
                  <input type="datetime-local" v-model="statsFilter.last_call.max" class="form-control tx-12 pd-l-10 pd-r-10 pd-t-4 pd-b-4" />
                  <div class="input-group-append">
                    <span class="input-group-text tx-secondary" style="font-size: 10px;">max</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group mb-4">
              <label class="form-label text-black">Организатор получения базы на стороне Ф5</label>
              <multiselect class="multiselect-filter ms-filter-orgs wd-100p-force"
                           placeholder="Введите тэг"
                           v-model="statsFilter.tags.orgs"
                           :options="tagsOrgs"
                           :multiple="true"
                           :close-on-select="true"
                           :clear-on-select="true"
                           :preserve-search="true"
                           :hide-selected="true"
              >
              </multiselect>
            </div>
            <div class="form-group mb-4">
              <label class="form-label text-black">Кто вёл РК, с которой пришёл контакт</label>
              <multiselect class="multiselect-filter ms-filter-authors wd-100p-force"
                           placeholder="Введите тэг"
                           v-model="statsFilter.tags.authors"
                           :options="tagsAuthors"
                           :multiple="true"
                           :close-on-select="true"
                           :clear-on-select="true"
                           :preserve-search="true"
                           :hide-selected="true"
              >
              </multiselect>
            </div>
            <div class="form-group mb-4">
              <label class="form-label text-black">Хэштеги откликов</label>
              <multiselect class="multiselect-filter ms-filter-tags wd-100p-force"
                           placeholder="Введите тэг"
                           v-model="statsFilter.tags.tags"
                           :options="tagsDefault"
                           :multiple="true"
                           :close-on-select="true"
                           :clear-on-select="true"
                           :preserve-search="true"
                           :hide-selected="true"
              >
              </multiselect>
            </div>
            <div class="form-group mb-4">
              <label class="form-label text-black">Номер телефона</label>
              <input type="text" v-mask="'# (###) ###-##-##'"  class="form-control tx-12 pd-l-10 pd-r-10 pd-t-4 pd-b-4" v-model="statsFilter.number" />
            </div>
            <div class="d-flex flex-row align-items-center justify-content-start">
              <icon-button label="Найти"
                           :disabled="loading" :icon="loading ? 'loader' : 'search'" :size="14" :padding="'3px 10px'"
                           :class="{'btn-outline-info' : !searchEnabled, 'btn-info': searchEnabled}"
                           v-on:click="searchData" class="tx-12 btn btn-sm mg-rem-r-1"></icon-button>
              <icon-button label="Очистить"
                           :disabled="loading" :icon="loading ? 'loader' : 'slash'" :size="14" :padding="'3px 10px'"
                           :class="{'btn-outline-secondary' : !searchEnabled, 'btn-secondary': searchEnabled}"
                           v-on:click="clearSearchData" class="tx-12 btn btn-sm mg-rem-r-1"></icon-button>
              <icon-button label="Создать базу"
                           :disabled="loading" :icon="loading ? 'loader' : 'plus'" :size="14" :padding="'3px 10px'"
                           :class="{'btn-outline-primary' : !searchEnabled, 'btn-primary': searchEnabled}"
                           v-on:click="createDatabase" class="tx-12 btn btn-sm" style="margin-left: auto"></icon-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.pagination-select {
  border-color: var(--cui-gray-500) !important;
  text-align: right;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.multiselect-filter .multiselect__tags,
.multiselect-filter .multiselect__content-wrapper,
.multiselect-filter .multiselect__input {
  font-size: 12px !important;
}

.multiselect-filter .multiselect__tag {
  padding: 6px 26px 6px 10px !important;
}

.multiselect-filter.ms-filter-orgs .multiselect__tag {
  color: #00737a;
  background-color: #ccf2f5;
  border-color: #b3ecef;
}
.multiselect-filter.ms-filter-orgs i.multiselect__tag-icon::after {
  color: #00737a !important;
}
.multiselect-filter.ms-filter-orgs .multiselect__option--highlight,
.multiselect-filter.ms-filter-orgs .multiselect__option--highlight:after {
  background-color: #ccf2f5;
  color: #00737a;
}

.multiselect-filter.ms-filter-authors .multiselect__tag {
  color: #0b4b76;
  background-color: #d0e5f3;
  border-color: #b8d8ee;
}
.multiselect-filter.ms-filter-authors i.multiselect__tag-icon::after {
  color: #0b4b76 !important;
}
.multiselect-filter.ms-filter-authors .multiselect__option--highlight,
.multiselect-filter.ms-filter-authors .multiselect__option--highlight:after {
  background-color: #d0e5f3;
  color: #0b4b76;
}

.multiselect-filter.ms-filter-tags .multiselect__tag {
  color: #5e636a;
  background-color: #ebedef;
  border-color: #e2e4e8;
}
.multiselect-filter.ms-filter-tags i.multiselect__tag-icon::after {
  color: #5e636a !important;
}
.multiselect-filter.ms-filter-tags .multiselect__option--highlight,
.multiselect-filter.ms-filter-tags .multiselect__option--highlight:after {
  background-color: #ebedef;
  color: #5e636a;
}

</style>

<script>
import {h} from 'vue';
import StatsNumbers from "@/entities/StatsNumbers/StatsNumbersClass";
import DataTable from "@/elements/Table";
import IconButton from "@/elements/IconLink";
import Search from "@/entities/StatsNumbers/Search.vue";
import VueFeather from "vue-feather";
import {TheMask,mask} from 'vue-the-mask'
import {createConfirmDialog} from "vuejs-confirm-dialog";
import DialogNewDatabankFromStats from "@/elements/DialogNewDatabankFromStats.vue";
import NumberFormatted from "@/elements/NumberFormatted.vue";
import DatabankClass from "@/entities/Databanks/DatabanksClass";
import Multiselect from "vue-multiselect";

export default {
  components: {Multiselect, VueFeather, TheMask, Search, IconButton, DataTable, NumberFormatted },
  directives: { mask },
  props: {
  },
  data() {
    return {
      StatsNumbers: null,
      DatabankClass: null,

      loading: false,
      count: 1,

      searchEnabled: false,
      searchNumber: null,
      page: 1,
      newPage: 1,
      maxOnPage: 100,
      maxPage: 1,

      pages: {},

      tags: [],

      filter: {
      },

      statsFilter: {
        calls: {
          min: null,
          max: null
        },
        leads: {
          min: null,
          max: null
        },
        last_call: {
          min: null,
          max: null
        },
        number: null,
        tags: {
          orgs: [],
          authors: [],
          tags: []
        }
      },

      columns: [
        {
          header: 'Номер телефона',
          width: '150px',
          value: (item) => { return this.formatNumber(item.number + ''); }
        },
        {
          header: 'Вызовы',
          width: '150px',
          value: (item) => { return h(NumberFormatted, {class: 'wd-100p-force text-align-center', value: item.calls}); }
        },
        {
          header: 'Нажатия "1"',
          width: '150px',
          value: (item) => { return h(NumberFormatted, {class: 'wd-100p-force text-align-center', value: item.press}); }
        },
        {
          header: 'Последний вызов',
          width: '150px',
          value: (item) => { return h('div', {class: 'wd-100p-force text-align-center', innerHTML: (item.last_call ? item.last_call : "")}); }
        },
        {
          header: 'Дата добавления',
          width: '150px',
          value: (item) => { return h('div', {class: 'wd-100p-force text-align-center', innerHTML: (item.loaded_at ? item.loaded_at : "")}); }
        },
        {
          header: 'Тэги',
          value: (item) => { return h('div', {class: 'wd-100p-force text-align-center', style: 'display: block; max-width: 270px;'}, this.getTags(item)); }
        },
      ],

      items: [],
    }
  },
  watch: {
    page: {
      handler() {
        this.newPage = this.page;
      }
    },
  },
  computed: {
    tagsOrgs: {
      get() {
        let result = [];

        if (this.tags.length) {
          this.tags.forEach((tag) => {
            if (tag.type === 'orgs') {
              result.push(tag.tag);
            }
          });
        }

        return result;
      }
    },
    tagsAuthors: {
      get() {
        let result = [];

        if (this.tags.length) {
          this.tags.forEach((tag) => {
            if (tag.type === 'authors') {
              result.push(tag.tag);
            }
          });
        }

        return result;
      }
    },
    tagsDefault: {
      get() {
        let result = [];

        if (this.tags.length) {
          this.tags.forEach((tag) => {
            if (tag.type === 'tags') {
              result.push(tag.tag);
            }
          });
        }

        return result;
      }
    }
  },
  methods: {
    getTags(item) {
      let result = [];

      if (item.tags && item.tags.length) {
        let tags = Array.from(new Set(item.tags)).sort();

        tags.forEach((tag) => {
          let splitted = tag.split(":");
          let alertClass = 'alert-secondary';
          switch (splitted[0]) {
            case 'orgs': alertClass = 'alert-info'; break;
            case 'authors': alertClass = 'alert-primary'; break;
            default: alertClass = 'alert-secondary'; break;
          }

          let name = (splitted[0] === 'tags' ? '#' : '') + splitted[splitted.length - 1];

          result.push(h('div', {class: "alert " + alertClass, style: "padding: 2px 4px; display: inline-block; margin: 0 2px 2px 0; overflow: hidden; text-overflow: ellipsis;"}, name));
        })
      }

      return result;
    },
    increasePage() {
      this.page += 1;
      this.init();
    },
    decreasePage() {
      this.page -= 1;
      this.init();
    },
    goToPage() {
      if (this.newPage > this.maxPage) {
        this.page = this.maxPage;
      } else {
        this.page = this.newPage;
      }
      this.init();
    },
    formatNumber(number) {
      return '+' + number.substr(0, 1)+ ' (' + number.substr(1, 3)+ ') ' + number.substr(4,3) + '-' + number.substr(7,2) + '-' + number.substr(9, 2);
    },
    init() {
      let filter = {limit: this.maxOnPage, offset: (this.page - 1) * this.maxOnPage, statsFilter: this.statsFilter};
      this.StatsNumbers.list(() => {
        const division = parseInt(parseInt(this.count) / this.maxOnPage);
        const reside = parseInt(this.count) - division * this.maxOnPage;
        this.maxPage = division + (reside > 0 ? 1 : 0);
      }, null, filter);
    },
    searchData() {
      this.page = 1;
      this.init();
    },
    clearSearchData() {
      this.page = 1;
      this.statsFilter = {
        calls: {
          min: null,
          max: null
        },
        leads: {
          min: null,
          max: null
        },
        last_call: {
          min: null,
          max: null
        },
        number: null,
        tags: {
          orgs: [],
          authors: [],
          tags: []
        }
      };
      this.init();
    },
    createDatabase() {
      const { reveal } = createConfirmDialog(h(DialogNewDatabankFromStats, {
        filter: this.statsFilter,
        cnt: this.count
      }));
      reveal();
    }
  },
  mounted() {
    this.DatabankClass = new DatabankClass({});
    this.StatsNumbers = new StatsNumbers(this);

    this.DatabankClass.tags((data) => {
      this.tags = data;
    })
    this.init();
  }
}
</script>