<template>
  <div class="d-flex flex-column align-items-center justify-content-start wd-100p-force">
    <number-formatted class="small fw-500" v-bind:value="item.speed"></number-formatted>
    <number-formatted class="small" v-bind:value="item.stats?.speed_real ?? 0"></number-formatted>
  </div>
</template>

<script>
import VueFeather from "vue-feather";
import {ContentLoader} from "vue-content-loader";
import DistributionClass from "@/entities/Distributions/DistributionClass";
export default {
  components: {ContentLoader, VueFeather },
  props: {
    item: { type: Object },
  }
}
</script>