import {h} from 'vue';
import BaseClass from "@/entities/BaseClass";
import ModalCard from "@/elements/ModalCard";
import Create from "@/entities/Databanks/Create";
import Edit from "@/entities/Databanks/Edit";

export default class DatabankClass extends BaseClass {
    urls = {
        list:   '/rest/databanks/list',
        single: '/rest/databanks/single',
        create: '/rest/databanks/save',
        update: '/rest/databanks/save',
        remove: '/rest/databanks/delete',
        tags: '/rest/databanks/tags',

        count:  '/rest/databanks/count',
        empty:  '/rest/databanks/empty',
        upload: '/rest/databanks/upload',
        download: '/rest/databanks/download',
    }

    list(resolve = null, reject = null, customFilter = {}) {
        super.count(() => {
            super.list(resolve, reject, customFilter)
        }, null, customFilter);
    }

    listItem(pk, resolve = null, reject = null, customFilter = {}) {
        this.makeRequest('list', null, Object.assign({filter: {id: pk}}, this.filter, customFilter), (data) => {
            const items = JSON.parse(JSON.stringify(data));
            const item = items.length > 0 ? items[0] : null;
            if (typeof resolve === 'function') {
                resolve(item);
            }
        }, reject);
    }

    tags(resolve = null, reject = null) {
        this.makeRequest('tags', null, null,(data) => {
            if (typeof resolve === 'function') {
                resolve(data);
            }
        }, reject);
    }

    downloadExcel(pk, resolve = null, reject = null, customFilter = {}) {
        this.loading = true;

        this.fileRequest('download', {pk: pk}, Object.assign({}, this.filter, customFilter), (response) => {
            if (typeof resolve === 'function') {
                resolve(response);
            }
        }, reject);
    }

    uploadExcel(pk, file, action, resolve = null, reject = null, customFilter = {}) {
        this.loading = true;

        this.makeRequest('upload', {pk: pk, action: action, file: file}, Object.assign({}, this.filter, customFilter), (response) => {
            if (typeof resolve === 'function') {
                resolve(response);
            }
        }, reject);
    }

    update(resolve, reject) {
        super.update({model: this.instance.model}, (data) => {
            if (parseInt(data)) {
                this.instance.emitter.emit('databank-changed', {id: data});
            }
            if (typeof resolve === 'function') {
                resolve(data);
            }
        }, reject);
    }

    create(model, numbers, files, resolve, reject) {
        let content = {model: model, numbers: numbers};
        if (files.length) {
            files.forEach((file, index) => {
                content['file' + index] = file;
            })
        }
        super.create(content, (data) => {
            this.instance.emitter.emit('databanks-list-changed');
            this.instance.emitter.emit('close-global-modal-force', {identity: 'create-databank'});
            this.listItem(data, (item) => {
                this.detailForm(item);
            }, null);

            if (typeof resolve === 'function') {
                resolve(data);
            }
        }, reject);
    }

    remove(pk, resolve, reject) {
        super.remove(pk, () => {
            this.instance.emitter.emit('databanks-list-changed');
            this.instance.emitter.emit('close-global-modal-force', {identity: 'databank-edit-' + pk});
        }, reject);
    }

    createForm() {
        return this.instance.emitter.emit(
            'open-global-modal',
            {
                view: h(Create),
                header: h(ModalCard, {main: 'Создание новой базы номеров', additional: 'Заполните все обязательные поля'}),
                identity: 'create-databank'
            }
        );
    }
    detailForm(item) {
        return this.instance.emitter.emit(
            'open-global-modal',
            {
                view: h(Edit),
                header: h(ModalCard, {main: item.name, additional: item.created_at}),
                identity: 'databank-edit-' + item.id,
                id: item.id,
            }
        );
    }
}
