<template>
  <form>
    <div v-full-height="true">
      <div class="form-group mb-4">
        <label class="form-label">Имя пользователя <sup class="tx-danger">*</sup></label>
        <input type="text" v-model="model.name" class="form-control">
      </div>
      <div class="form-group mb-4">
        <label class="form-label">Логин <sup class="tx-danger">*</sup></label>
        <input type="text" v-model="model.login" class="form-control">
      </div>
      <div class="form-group mb-4">
        <label class="form-label">Новый пароль <sup class="tx-danger">*</sup></label>
        <input type="text" v-model="model.password" class="form-control">
      </div>
    </div>
    <div class="d-flex flex-row align-items-center justify-content-between pd-t-30 pd-b-30">
      <a href="javascript:void(0)" :disabled="!saveAllowed || loading" v-on:click="save" class="btn btn-sm btn-outline-success d-flex flex-row align-items-center justify-content-start">
        <vue-feather class="mg-rem-r-1" type="save" :size="14" v-if="!loading"></vue-feather>
        <vue-feather class="mg-rem-r-1" type="loader" :size="14" v-if="loading"></vue-feather>
        Сохранить
      </a>
      <a href="javascript:void(0)" :disabled="loading" v-on:click="lock" v-bind:class="{'btn-outline-warning': model.locked === '0', 'btn-warning': model.locked === '1'}" class="btn btn-sm d-flex flex-row align-items-center justify-content-start">
        <vue-feather class="mg-rem-r-1" type="lock" :size="14" v-if="!loading && model.locked === '0'"></vue-feather>
        <vue-feather class="mg-rem-r-1" type="unlock" :size="14" v-if="!loading && model.locked === '1'"></vue-feather>
        <vue-feather class="mg-rem-r-1" type="loader" :size="14" v-if="loading"></vue-feather>
        <span v-if="model.locked === '0'">Заблокировать</span>
        <span v-if="model.locked === '1'">Разблокировать</span>
      </a>
      <a href="javascript:void(0)" class="btn btn-sm btn-link tx-danger d-flex flex-row align-items-center justify-content-start" v-on:click="remove">
        <vue-feather class="mg-rem-r-1" type="trash" :size="14"></vue-feather>
        Удалить
      </a>
    </div>
  </form>
</template>

<script>
import AccountsClass from "@/entities/Accounts/AccountsClass";
import VueFeather from "vue-feather";
import Accounts from "@/views/Accounts.vue";
export default {
  props: { element: { type: Object }},
  components: { VueFeather },
  data() {
    return {
      Accounts: null,
      loading: false,
      uploadInProgress: false,

      model: {},
      pre_model: {},
    }
  },
  computed: {
    saveAllowed: {
      get() { return this.Accounts ? this.Accounts.itemHasBeenChanged() : false; }
    }
  },
  methods: {
    init() {
      this.Accounts.item(this.$props.element.id);
    },
    save() {
      this.Accounts.update(() => {
        this.emitter.emit('add-close-break-point', {identity: 'account-edit-' + this.model.id, closeBreakPoint: false});
        this.init();
      }, () => {
        this.loading = false;
      })
    },
    lock() {
      this.Accounts.lock(this.$props.element.id, () => {
        this.init();
      });
    },
    remove() {
      this.Accounts.remove(this.$props.element.id);
    }
  },
  mounted() {
    this.Accounts = new AccountsClass(this);
    this.init();
  }
}
</script>