<template>
  <div class="d-flex flex-row align-items-center justify-content-start wd-100p-force mg-rem-b-3">
    <div class="form-group wd-100p-force">
      <select class="form-control" v-model="regionId" v-on:change="addNewRegion">
        <option value="" disabled>Выберите регион обзвона</option>
        <option v-for="region in regionsItems" v-bind:value="region.id">{{region.region}}</option>
      </select>
    </div>
  </div>
  <div class="wd-100p-force">
    <data-table :columns="columnsActions" :items="[{}]" :max-height="16" :show-header="false" :show-search="false" :loading="false" :items-cnt="1"></data-table>
  </div>
  <div class="wd-100p-force">
    <data-table :columns="columns" :full-height="false" :items="displayItems" :max-height="16" :show-header="false" :show-search="false"></data-table>
  </div>
  <div class="d-flex flex-row align-items-center justify-content-start wd-100p-force mg-rem-t-2">
    <div class="d-flex flex-row align-items-center justify-content-start wd-100p-force">
    </div>
    <div class="d-flex flex-row align-items-center justify-content-end wd-100p-force">
      <div>Регионы: </div>
      <div class="mg-rem-l-1">{{ regions.length }}</div>
    </div>
  </div>
</template>

<style>
.tx-link {
  text-decoration: underline;
  color: var(--cui-info);
}
</style>

<script>
import {h} from 'vue';
import RegionsClass from "@/entities/Regions/RegionsClass";
import VueFeather from "vue-feather";
import DataTable from "@/elements/Table";
import IconButton from "@/elements/IconLink";
import DropDownButton from "@/elements/DropDownButton";

export default {
  components: {IconButton, DataTable, VueFeather, DropDownButton },
  props: {
    updateRegions: { type: Function }
  },
  data() {
    return {
      searchNumber: '',

      regions: [],

      regionsItems: [],
      regionId: '',

      totalSelected: false,

      columns: [
        {
          header: '',
          width: '25px',
          value: (item) => {
            return h('input', {
              class: 'flex-shrink-none',
              checked: item.checked,
              type: 'checkbox',
              onClick: () => { item.checked = !item.checked; }
            })
          }
        },
        {
          header: 'Регион',
          value: (item) => {
            return h('div', {
              class: 'wd-100p-force ' + (item.id ? 'tx-link' : ''),
              onClick: () => { item.checked = !item.checked; }
            }, item.region)
          }
        },
      ],
      columnsActions: [
        {
          header: '',
          width: '25px',
          value: () => {
            return h('input', {
              class: 'flex-shrink-none',
              checked: this.totalSelected,
              type: 'checkbox',
              disabled: this.loading,
              onClick: () => { this.toggleVisibleSelected() }
            })
          }
        },
        {
          header: '',
          float: 'right',
          value: () => {
            return h(IconButton, {
              className: 'btn btn-sm btn-link tx-12',
              icon: 'trash',
              label: 'Удалить выбранные',
              disabled: !this.someItemSelected,
              style: {'opacity': this.someItemSelected ? '1' : '0.3'},
              padding: '0',
              onClick: () => { this.removeSelected() }
            })
          }
        },
      ],
    }
  },
  computed: {
    displayItems: {
      get() {
        let result = [];

        if (this.regions.length) {
          this.regions.slice().reverse().forEach((item) => {
            result.push(item);
          })
        }

        return result;
      }
    },
    someItemSelected: {
      get() {
        let result = false;
        if (this.displayItems.length) {
          this.displayItems.forEach((item) => {
            if (item.checked) { result = true; }
          })
        }
        return result;
      }
    }
  },
  methods: {
    addNewRegion() {
      if (this.regionId && this.regionsItems.length) {
        let needAdd = true;
        if (this.regions.length) {
          this.regions.forEach((_region) => {
            if (parseInt(_region.id) === parseInt(this.regionId)) {
              needAdd = false;
            }
          })
        }

        if (needAdd) {
          this.regionsItems.forEach((regionItem) => {
            if (parseInt(regionItem.id) === parseInt(this.regionId)) {
              this.regions.push(Object.assign({checked: false}, regionItem));
              this.$props.updateRegions(this.regions);
            }
          });
        }
      }

      this.regionId = '';
    },
    removeSelected() {
      if (this.regions.length) {
        this.regions = this.regions.filter((item) => {
          return !item.checked;
        });
        this.$props.updateRegions(this.regions);
      }
      this.totalSelected = false;
    },
    toggleVisibleSelected() {
      if (this.displayItems.length) {
        this.displayItems.forEach((item) => {
          if (this.regions.length) {
            this.regions.forEach((region, index) => {
              if (region.id === item.id) {
                this.regions[index].checked = !this.totalSelected;
              }
            })
          }
        })

        this.totalSelected = !this.totalSelected;
      }
    }
  },
  mounted() {
    (new RegionsClass({})).listSimple((items) => {
      this.regionsItems = items;
    });

    this.emitter.on('new-distribution-add-regions', (options) => {
      if (options.region && options.region.id) {
        this.databanks.push(Object.assign({checked: false}, options.databank));
        this.$props.updateDatabanks(this.databanks);
      }
    })
  }
}
</script>