<template>
  <div class="wd-100p-force d-flex flex-column align-items-start justify-content-center">
    <number-formatted class="wd-100p-force text-align-center fw-400" v-bind:value="item.stats.pressed + ' / ' + item.leads_max"></number-formatted>
  </div>
</template>

<script>
import VueFeather from "vue-feather";
import {ContentLoader} from "vue-content-loader";
import DistributionClass from "@/entities/Distributions/DistributionClass";
export default {
  components: {ContentLoader, VueFeather },
  props: {
    item: { type: Object },
  },
}
</script>