<script>
import VueFeather from "vue-feather";
export default {
  components: { VueFeather },
  emits: ['confirm', 'cancel'],
  props: { header: {type: String}, description: { type: String }},
  setup(props, ctx) {
    const confirmEvent = () => { ctx.emit('confirm'); }
    const cancelEvent = () => { ctx.emit('cancel'); }
    return {
      confirmEvent,
      cancelEvent
    }
  },
  data() {
    return {
      visible: false
    }
  },
  methods: {
    hireConfirm() {
      this.visible = false;
      setTimeout(() => {
        this.confirmEvent();
      }, 300);
    },
    hireCancel() {
      this.visible = false;
      setTimeout(() => {
        this.cancelEvent();
      }, 300);
    }
  },
  mounted() {
    this.visible = true;
  }
}
</script>

<style>
.dialog-window .modal-body { background-color: white; height: 100vh; position: relative; }
.dialog-window {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2040;
  background-color: transparent;
}
.dialog-window_background {
  background-color: rgba(0,0,15,0.6);
  width: 100%;
  height: 100%;
}
.dialog-window_body_parent {
  width: 100%;
  height: 100%;
  position: absolute;
  top: -10vh;
  left: 0;
}
.dialog-window_body {
  display: block;
  width: 500px;
  height: fit-content;
}
.dialog-window_body .card,
.dialog-window_body .card-body {
  border-radius: 15px;
  padding: 20px 30px;
}
.dialog-window_body .card {
  border: none;
  box-shadow: none;
}
</style>

<template>
  <div class="dialog-window">
    <div class="dialog-window_background" @click="hireCancel"></div>
      <div class="dialog-window_body_parent d-flex flex-row align-items-center justify-content-center">
        <transition name="bounce">
          <div class="dialog-window_body" v-show="visible">
          <div class="card">
            <div class="card-body">
              <div class="d-flex flex-column align-items-center justify-content-start">
                <vue-feather type="alert-circle" :size="50" class="tx-warning mg-rem-b-4"></vue-feather>
                <h4 class="tx-center" v-html="header"></h4>
                <p class="tx-center" v-html="description"></p>
                <div class="d-flex flex-row align-items-center justify-content-between pd-l-40 pd-r-40 mg-t-20 wd-100p-force">
                  <a href="javascript:void(0)" class="btn btn-sm btn-outline-warning" @click="hireConfirm">Подтвердить</a>
                  <a href="javascript:void(0)" class="btn btn-sm btn-outline-secondary" @click="hireCancel">Отмена</a>
                </div>
              </div>

            </div>
          </div>
        </div>
        </transition>
      </div>
  </div>
</template>