<template>
  <a href="javascript:void(0)" :class="[className]" :disabled="disabled" class="d-flex flex-column justify-content-start flex-shrink-none align-items-start"
     :style="{
      'padding': padding ? padding : (parseInt(size / 2.5) + 'px')
    }"
  >
    <div class="d-flex flex-row justify-content-center flex-shrink-none align-items-center">
      <vue-feather :type="icon" :size="size" :fill="iconFill" :stroke="iconStroke"></vue-feather>
      <div class="d-flex flex-column align-items-start justify-content-start" v-if="label || date || description" :style="{'line-height': date || description ? '14px' : 'inherit', 'margin-left': date || description ? '0.75rem': '0.5rem', 'padding': date || description ? '1px 0': 'inherit'}">
        <div v-if="label" v-html="label"></div>
        <small v-if="description" class="tx-10 one-line-width" v-html="description"></small>
        <date-formatted v-if="date" class="small tx-10 one-line-width" v-bind:value="date"></date-formatted>
      </div>
    </div>
  </a>
</template>

<style>
.btn.btn-outline-warning:hover {
  color: var(--cui-white);
}
</style>

<script>
import VueFeather from "vue-feather";
import DateFormatted from "@/elements/DateFormatted.vue";
export default {
  components: {VueFeather, DateFormatted},
  props: {
    className: {type: String, default: 'btn btn-sm btn-outline-primary'},
    icon: { type: String, default: 'check' },
    iconFill: { type: String, default: 'none' },
    iconStroke: { type: String, default: 'currentColor'},
    size: {type: Number, default: 14},
    padding: {type: String, default: null },
    label: { type: String, default: null },
    disabled: { type: Boolean, default: false },
    description: { type: String, default: null },
    date: { type: String, default: null },
  }
}
</script>