import BaseClass from "@/entities/BaseClass";

export default class StatsNumbersClass extends BaseClass {
    urls = {
        count:  '/rest/numbers/count',
        list:   '/rest/numbers/list',
        databank: '/rest/numbers/databank'
    }

    list(resolve = null, reject = null, customFilter = {}) {
        super.count(() => {
            super.list(resolve, reject, customFilter)
        }, null, customFilter);
    }

    createDatabank(filter, name, count, resolve = null, reject = null, customFilter = {}) {
        this.loading = true;

        super.makeRequest('databank', {filter: filter, name: name, count: count}, Object.assign({}, this.filter, customFilter), (response) => {
            if (typeof resolve === 'function') {
                resolve(response);
            }
        }, reject);
    }
}
