<template>
  <form>
    <div v-full-height="true">
      <div class="form-group mg-rem-b-3">
        <label class="form-label">Название <sup class="tx-danger">*</sup></label>
        <input type="text" v-model="model.name" class="form-control">
      </div>
      <div class="form-group mb-4">
        <label class="form-label">Организатор получения базы на стороне Ф5</label>
        <multiselect class="multiselect-tagging ms-filter-orgs wd-100p-force"
                     placeholder="Введите новый элемент"
                     :close-on-select="false"
                     v-model="model.orgs"
                     :options="tagEmpty.orgs ? [] : tagsOrgs"
                     :multiple="true"
                     :taggable="true"
                     :show-no-options="false"
                     :show-no-result="false"
                     :hide-selected="true"
                     :options-limit="3"
                     :preserve-search="true"
                     @tag="addTagOrgs"
                     @search-change="searchChangedOrgs"
        >
        </multiselect>
      </div>
      <div class="form-group mb-4">
        <label class="form-label">Кто вёл РК, с которой пришёл контакт</label>
        <multiselect class="multiselect-tagging ms-filter-authors wd-100p-force"
                     placeholder="Введите новый элемент"
                     :close-on-select="false"
                     v-model="model.authors"
                     :options="tagEmpty.authors ? [] : tagsAuthors"
                     :multiple="true"
                     :taggable="true"
                     :show-no-options="false"
                     :show-no-result="false"
                     :hide-selected="true"
                     :options-limit="3"
                     :preserve-search="true"
                     @tag="addTagAuthors"
                     @search-change="searchChangedAuthors"
        >
        </multiselect>
      </div>
      <div class="form-group mb-4">
        <label class="form-label">Хэштеги откликов</label>
        <multiselect class="multiselect-tagging ms-filter-tags wd-100p-force"
                     placeholder="Введите новый элемент"
                     :close-on-select="false"
                     v-model="model.tags"
                     :options="tagEmpty.tags ? [] : tagsDefault"
                     :multiple="true"
                     :taggable="true"
                     :show-no-options="false"
                     :show-no-result="false"
                     :hide-selected="true"
                     :options-limit="3"
                     :preserve-search="true"
                     @tag="addTagTags"
                     @search-change="searchChangedTags"
        >
        </multiselect>
      </div>
      <block-group label="Номера" :components="numbersComponents"></block-group>
    </div>
    <div class="d-flex flex-row align-items-center justify-content-between pd-t-30 pd-b-30">
      <a href="javascript:void(0)" :disabled="!saveAllowed || loading" v-on:click="save" class="btn btn-sm btn-outline-success d-flex flex-row align-items-center justify-content-start">
        <vue-feather class="mg-rem-r-1" type="save" :size="14" v-if="!loading"></vue-feather>
        <vue-feather class="mg-rem-r-1" type="loader" :size="14" v-if="loading"></vue-feather>
        Сохранить
      </a>
      <a href="javascript:void(0)" class="btn btn-sm btn-link tx-danger d-flex flex-row align-items-center justify-content-start" v-on:click="remove">
        <vue-feather class="mg-rem-r-1" type="trash" :size="14"></vue-feather>
        Удалить
      </a>
    </div>
  </form>
</template>

<script>
import {h} from 'vue';
import DatabankClass from "@/entities/Databanks/DatabanksClass";
import VueFeather from "vue-feather";
import NumbersList from "@/entities/Databanks/Numbers/List";
import BlockGroup from "@/elements/BlockGroup";
import Multiselect from "vue-multiselect";

export default {
  props: { element: { type: Object }},
  components: {Multiselect, BlockGroup, VueFeather },
  data() {
    return {
      Databank: null,
      loading: false,
      uploadInProgress: false,

      model: {},
      pre_model: {},

      tagEmpty: {
        orgs: true,
        authors: true,
        tags: true
      },

      tags: [],

      numbersComponents: [
        h(NumbersList, {databankId: this.$props.element.id})
      ]
    }
  },
  watch: {
    saveAllowed: {
      handler(val) {
        this.emitter.emit('add-close-break-point', {identity: 'databank-edit-' + this.$props.element.id, closeBreakPoint: val});
      }
    }
  },
  computed: {
    saveAllowed: {
      get() { return this.Databank ? this.Databank.itemHasBeenChanged() : false; }
    },
    tagsOrgs: {
      get() {
        let result = [];

        if (this.tags.length) {
          this.tags.forEach((tag) => {
            if (tag.type === 'orgs') {
              result.push(tag.tag + ' ');
            }
          });
        }

        return result;
      }
    },
    tagsAuthors: {
      get() {
        let result = [];

        if (this.tags.length) {
          this.tags.forEach((tag) => {
            if (tag.type === 'authors') {
              result.push(tag.tag + ' ');
            }
          });
        }

        return result;
      }
    },
    tagsDefault: {
      get() {
        let result = [];

        if (this.tags.length) {
          this.tags.forEach((tag) => {
            if (tag.type === 'tags') {
              result.push(tag.tag + ' ');
            }
          });
        }

        return result;
      }
    }
  },
  methods: {
    searchChangedOrgs(query) { this.tagEmpty.orgs = (query.length === 0); },
    searchChangedAuthors(query) { this.tagEmpty.authors = (query.length === 0); },
    searchChangedTags(query) { this.tagEmpty.tags = (query.length === 0); },

    addTagOrgs(tag) { this.model.orgs.push(tag.trimEnd()); },
    addTagAuthors(tag) { this.model.authors.push(tag.trimEnd()); },
    addTagTags(tag) { this.model.tags.push(tag.trimEnd()); },

    init() {
      this.Databank.item(this.$props.element.id);
    },
    save() {
      if (this.model.orgs.length) {
        this.model.orgs.forEach((tag, index) => {
          this.model.orgs[index] = tag.trimEnd();
        })
      }

      if (this.model.authors.length) {
        this.model.authors.forEach((tag, index) => {
          this.model.authors[index] = tag.trimEnd();
        })
      }

      if (this.model.tags.length) {
        this.model.tags.forEach((tag, index) => {
          this.model.tags[index] = tag.trimEnd();
        })
      }

      this.Databank.update(() => {
        this.emitter.emit('add-close-break-point', {identity: 'databank-edit-' + this.$props.element.id, closeBreakPoint: false});
        this.init();
      }, () => {
        this.loading = false;
      })
    },
    remove() {
      this.Databank.remove(this.$props.element.id);
    }
  },
  mounted() {
    this.Databank = new DatabankClass(this);
    this.Databank.tags((data) => {
      this.tags = data;
      this.init();
    })
  }
}
</script>