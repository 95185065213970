<template>
  <div class="card">
    <div class="card-body pd-2">
      <sounds-list></sounds-list>
    </div>
  </div>
</template>
<script>
import SoundsList from "@/entities/Sounds/List";
export default {
  name: 'Sounds',
  components: { SoundsList },
}
</script>
