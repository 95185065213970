<template>
  <div>
    <AppSidebar />
    <div class="wrapper d-flex flex-column min-vh-100 bg-light">
      <AppHeader />
      <div class="body flex-grow-1">
        <CContainer class="main-container" lg>
          <component v-if="view" :is="view"></component>
        </CContainer>
      </div>
    </div>
  </div>
</template>

<style>
.container.main-container, .container-fluid.main-container, .container-xxl.main-container, .container-xl.main-container, .container-lg.main-container,
.container-md.main-container, .container-sm.main-container {
  margin: 0;
  padding-top: 1.5rem;
  padding-left: 3rem;
  padding-right: 3rem;
  max-width: 100% !important;
}
</style>

<script>
import { CContainer } from '@coreui/vue'
import AppFooter from '@/components/AppFooter.vue'
import AppHeader from '@/components/AppHeader.vue'
import AppSidebar from '@/components/AppSidebar.vue'

export default {
  props: { view: {type: Object }},
  name: 'DefaultLayout',
  components: {
    AppFooter,
    AppHeader,
    AppSidebar,
    CContainer,
  },
  mounted() {
    window.addEventListener('click', (event) => {
      this.emitter.emit('body-click', event);
    });
  }
}
</script>
