<template>
    <stats-numbers-list></stats-numbers-list>
</template>
<script>
import StatsNumbersList from "@/entities/StatsNumbers/List";
export default {
  name: 'Blacklist',
  components: { StatsNumbersList }
}
</script>
