<template>
  <div class="d-flex flex-row align-items-start justify-content-start wd-100p-force">
    <component v-if="header && header.type" :is="header" style="max-width: calc(100% - 100px)"></component>
    <vue-feather class="mg-l-auto flex-shrink-none tx-primary cursor-pointer" v-on:click="close" type="x" :size="30"></vue-feather>
  </div>
</template>

<style>
</style>

<script>
import VueFeather from "vue-feather";
import IconButton from "@/elements/IconLink";
export default {
  components: {IconButton, VueFeather},
  props: {
      identity: { type: String },
      header: { type: Object }
  },
  methods: {
    close() {
      this.emitter.emit('close-global-modal-from-header', {identity: this.$props.identity});
    }
  }
}
</script>
