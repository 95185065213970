<template>
  <div class="wd-100p-force d-flex flex-column align-items-center justify-content-center">
    <number-formatted class="tx-dark fw-500 tx-right" v-bind:value="result ?? '-----'"></number-formatted>
    <small class="tx-secondary">Расходы в этом месяце</small>
  </div>
</template>

<script>
import {ContentLoader} from "vue-content-loader";
import DistributionClass from "@/entities/Distributions/DistributionClass";
export default {
  components: {ContentLoader },
  data() {
    return {
      loaded: false,
      result: null
    }
  },
  mounted() {
    this.loaded = false;
    (new DistributionClass(this)).monthlyExpense((data) => {
      this.result = data;
      this.loaded = true;
    })
  }
}
</script>