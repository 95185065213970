<template>
  <div class="wd-100p-force d-flex flex-column align-items-start justify-content-center">
    <number-formatted class="wd-100p-force text-align-center fw-400" v-bind:value="price"></number-formatted>
  </div>
</template>

<script>
import VueFeather from "vue-feather";
import {ContentLoader} from "vue-content-loader";
import DistributionClass from "@/entities/Distributions/DistributionClass";
export default {
  components: {ContentLoader, VueFeather },
  props: {
    item: { type: Object },
  },
  computed: {
    price: {
      get() {
        return (this.$props.item && parseInt(this.$props.item.stats.pressed) > 0) ? Number(parseFloat(parseFloat(this.$props.item.stats.price) / parseInt(this.$props.item.stats.pressed))).toFixed(2) : 0
      }
    }
  }
}
</script>