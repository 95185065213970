<template>
  <form>
    <div v-full-height="true">
      <div class="form-group mb-4">
        <label class="form-label">Имя пользователя <sup class="tx-danger">*</sup></label>
        <input type="text" v-model="model.name" class="form-control">
      </div>
      <div class="form-group mb-4">
        <label class="form-label">Логин <sup class="tx-danger">*</sup></label>
        <input type="text" v-model="model.login" class="form-control">
      </div>
      <div class="form-group mb-4">
        <label class="form-label">Пароль <sup class="tx-danger">*</sup></label>
        <input type="text" v-model="model.password" class="form-control">
      </div>
    </div>
    <div class="d-flex flex-row align-items-center justify-content-start pd-t-30 pd-b-30">
      <a href="javascript:void(0)" :disabled="!saveAllowed || loading" v-on:click="save" class="btn btn-sm btn-outline-primary d-flex flex-row align-items-center justify-content-start">
        <vue-feather class="mg-rem-r-1" type="plus" :size="14" v-if="!loading"></vue-feather>
        <vue-feather class="mg-rem-r-1" type="loader" :size="14" v-if="loading"></vue-feather>
        Добавить
      </a>
    </div>
  </form>
</template>

<script>
import AccountsClass from "@/entities/Accounts/AccountsClass";
import VueFeather from "vue-feather";
export default {
  components: { VueFeather },
  data() {
    return {
      Accounts: null,
      loading: false,
      uploadInProgress: false,

      model: {
        name: null,
        login: null,
        password: null,
      }
    }
  },
  computed: {
    saveAllowed: { get() { return this.model.name && this.model.login && this.model.password } }
  },
  mounted() {
    this.Accounts = new AccountsClass(this);
    this.Accounts.empty();
  },
  methods: {
    save() { return this.Accounts.create() }
  }
}
</script>