<template>
  <div class="d-flex flex-row align-items-center justify-content-start wd-100p-force mg-rem-b-3">
    <div class="form-group wd-100p-force">
      <div class="input-group">
        <input type="text" v-mask="'# (###) ###-##-##'"  class="form-control tx-12 pd-l-10 pd-r-10 pd-t-4 pd-b-4" placeholder="Поиск номера" v-model="searchNumber"/>
        <div class="input-group-append">
        <span class="input-group-text" v-on:click="searchNumber = ''">
          <vue-feather type="x" :size="14" :style="{'opacity': searchNumber ? '1' : '0', 'pointer-events': searchNumber ? 'auto' : 'none'}" stroke="var(--cui-dark)"></vue-feather>
        </span>
        </div>
      </div>
    </div>
    <icon-button :icon="'plus'"
                 v-on:click="openAddForm"
                 :size="14" :padding="'5px'" class="tx-12 btn-outline-success btn btn-sm mg-rem-l-3">
    </icon-button>
    <drop-down-button class="mg-rem-l-1" :items="links"></drop-down-button>
  </div>
  <div class="wd-100p-force">
    <data-table :columns="columnsActions" :items="[{}]" :max-height="16" :show-header="false" :show-search="false" :loading="false" :items-cnt="1"></data-table>
  </div>
  <div class="wd-100p-force" v-full-height="35">
    <data-table :columns="columns" :full-height="false" :items="displayItems" :max-height="16" :show-header="false" :show-search="false"></data-table>
  </div>
  <div class="d-flex flex-column align-items-start justify-content-start wd-100p-force mg-rem-t-2">
    <div class="d-flex flex-row align-items-start justify-content-start wd-100p-force">
      <div>Номера: </div>
      <div class="mg-rem-l-1">{{ numbers.length }}</div>
    </div>
    <div class="d-flex flex-row align-items-start justify-content-start wd-100p-force">
      <div>Файлы: </div>
      <div class="mg-rem-l-1">{{ files.length }}</div>
    </div>
  </div>
</template>

<style>
.tx-link {
  text-decoration: underline;
  color: var(--cui-info);
}
</style>

<script>
import {h} from 'vue';
import Create from "@/entities/Databanks/Numbers/Create";
import VueFeather from "vue-feather";
import {TheMask,mask} from 'vue-the-mask'
import DataTable from "@/elements/Table";
import IconButton from "@/elements/IconLink";
import DropDownButton from "@/elements/DropDownButton";
import {createConfirmDialog} from "vuejs-confirm-dialog";
import DialogNewDatabankNumbers from "@/elements/DialogNewDatabankNumbers";
import DialogUploadBlacklistExcel from "@/elements/DialogUploadBlacklistExcel";

export default {
  components: {IconButton, DataTable, Create, TheMask, VueFeather, DropDownButton },
  directives: {mask},
  props: {
    updateNumbers: { type: Function },
    updateFiles: { type: Function }
  },
  data() {
    return {
      searchNumber: '',

      numbers: [],
      files: [],

      totalSelected: false,

      columns: [
        {
          header: '',
          width: '25px',
          value: (item) => {
            return h('input', {
              class: 'flex-shrink-none',
              checked: item.checked,
              type: 'checkbox',
              onClick: () => { item.checked = !item.checked; }
            })
          }
        },
        {
          header: 'Номер телефона / Файл',
          value: (item) => {
            return h('div', {
              class: 'wd-100p-force ' + (item.file ? 'tx-link' : ''),
              onClick: () => { item.checked = !item.checked; }
            }, (item.file ? item.file.name : this.formatNumber(item.number)))
          }
        },
      ],
      columnsActions: [
        {
          header: '',
          width: '25px',
          value: () => {
            return h('input', {
              class: 'flex-shrink-none',
              checked: this.totalSelected,
              type: 'checkbox',
              disabled: this.loading,
              onClick: () => { this.toggleVisibleSelected() }
            })
          }
        },
        {
          header: '',
          float: 'right',
          value: () => {
            return h(IconButton, {
              className: 'btn btn-sm btn-link tx-12',
              icon: 'trash',
              label: 'Удалить выбранные',
              disabled: !this.someItemSelected,
              style: {'opacity': this.someItemSelected ? '1' : '0.3'},
              padding: '0',
              onClick: () => { this.removeSelected() }
            })
          }
        },
      ],

      links: [
        {
          label: 'Загрузить excel',
          click: () => {
            let input = document.createElement('input');
            input.type = 'file';
            input.onchange = _ => {
              if (input.files && input.files.length) {
                this.files.push({
                  checked: false,
                  file: input.files[0]
                });
                this.$props.updateFiles(this.files);
              }
            };
            input.click();
          }
        },
      ],
    }
  },
  computed: {
    displayItems: {
      get() {
        let result = [];

        if (this.files.length) {
          this.files.slice().reverse().forEach((item) => {
            result.push(item);
          })
        }

        if (this.numbers.length) {
          this.numbers.slice().reverse().forEach((item) => {
            if (!this.searchNumber) {
              result.push(item);
            } else {
              if (item.number.includes(this.searchNumber.replace(/\D/g,''))) {
                result.push(item);
              }
            }
          })
        }

        return result;
      }
    },
    someItemSelected: {
      get() {
        let result = false;
        if (this.displayItems.length) {
          this.displayItems.forEach((item) => {
            if (item.checked) { result = true; }
          })
        }
        return result;
      }
    }
  },
  methods: {
    openAddForm() {
      const { reveal } = createConfirmDialog(h(DialogNewDatabankNumbers));
      reveal();
    },
    formatNumber(number) {
      return '+' + number.substr(0, 1)+ ' (' + number.substr(1, 3)+ ') ' + number.substr(4,3) + '-' + number.substr(7,2) + '-' + number.substr(9, 2);
    },
    removeSelected() {
      if (this.numbers.length) {
        this.numbers = this.numbers.filter((item) => {
          return !item.checked;
        });
        this.files = this.files.filter((item) => {
          return !item.checked;
        });
        this.$props.updateNumbers(this.numbers);
        this.$props.updateNumbers(this.files);

        this.totalSelected = false;
      }
    },
    toggleVisibleSelected() {
      if (this.displayItems.length) {
        this.displayItems.forEach((item) => {
          if (this.numbers.length) {
            this.numbers.forEach((number, index) => {
              if (number.number === item.number) {
                this.numbers[index].checked = !this.totalSelected;
              }
            })
          }
        })

        this.totalSelected = !this.totalSelected;
      }
    },
    isInNumbers(number) {
      let result = false;
      if (this.numbers && this.numbers.length) {
        this.numbers.forEach((item) => {
          if (item.number && parseInt(item.number) === parseInt(number)) {
            result = true;
          }
        })
      }
      return result;
    }
  },
  mounted() {
    this.emitter.on('new-databank-add-numbers', (options) => {
      if (options.numbers && options.numbers.length) {
        options.numbers.forEach((number) => {
          if (!this.isInNumbers(number)) {
            this.numbers.push({
              checked: false,
              number: parseInt(number).toString()
            });
          }
        });
        this.$props.updateNumbers(this.numbers);
      }
    })
  }
}
</script>