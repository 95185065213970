<template>
  <div class="wd-100p-force">
    <number-formatted class="d-block wd-100p-force text-align-center fw-400 mg-b-3" v-if="loaded" v-bind:value="value + suffix"></number-formatted>
  </div>
</template>

<script>
import {ContentLoader} from "vue-content-loader";
export default {
  components: { ContentLoader },
  props: {
    item: {type: Object},
    field: { type: String },
    suffix: { type: String, default: '' }
  },
  computed: {
    loaded: {
      get() {
        return typeof this.$props.item !== 'undefined' && typeof this.$props.item[this.$props.field] !== 'undefined';
      }
    },
    value: {
      get() {
        return (typeof this.$props.item !== 'undefined' && typeof this.$props.item[this.$props.field] !== 'undefined') ? this.$props.item[this.$props.field] : null;
      }
    }
  }
}
</script>