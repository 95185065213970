<template>
  <CSidebar
    position="fixed"
    :unfoldable="sidebarUnfoldable"
    :visible="sidebarVisible"
    @visible-change="
      (event) =>
        $store.commit({
          type: 'updateSidebarVisible',
          value: event,
        })
    "
  >
    <CSidebarBrand>
      <div class="wd-100p-force pd-10 d-flex flex-row align-items-center justify-content-center">
        <img class="hg-max-35-force" src="/images/logo_space.png" />
      </div>
    </CSidebarBrand>
    <AppSidebarNav />
  </CSidebar>
</template>

<style>
.sidebar-brand { background-color: var(--cui-white); border-right: 1px solid var(--cui-gray-100); }
.sidebar-nav .nav-icon:first-child { margin-left: 0; }
.sidebar-nav .nav-icon { flex: 0 0 2rem; }
</style>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { AppSidebarNav } from './AppSidebarNav'
export default {
  name: 'AppSidebar',
  components: {
    AppSidebarNav,
  },
  setup() {
    const store = useStore()
    return {
      sidebarUnfoldable: computed(() => store.state.sidebarUnfoldable),
      sidebarVisible: computed(() => store.state.sidebarVisible),
    }
  },
}
</script>
