<template>
  <div class="d-flex flex-row align-items-center justify-content-between mg-rem-b-4" v-if="showSearch">
    <div class="input-group filter-input-group">
      <input class="form-control" v-model="search" placeholder="Введите данные для поиска">
      <div class="input-group-append">
        <span class="input-group-text" v-on:click="search = null;">
          <vue-feather type="x" :size="14" :style="{
            'color': search ? 'var(--cui-dark)' : 'var(--cui-light)'
          }"></vue-feather>
        </span>
      </div>
    </div>
    <icon-button  v-if="onCreateClick" v-on:click="onCreateClick"
        label="Создать" icon="plus" :size="14" :padding="'5px 10px'" class-name="tx-12 btn btn-sm btn-outline-primary" class="mg-rem-l-3" />
  </div>
  <div v-full-max-height="fullHeight">
    <CTable>
      <CTableHead v-if="showHeader" style="position: sticky; top: 0; left: 0; z-index: 2">
        <CTableRow>
          <CTableHeaderCell v-for="(column,index) in columns" :key="index" style="background-color: white; border-bottom: 1px solid rgb(216, 219, 224) !important;" :style="[{
          'width': column.width ? column.width : 'auto',
          'max-width': column.width ? column.width : 'auto',
          'position': column.position ? column.position : 'inherit',
          'left': column.position && column.position === 'sticky' ? '0' : 'inherit',
          'background-color': 'white',
          'border': 'none !important',
        }]">
            <div class="one-line-width" :style="{'float': column.float ? column.float : 'left', 'text-align': column.float ? column.float : 'left'}" v-html="column.header"></div>
          </CTableHeaderCell>
        </CTableRow>
        <CTableRow>
          <CTableHeaderCell style="padding: 0; border: none !important;" :colspan="columns.length">
            <div style="width: 100%; height: 1px; background-color: rgb(216, 219, 224)"></div>
          </CTableHeaderCell>
        </CTableRow>
      </CTableHead>
      <CTableBody v-if="!loading && elements.length">
        <CTableRow v-for="(item, index) in elements" :key="index" style="z-index: 1">
          <CTableDataCell :style="{
          'width': column.width ? column.width : 'auto',
          'max-width': column.width ? column.width : 'auto',
          'position': column.position ? column.position : 'inherit',
          'left': column.position && column.position === 'sticky' ? '0' : 'inherit',
          'background-color': 'white'
        }" v-for="(column,index) in columns" :key="index">
            <div class="wd-100p-force">
              <div class="one-line-width" :style="{'float': column.float ? column.float : 'left'}" v-if="typeof column.value(item) !== 'object'" v-html="column.value(item)"></div>
              <component :style="{'float': column.float ? column.float : 'left'}" v-if="typeof column.value(item) === 'object' && column.value(item).type" :is="column.value(item)"></component>
            </div>
          </CTableDataCell>
        </CTableRow>
      </CTableBody>
      <CTableBody v-if="!loading && !elements.length">
        <CTableRow>
          <CTableDataCell :colspan="columns.length">
            <small class="text-secondary">Данные не найдены</small>
          </CTableDataCell>
        </CTableRow>
      </CTableBody>
      <CTableBody v-if="loading">
        <CTableRow v-for="index in (itemsCnt > 100 ? 100 : (itemsCnt === 0 ? 1 : itemsCnt))" :key="index">
          <CTableDataCell :style="{
          'width': column.width ? column.width : 'auto',
          'max-width': column.width ? column.width : 'auto',
        }" v-for="(column,index) in columns" :key="index">
            <content-loader :width="column.width ? (parseInt(column.width) - 6) : 100" :height="(maxHeight)"></content-loader>
          </CTableDataCell>
        </CTableRow>
      </CTableBody>
    </CTable>
  </div>
</template>

<style>
.table { margin-bottom: 0; }
table tr td { vertical-align: middle; cursor: default; }
table tr th { vertical-align: bottom; cursor: default; }
table tr:last-child td { border: none; padding-bottom: 10px; }
</style>

<script>
import VueFeather from "vue-feather";
import IconButton from "@/elements/IconLink";
import {ContentLoader} from "vue-content-loader";
export default {
  components: {IconButton, VueFeather, ContentLoader},
  props: {
    loading: { type: Boolean, default: false },
    itemsCnt: { type: Number, default: 1 },
    columns: { type: Array, default: null },
    items: { type: Array, default: null },
    maxHeight: { type: Number, default: 26 },
    fullHeight: { type: Boolean, default: true },
    attachedHeader: { type: Boolean, default: false },

    showSearch: { type: Boolean, default: true },
    showHeader: { type: Boolean, default: true },

    onCreateClick: { type: Function, default: null },
    onItemClick: { type: Function, default: null }
  },
  computed: {
    elements: {
      get() {
        let result = [];
        if (this.$props.items && this.$props.items.length) {
          this.$props.items.forEach((item) => {
            if (!this.search || JSON.stringify(item).toLowerCase().includes(this.search.toLowerCase())) {
              result.push(item);
            }
          })
        }

        return result;
      }
    }
  },
  data() {
    return {
      search: null
    }
  }
}
</script>