<template>
  <div class="d-flex flex-row align-items-start justify-content-between wd-100p-force">
    <div class="wd-50p-force">
      <div class="card">
        <div class="card-body pd-2">
          <regions-list></regions-list>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import RegionsList from "@/entities/Regions/List";
export default {
  name: 'Regions',
  components: { RegionsList }
}
</script>
