<template>
    <distributions-list></distributions-list>
</template>
<script>
import DistributionsList from "@/entities/Distributions/List";
export default {
  name: 'Distributions',
  components: { DistributionsList }
}
</script>
