<template>
  <icon-button label="Отчет" icon="download" class-name="btn btn-sm btn-outline-info pd-0 tx-12" padding="2.5px 10px" :size="12" v-on:click="click"></icon-button>
</template>

<script>
import IconButton from "@/elements/IconLink";
import {createConfirmDialog} from "vuejs-confirm-dialog";
import {h} from "vue";
import DialogDownloadReport from "@/elements/DialogDownloadReport";

export default {
  components: {IconButton},
  props: {
    item: { type: Object },
  },
  methods: {
    click() {
      const { reveal } = createConfirmDialog(h(DialogDownloadReport, {
        distribution: this.$props.item
      }));
      reveal();
    }
  }
}
</script>