<template>
  <div class="d-flex flex-row align-items-start justify-content-between wd-100p-force">
    <div class="wd-60p-force">
      <div class="card">
        <div class="card-body pd-2">
          <div class="wd-100p-force">
            <data-table :columns="columns" :show-search="false" :items="items" :loading="loading" :items-cnt="50"></data-table>
          </div>
          <div class="d-flex flex-row align-items-center justify-content-start wd-100p-force mg-rem-t-4 tx-right table-navigator" v-show="!loading && items.length">
            <div class="d-flex flex-column align-items-start justify-content-start">
              <b  style="color: var(--cui-dark) !important;">Всего:</b>
              <number-formatted class="small" v-bind:value="count + ''"  style="color: var(--cui-dark) !important;"></number-formatted>
            </div>
            <div class="d-flex flex-column align-items-end justify-content-start wd-100p-force mg-rem-r-2">
              <b  style="color: var(--cui-dark) !important;">Страница:</b>
              <small  style="color: var(--cui-dark) !important;">{{ page }} / {{ maxPage }}</small>
            </div>
            <div class="form-group mg-l-auto flex-shrink-none">
              <div class="d-flex flex-row align-items-center justify-content-end">
                <input type="number" v-model="newPage" :min="0" :max="maxPage" style="width: 80px; padding: 4px 7px;" class="form-control form-control-select pagination-select">
                <icon-button :icon="'corner-down-right'"
                             v-on:click="goToPage"
                             :size="14" :padding="'5px 7px'" class="tx-12 btn-outline-primary btn btn-sm mg-rem-l-1">
                </icon-button>
                <icon-button :icon="'arrow-left'"
                             :disabled="newPage === 1"
                             v-on:click="decreasePage"
                             :size="14" :padding="'5px 7px'" class="tx-12 btn-outline-info btn btn-sm mg-rem-l-3">
                </icon-button>
                <icon-button :icon="'arrow-right'"
                             :disabled="newPage === maxPage"
                             v-on:click="increasePage"
                             :size="14" :padding="'5px 7px'" class="tx-12 btn-outline-info btn btn-sm mg-rem-l-1">
                </icon-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="wd-450-force">
      <div class="card">
        <div class="card-body">
          <div class="wd-100p-force">
            <div class="form-group mb-4">
              <label class="form-label text-black">Тип логов </label>
              <select class="form-control mg-rem-r-2" v-model="collection">
                <option value="blacklist">Добавление в стоп-лист</option>
                <option value="distributions_actions">Планировщик рассылок</option>
                <option value="number_stats">Сохранение данных о звонке</option>
                <option value="numbers_get">Запрос номеров голосом</option>
                <option value="remote_proxy_events">Внешние запросы о звонках</option>
                <option value="webhooks">Отправки webhook-ов</option>
              </select>
            </div>
          </div>
          <div class="wd-100p-force" v-show="collection === 'blacklist'">
            <div class="form-group mb-4">
              <label class="form-label text-black">Номер телефона</label>
              <input type="text" v-mask="'# (###) ###-##-##'"  class="form-control tx-12 pd-l-10 pd-r-10 pd-t-4 pd-b-4" v-model="logsFilter.number" />
            </div>
          </div>
          <div class="wd-100p-force" v-show="collection === 'webhooks'">
            <div class="form-group mb-4">
              <label class="form-label text-black">Успешность</label>
              <select class="form-control mg-rem-r-2" v-model="logsFilter.webhook_success">
                <option value=""></option>
                <option value="1">Только успешные</option>
                <option value="0">Только неуспешные</option>
              </select>
            </div>
            <div class="form-group mb-4">
              <label class="form-label text-black">Проект</label>
              <input type="text" class="form-control tx-12 pd-l-10 pd-r-10 pd-t-4 pd-b-4" v-model="logsFilter.webhook_project" />
            </div>
            <div class="form-group mb-4">
              <label class="form-label text-black">Номер телефона</label>
              <input type="text" v-mask="'# (###) ###-##-##'"  class="form-control tx-12 pd-l-10 pd-r-10 pd-t-4 pd-b-4" v-model="logsFilter.number" />
            </div>
          </div>
          <div class="wd-100p-force" v-show="collection === 'remote_proxy_events'">
            <div class="form-group mb-4">
              <label class="form-label text-black">Успешность</label>
              <select class="form-control mg-rem-r-2" v-model="logsFilter.proxy_success">
                <option value=""></option>
                <option value="1">Только успешные</option>
                <option value="0">Только неуспешные</option>
              </select>
            </div>
            <div class="form-group mb-4">
              <label class="form-label text-black">Номер телефона</label>
              <input type="text" v-mask="'# (###) ###-##-##'"  class="form-control tx-12 pd-l-10 pd-r-10 pd-t-4 pd-b-4" v-model="logsFilter.number" />
            </div>
          </div>
          <div class="wd-100p-force" v-show="collection === 'distributions_actions'">
            <div class="form-group mb-4">
              <label class="form-label text-black">Рассылка</label>
              <select class="form-control mg-rem-r-2" v-model="logsFilter.distribution_id">
                <option value=""></option>
                <option v-for="distribution in distributions" v-bind:value="distribution.id">{{distribution.id}}: {{distribution.name}}</option>
              </select>
            </div>
          </div>
          <div class="wd-100p-force" v-show="collection === 'numbers_get'">
            <div class="form-group mb-4">
              <label class="form-label text-black">IP</label>
              <input type="text" class="form-control tx-12 pd-l-10 pd-r-10 pd-t-4 pd-b-4" v-model="logsFilter.ip" />
            </div>
            <div class="form-group mb-4">
              <label class="form-label text-black">Оператор</label>
              <select class="form-control mg-rem-r-2" v-model="logsFilter.get_oper">
                <option value="">Любой</option>
                <option value="megafon">Мегафон</option>
              </select>
            </div>
            <div class="form-group mb-4">
              <label class="form-label text-black">Время запроса </label>
              <div class="d-flex flex-row align-items-start justify-content-start wd-100p-force">
                <div class="input-group mg-r-3">
                  <input type="number" v-model="logsFilter.get_time_min" class="form-control tx-12 pd-l-10 pd-r-10 pd-t-4 pd-b-4" />
                  <div class="input-group-append">
                    <span class="input-group-text tx-secondary" style="font-size: 10px;">min</span>
                  </div>
                </div>
                <div class="input-group mg-l-3">
                  <input type="number" v-model="logsFilter.get_time_max" class="form-control tx-12 pd-l-10 pd-r-10 pd-t-4 pd-b-4" />
                  <div class="input-group-append">
                    <span class="input-group-text tx-secondary" style="font-size: 10px;">max</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group mb-4">
              <label class="form-label text-black">Объем данных </label>
              <div class="d-flex flex-row align-items-start justify-content-start wd-100p-force">
                <div class="input-group mg-r-3">
                  <input type="number" v-model="logsFilter.get_count_min" class="form-control tx-12 pd-l-10 pd-r-10 pd-t-4 pd-b-4" />
                  <div class="input-group-append">
                    <span class="input-group-text tx-secondary" style="font-size: 10px;">min</span>
                  </div>
                </div>
                <div class="input-group mg-l-3">
                  <input type="number" v-model="logsFilter.get_count_max" class="form-control tx-12 pd-l-10 pd-r-10 pd-t-4 pd-b-4" />
                  <div class="input-group-append">
                    <span class="input-group-text tx-secondary" style="font-size: 10px;">max</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="wd-100p-force" v-show="collection === 'number_stats'">
            <div class="form-group mb-4">
              <label class="form-label text-black">Номер телефона</label>
              <input type="text" v-mask="'# (###) ###-##-##'"  class="form-control tx-12 pd-l-10 pd-r-10 pd-t-4 pd-b-4" v-model="logsFilter.number" />
            </div>
            <div class="form-group mb-4">
              <label class="form-label text-black">Тип звонка</label>
              <select class="form-control mg-rem-r-2" v-model="logsFilter.stats_incoming">
                <option value="">Любой</option>
                <option value="1">Только входящие</option>
                <option value="0">Только исходящие</option>
              </select>
            </div>
            <div class="form-group mb-4">
                <label class="form-label text-black">Успешность</label>
                <select class="form-control mg-rem-r-2" v-model="logsFilter.stats_success">
                  <option value=""></option>
                  <option value="1">Только успешные</option>
                  <option value="0">Только неуспешные</option>
                </select>
            </div>
            <div class="form-group mb-4">
              <label class="form-label text-black">Разговор</label>
              <select class="form-control mg-rem-r-2" v-model="logsFilter.stats_called">
                <option value=""></option>
                <option value="1">Выполнен</option>
                <option value="0">Не выполнен</option>
              </select>
            </div>
            <div class="form-group mb-4">
              <label class="form-label text-black">Нажатие</label>
              <select class="form-control mg-rem-r-2" v-model="logsFilter.stats_pressed">
                <option value=""></option>
                <option value="1">Совершено</option>
                <option value="0">Не совершено</option>
              </select>
            </div>
          </div>
          <div class="wd-100p-force">
            <div class="d-flex flex-row align-items-center justify-content-start">
              <icon-button label="Найти"
                           :disabled="loading" :icon="loading ? 'loader' : 'search'" :size="14" :padding="'3px 10px'"
                           :class="{'btn-outline-info' : !searchEnabled, 'btn-info': searchEnabled}"
                           v-on:click="searchData" class="tx-12 btn btn-sm mg-rem-r-1"></icon-button>
              <icon-button label="Очистить"
                           :disabled="loading" :icon="loading ? 'loader' : 'slash'" :size="14" :padding="'3px 10px'"
                           :class="{'btn-outline-secondary' : !searchEnabled, 'btn-secondary': searchEnabled}"
                           v-on:click="clearSearchData" class="tx-12 btn btn-sm mg-rem-r-1"></icon-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.pagination-select {
  border-color: var(--cui-gray-500) !important;
  text-align: right;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>

<script>
import {h} from 'vue';
import Logs from "@/entities/Logs/LogsClass";
import Distributions from "@/entities/Distributions/DistributionClass";
import DataTable from "@/elements/Table";
import IconButton from "@/elements/IconLink";
import Search from "@/entities/Logs/Search.vue";
import VueFeather from "vue-feather";
import {TheMask,mask} from 'vue-the-mask'
import NumberFormatted from "@/elements/NumberFormatted.vue";
import DateFormatted from "@/elements/DateFormatted.vue";

export default {
  components: {VueFeather, TheMask, Search, IconButton, DataTable, NumberFormatted },
  directives: { mask },
  props: {
  },
  data() {
    return {
      Logs: null,
      loading: false,
      count: 1,

      searchEnabled: false,
      searchNumber: null,
      page: 1,
      newPage: 1,
      maxOnPage: 50,
      maxPage: 1,

      pages: {},
      collection: 'blacklist',

      distributions: [],

      filter: {
      },

      logsFilter: {},
      baseLogsFilter: {
        number: null,
        distribution_id: null,
        ip: null,

        get_time_min: null,
        get_time_max: null,
        get_count_min: null,
        get_count_max: null,
        get_oper: "",

        stats_success: "",
        stats_incoming: "",
        stats_called: "",
        stats_pressed: "",

        proxy_success: "",

        webhook_success: "",
        webhook_project: null
      },

      columns: [],

      logsColumns: {
        'blacklist': [
          {
            header: 'Дата',
            width: '150px',
            value: (item) => { return h(DateFormatted, {class: 'wd-100p-force text-align-center', value: item.date_add}); }
          },
          {
            header: 'Номер телефона',
            width: '150px',
            value: (item) => { return this.formatNumber(item.number + ''); }
          },
          {
            header: 'Рассылка',
            value: (item) => { return this.getDistributionName(item.distribution_id) }
          },
        ],
        'distributions_actions' : [
          {
            header: 'Дата',
            width: '150px',
            value: (item) => { return h(DateFormatted, {class: 'wd-100p-force text-align-center', value: item.date_add}); }
          },
          {
            header: 'Рассылка',
            width: '300px',
            value: (item) => { return h('div', {class: 'wd-100p-force one-line-width'}, this.getDistributionName(item.distribution_id))}
          },
          {
            header: 'Действие',
            value: (item) => { return h('div', {class: 'wd-100p-force one-line-width'}, item.ru_action) }
          },
        ],
        'number_stats': [
          {
            header: 'Дата',
            width: '150px',
            value: (item) => { return h(DateFormatted, {class: 'wd-100p-force text-align-center', value: item.date_add}); }
          },
          {
            header: 'Номер телефона',
            width: '150px',
            value: (item) => { return this.formatNumber(item.number + ''); }
          },
          {
            header: 'Тип',
            width: '100px',
            value: (item) => { return h('div', {class: 'wd-100p-force text-align-center ' + (item.incoming ? 'tx-warning' : '')}, (item.incoming ? 'Вход' : 'Исход')) }
          },
          {
            header: 'Успешно',
            width: '100px',
            value: (item) => { return h('div', {class: 'wd-100p-force text-align-center ' + (item.success ? 'tx-success' : 'tx-danger')}, item.success) }
          },
          {
            header: 'Разговор',
            width: '100px',
            value: (item) => { return h('div', {class: 'wd-100p-force text-align-center ' + (item.called ? 'tx-success' : 'tx-danger')}, item.called) }
          },
          {
            header: 'Нажатие',
            width: '100px',
            value: (item) => { return h('div', {class: 'wd-100p-force text-align-center ' + (item.pressed ? 'tx-success' : 'tx-danger')}, item.pressed) }
          },
          {
            header: 'Длительность',
            width: '150px',
            value: (item) => { return h(NumberFormatted, {class: 'wd-100p-force text-align-center', value: item.duration}) }
          },
          {
            header: 'Дата вызова',
            value: (item) => { return h(DateFormatted, {class: 'wd-100p-force text-align-center', value: (item.called_at ? (new Date(item.called_at * 1000)).toLocaleString('ru-RU', {timeZone: "Europe/Moscow"}) : '')}); }
          },
        ],
        'numbers_get' : [
          {
            header: 'Дата',
            width: '150px',
            value: (item) => { return h(DateFormatted, {class: 'wd-100p-force text-align-center', value: item.date_add}); }
          },
          {
            header: 'IP',
            width: '150px',
            value: (item) => { return h('div', {class: 'wd-100p-force one-line-width'}, item.ip)}
          },
          {
            header: 'Время',
            width: '100px',
            value: (item) => { return h(NumberFormatted, {class: 'wd-100p-force text-align-center', value: item.time})}
          },
          {
            header: 'Получено',
            width: '100px',
            value: (item) => { return h(NumberFormatted, {class: 'wd-100p-force text-align-center', value: item.count})}
          },
          {
            header: 'Оператор',
            value: (item) => { return h('div', {class: 'wd-100p-force one-line-width'}, item.get?.oper ?? '')}
          }
        ],
        'remote_proxy_events': [
          {
            header: 'Дата',
            width: '150px',
            value: (item) => { return h(DateFormatted, {class: 'wd-100p-force text-align-center', value: item.date_add}); }
          },
          {
            header: 'Номер телефона',
            width: '150px',
            value: (item) => { return this.formatNumber((item.input?.call?.phone ?? '') + ''); }
          },
          {
            header: 'Проект',
            width: '90px',
            value: (item) => { return h('div', {class: 'wd-100p-force one-line-width text-align-center'}, (item.input?.project_id ?? '') + ''); }
          },
          {
            header: 'IVR',
            width: '90px',
            value: (item) => { return h('div', {class: 'wd-100p-force one-line-width text-align-center'}, (item.input?.call?.ivr_answers ?? '') + ''); }
          },
          {
            header: 'Действите',
            value: (item) => { return h('div', {class: 'wd-100p-force one-line-width'}, item.detail) }
          },
        ],
        'webhooks': [
          {
            header: 'Дата',
            width: '150px',
            value: (item) => { return h(DateFormatted, {class: 'wd-100p-force text-align-center', value: item.date_add}); }
          },
          {
            header: 'Номер телефона',
            width: '150px',
            value: (item) => { return this.formatNumber((item.phone ?? '') + ''); }
          },
          {
            header: 'Успешно',
            width: '100px',
            value: (item) => { return h('div', {class: 'wd-100p-force text-align-center ' + (item.success ? 'tx-success' : 'tx-danger')}, item.success) }
          },
          {
            header: 'Проект',
            width: '90px',
            value: (item) => { return h('div', {class: 'wd-100p-force one-line-width text-align-center'}, (item.project ?? '') + ''); }
          },
          {
            header: 'IVR',
            width: '90px',
            value: (item) => { return h('div', {class: 'wd-100p-force one-line-width text-align-center'}, (item.ivr ?? '') + ''); }
          },
          {
            header: 'Ошибка',
            value: (item) => { return h('div', {class: 'wd-100p-force one-line-width'}, item.success ? '' : (item.answer?.errorMessage ?? 'Undefined')) }
          },
        ],
      },

      items: [],
    }
  },
  watch: {
    page: {
      handler() {
        this.newPage = this.page;
      }
    },
    collection: {
      handler() {
        this.logsFilter = JSON.parse(JSON.stringify(this.baseLogsFilter));
        this.init();
      }
    }
  },
  methods: {
    getDistributionName(id) {
      let result = '';
      if (this.distributions.length) {
        this.distributions.forEach((distribution) => {
          if (parseInt(distribution.id) === parseInt(id)) {
            result = distribution.name;
          }
        })
      }

      return result;
    },
    increasePage() {
      this.page += 1;
      this.init();
    },
    decreasePage() {
      this.page -= 1;
      this.init();
    },
    goToPage() {
      if (this.newPage > this.maxPage) {
        this.page = this.maxPage;
      } else {
        this.page = this.newPage;
      }
      this.init();
    },
    formatNumber(number) {
      return '+' + number.substr(0, 1)+ ' (' + number.substr(1, 3)+ ') ' + number.substr(4,3) + '-' + number.substr(7,2) + '-' + number.substr(9, 2);
    },
    init() {
      this.columns = this.logsColumns[this.collection];
      let filter = {limit: this.maxOnPage, offset: (this.page - 1) * this.maxOnPage, logsFilter: this.logsFilter, logsCollection: this.collection};
      this.Logs.list(() => {
        const division = parseInt(parseInt(this.count) / this.maxOnPage);
        const reside = parseInt(this.count) - division * this.maxOnPage;
        this.maxPage = division + (reside > 0 ? 1 : 0);
      }, null, filter);
    },
    searchData() {
      this.page = 1;
      this.init();
    },
    clearSearchData() {
      this.page = 1;
      this.logsFilter = JSON.parse(JSON.stringify(this.baseLogsFilter));
      this.init();
    }
  },
  mounted() {
    this.logsFilter = JSON.parse(JSON.stringify(this.baseLogsFilter));
    this.Logs = new Logs(this);
    this.loading = true;
    (new Distributions({})).listNames((distributions) => {
      this.distributions = distributions;
      this.init();
    })
  }
}
</script>