<template>
  <expenses-main></expenses-main>
</template>
<script>
import ExpensesMain from "@/entities/Expenses/Main";
export default {
  name: 'Expenses',
  components: { ExpensesMain }
}
</script>
