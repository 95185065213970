<template>
  <div class="d-flex flex-row align-items-center justify-content-start mg-rem-b-2">
    <div class="card pd-0" style="margin-right: 20px; width: fit-content; min-width: 250px;">
      <div class="card-body">
        <monthly-payment-stats></monthly-payment-stats>
      </div>
    </div>
  </div>
  <div class="d-flex flex-column align-items-start justify-content-start wd-100p-force">
    <div class="card wd-100p-force">
      <div class="card-body pd-2 mg-rem-b-4">
        <div class="d-flex flex-row align-items-center justify-content-start">
          <div class="input-group filter-input-group wd-30p-force">
            <input class="form-control" v-model="searchString" placeholder="Введите данные для поиска">
            <div class="input-group-append">
              <span class="input-group-text" v-on:click="searchString = null;">
                <vue-feather type="x" :size="14" :style="{
                  'color': searchString ? 'var(--cui-dark)' : 'var(--cui-light)'
                }"></vue-feather>
              </span>
            </div>
          </div>
          <div class="multiselect-parent mg-rem-l-3 d-flex flex-row align-items-center justify-content-start">
            <multiselect class="wd-450-force" :close-on-select="false" :disabled="loadInProgress" placeholder="Фильтр по статусам" :hide-selected="true"  v-model="filterStatus.model" track-by="name" label="name" :options="filterStatus.options" :searchable="false" :allow-empty="true" :multiple="true">
              <template #option="props">
                <div class="d-inline-block wd-fit-content" style="margin-right: 4px;">
                  <a class="d-flex flex-row align-items-center justify-content-center flex-shrink-none btn" :class="[props.option.btnClass]">
                    <vue-feather :type="props.option.icon" :size="11"></vue-feather>
                    <div class="mg-rem-l-1 option__desc" v-html="props.option.name"></div>
                  </a>
                </div>
              </template>
              <template #tag="props">
                <div class="d-inline-block wd-fit-content" style="margin-right: 4px;" @mousedown.prevent="">
                  <div class="d-flex flex-row align-items-start justify-content-center">
                    <a class="d-flex flex-row align-items-center justify-content-center flex-shrink-none btn" @mousedown.prevent="" :class="[props.option.btnClass]" style="padding: 3px 10px !important; border-top-right-radius: 0; border-bottom-right-radius: 0">
                      <vue-feather :type="props.option.icon" :size="14"></vue-feather>
                    </a>
                    <a class="d-flex flex-row align-items-center justify-content-center flex-shrink-none btn" @mousedown.prevent="filterStatus.removeOption(filterStatus, props.option)" :class="[props.option.className]" style="padding: 3px 10px !important; border-top-left-radius: 0; border-bottom-left-radius: 0">
                      <vue-feather v-show="!props.option.locked" type="x" :size="14" ></vue-feather>
                    </a>
                  </div>
                </div>
              </template>
            </multiselect>
            <icon-button :size="14"
                         :padding="'5px 10px'"
                         :disabled="loadInProgress"
                         :label="'Применить'"
                         :class-name="'multilselect-action btn btn-sm btn-outline-primary tx-12'"
                         icon="refresh-cw"
                         v-on:click="applyFilterStatus()"
            ></icon-button>
          </div>
          <div class="d-flex flex-row align-items-center justify-content-start mg-l-auto">
            <icon-button :label="isSomeSelected ? 'Снять выделение' : 'Выделить все'"
                         :disabled="!(items && items.length)"
                         :size="14" :padding="'5px 10px'"
                         :class-name="'tx-12 btn btn-sm wd-180-force ' + (isSomeSelected ? 'btn-outline-warning' : 'btn-outline-info')"
                         icon="check-square"
                         v-on:click="toggleAll()"
            >
            </icon-button>
            <icon-button :size="14" :padding="'5px 10px'"
                         :disabled="!(items && items.length) || !isSomeSelected"
                         :label="'Скачать'"
                         :class-name="'tx-12 mg-rem-l-3 btn btn-sm btn-outline-info'"
                         icon="download"
                         v-on:click="downloadExcel()"
            >
            </icon-button>
          </div>
        </div>
      </div>
      <div class="card-body stats-block-parent pd-2">
        <div class="stats-block wd-100p-force">
          <data-table :columns="columns" :attached-header="true" :items="elements" :loading="loadInProgress" :max-height="17" :items-cnt="count" :show-search="false"></data-table>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
  .stats-block table {
    table-layout: fixed;
  }
  .stats-block table tr td {
    border-right: 1px solid rgba(0,0,0,0.1);
  }
  .tippy-content {
    background-color: var(--cui-dark);
    color: var(--cui-light);
    padding: 5px 10px;
    font-size: 12px;
  }


</style>

<script>
import {h} from "vue";
import DistributionClass from "@/entities/Distributions/DistributionClass";
import DataTable from "@/elements/Table";
import PreloadedValue from "@/entities/Stats/PreloadedValue";
import PreloadedStatus from "@/entities/Stats/PreloadedStatus";
import DropDownButton from "@/elements/DropDownButton";
import IconButton from "@/elements/IconLink";
import {createConfirmDialog} from "vuejs-confirm-dialog";
import DialogDownloadStats from "@/elements/DialogDownloadStats";
import { Tippy } from 'vue-tippy';
import MonthlyPaymentStats from "@/entities/Stats/MonthlyPaymentStats";
import Auth from "@/classes/Auth";
import NumberFormatted from "@/elements/NumberFormatted.vue";
import DateFormatted from "@/elements/DateFormatted.vue";
import Multiselect from 'vue-multiselect';
import VueFeather from "vue-feather";

export default {
  components: {
    VueFeather,
    IconButton, DropDownButton, DataTable, MonthlyPaymentStats, NumberFormatted, DateFormatted, Multiselect },
  data() {
    return {
      Distributions: null,
      loading: false,
      loadInProgress: false,
      count: 1,
      searchString: null,

      filterStatus: {
        model: [
        ],
        options: [
          {name: 'Создано', value: 'created', icon: 'star', className: 'btn-outline-info', btnClass: 'btn-info'},
          {name: 'Запущено', value: 'active', icon: 'play', className: 'btn-outline-primary', btnClass: 'btn-primary'},
          {name: 'Пауза', value: 'pause', icon: 'pause', className: 'btn-outline-warning', btnClass: 'btn-warning'},
          {name: 'Остановлено', value: 'stopped', icon: 'stop-circle', className: 'btn-outline-danger', btnClass: 'btn-danger'},
          {name: 'Завершено', value: 'finished', icon: 'check', className: 'btn-outline-success', btnClass: 'btn-success'},
        ],
        removeOption: function(filter, option) {
          let _filter = JSON.parse(JSON.stringify(filter));
          _filter.model.forEach((_option, key) => {
            if (_option.value === option.value) {
              filter.model.splice(key, 1);
            }
          });
        }
      },

      toggleInProgress: false,

      columns: [
        {
          header: 'Название',
          position: 'sticky',
          width: '350px',
          value: (item) => {
            return h('div', {
              class: 'wd-100p-force d-flex flex-row align-items-center justify-content-start',
              onClick: () => { item.checked = !item.checked; }
            }, [
              h('input', {
                class: 'flex-shrink-none mg-rem-r-2',
                checked: item.checked,
                type: 'checkbox'
              }),
              h('div', {}, item.name),
              /*
              h('div', {class: 'd-flex flex-column align-items-start justify-content-start'}, [
                  h('div', {}, item.id + ' ' + item.name),
                  h(DateFormatted, {class: 'tx-secondary', style: 'margin-top: 2px; font-size: 10px;', value: item.updated_at})
              ]),
               */

            ])
          }
        },
        {
          header: 'Состояние',
          width: '200px',
          value: (item) => { return h(PreloadedStatus, {item: item}) }
        },
        {
          header: 'Расход',
          width: '100px',
          value: (item) => { return h(NumberFormatted, {class: 'wd-100p-force text-align-center', value: item.price}) }
        },
        {
          header: 'Звонков',
          width: '100px',
          value: (item) => { return h(NumberFormatted, {class: 'wd-100p-force text-align-center', value: item.finished}) }
        },
        {
          header: 'Отвечено',
          width: '100px',
          value: (item) => { return h(NumberFormatted, {class: 'wd-100p-force text-align-center', value: item.called}) }
        },
        {
          header: 'Конверсия:<br/>в дозвон',
          width: '100px',
          value: (item) => { return h(PreloadedValue, {item: item, field: 'conversion_called', suffix: '%'}) }
        },
        {
          header: 'Лиды:<br/>создано',
          width: '100px',
          value: (item) => { return h(NumberFormatted, {class: 'wd-100p-force text-align-center', value: item.pressed}) }
        },
        {
          header: 'Лиды</br>(п.дата)',
          width: '100px',
          value: (item) => { return h(PreloadedValue, {item: item, field: 'lead_count_last'}) }
        },
        {
          header: 'Цена лида</br>(п.дата)',
          width: '100px',
          value: (item) => { return h(PreloadedValue, {item: item, field: 'lead_price_last'}) }
        },
        {
          header: 'Лиды:<br/>норматив',
          width: '100px',
          value: (item) => { return h(NumberFormatted, {class: 'wd-100p-force text-align-center', value: item.leads_max}) }
        },
        {
          header: 'Конверсия:<br/>в лид',
          width: '100px',
          value: (item) => { return h(PreloadedValue, {item: item, field: 'conversion_lead', suffix: '%'}) }
        },
        {
          header: 'Цена лида',
          width: '100px',
          value: (item) => { return h(PreloadedValue, {item: item, field: 'lead_price'}) }
        },
        {
          header: 'Не<br/>отвечено',
          width: '100px',
          value: (item) => { return h(NumberFormatted, {class: 'wd-100p-force text-align-center', value: item.uncalled}) }
        },
        {
          header: 'Входящие',
          width: '100px',
          value: (item) => { return h(NumberFormatted, {class: 'wd-100p-force text-align-center', value: item.incoming}) }
        },
        {
          header: 'Нажавшие<br/>0',
          width: '100px',
          value: (item) => { return h(NumberFormatted, {class: 'wd-100p-force text-align-center', value: item.blacklist_by_press}) }
        },
        {
          header: 'Процент<br/>нажавших 0',
          width: '100px',
          value: (item) => { return h(NumberFormatted, {class: 'wd-100p-force text-align-center', value: (item.conversion_blacklist_by_press + '%')}) }
        },
        {
          header: 'Аудио',
          width: '150px',
          value: (item) => {
            return h(
                Tippy,
                {'content': item.sound, class: 'one-line-width', delay: 100, duration: [null, null], animation: 'none'},
                {
                  default: () => item.sound
                });
          }
        },
        {
          header: 'База номеров',
          width: '150px',
          value: (item) => {
            return h(
              Tippy,
              {'content': item.databanks, class: 'one-line-width', delay: 100, duration: [null, null], animation: 'none'},
                {
                  default: () => item.databanks
                });
          }
        },
        {
          header: 'Дата<br/>создания',
          width: '100px',
          value: (item) => { return h(DateFormatted, {class: 'wd-100p-force text-align-center', value: item.created_date}) }
        },
        {
          header: 'Дата<br/>старта',
          width: '100px',
          value: (item) => { return h(DateFormatted, {class: 'wd-100p-force text-align-center', value: item.started_date}) }
        },
        {
          header: 'Дата<br/>окончания',
          width: '100px',
          value: (item) => { return h(DateFormatted, {class: 'wd-100p-force text-align-center', value: item.finished_date}) }
        },
        {
          header: 'Проект',
          width: '200px',
          value: (item) => { return item.project?.name ?? '' }
        },
      ],

      items: []
    }
  },
  computed: {
    elements: {
      get() {
        let result = [];
        if (this.items && this.items.length) {
          this.items.forEach((item) => {
            if (!this.searchString || JSON.stringify(item).toLowerCase().includes(this.searchString.toLowerCase())) {
              result.push(item);
            }
          })
        }

        return result;
      }
    },
    isSomeSelected: {
      get() {
        let result = false;

        if (this.items && this.items.length) {
          this.items.forEach((item) => {
            if (item.checked) { result = true }
          })
        }

        return result;
      }
    }
  },
  methods: {
    applyFilterStatus() {
      localStorage.setItem('stats-filter-status', JSON.stringify(this.filterStatus.model));
      this.init();
    },
    init() {
      this.loadInProgress = true;

      let filter = {'filter': {'status': []}};
      let statuses = this.filterStatus.model.map(a => a.value);

      if (statuses.includes('created')) { filter.filter.status.push('created'); }
      if (statuses.includes('active')) { filter.filter.status.push('active'); }
      if (statuses.includes('pause')) {
        filter.filter.status.push('paused');
        filter.filter.status.push('paused_out_time');
        filter.filter.status.push('paused_lead_limit');
        filter.filter.status.push('paused_lead_price_limit');
      }
      if (statuses.includes('finished')) {
        filter.filter.status.push('finished');
      }
      if (statuses.includes('stopped')) {
        filter.filter.status.push('stopped');
      }

      this.Distributions.listStats((items) => {
        this.items = items;
        this.loadInProgress = false;
      }, null, filter);
    },

    toggleAll() {
      if (this.items.length) {
        if (this.isSomeSelected) {
          this.items.forEach((item) => {
            item.checked = false;
          })
        } else {
          this.items.forEach((item) => {
            item.checked = true;
          })
        }
      }
    },
    downloadExcel() {
      if (this.items.length) {
        let pks = [];
        this.items.forEach((item) => {
          if(item.checked) {
            pks.push(item.id);
          }
        });

        const { reveal } = createConfirmDialog(h(DialogDownloadStats, {
          pks: pks
        }));
        reveal();
      }
    },

    initTable() {
      Auth.isAdmin().then((isAdmin) => {
        if (isAdmin) {
          this.columns = this.columns.concat([{
            header: 'Автор',
            width: '200px',
            value: (item) => { return item.author?.name ?? '' }
          }]);
        }
      })
    }
  },
  mounted() {
    const storageFilter = localStorage.getItem('stats-filter-status');
    this.filterStatus.model = storageFilter ? JSON.parse(storageFilter) : [
      {name: 'Создано', value: 'created', icon: 'star', className: 'btn-outline-info', btnClass: 'btn-info'},
      {name: 'Запущено', value: 'active', icon: 'play', className: 'btn-outline-primary', btnClass: 'btn-primary'},
      {name: 'Пауза', value: 'pause', icon: 'pause', className: 'btn-outline-warning', btnClass: 'btn-warning'},
      {name: 'Остановлено', value: 'stopped', icon: 'stop-circle', className: 'btn-outline-danger', btnClass: 'btn-danger'},
      {name: 'Завершено', value: 'finished', icon: 'check', className: 'btn-outline-success', btnClass: 'btn-success'},
    ];

    this.Distributions = new DistributionClass(this);
    this.init();
    this.initTable();
  }
}
</script>