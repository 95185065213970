<template>
  <div class="d-flex flex-column align-items-start justify-content-start wd-100p-force">
    <div class="d-flex flex-row align-items-center justify-content-start wd-100p-force">
      <icon-link :class="{'audio-player-inactive': !file.path}" v-on:click="playPause" :icon="isPlaying ? 'pause' : 'play'" icon-fill="var(--cui-info)" icon-stroke="var(--cui-info)" class-name="btn btn-sm btn-link tx-primary pd-0" :padding="'0'" :size="18"></icon-link>
      <small class="player-time wd-40-force flex-shrink-none tx-10 fw-300 tx-right" :class="{'tx-info': isPlaying}" v-html="currentTimeString"></small>
      <div class="d-flex flex-column align-items-start justify-content-start wd-100p-force mg-r-20 mg-l-20" :class="{
        'audio-player-inactive': !file.path
      }">
        <audio-player
            ref="audioPlayer"
            :audio-list="playlist.map(elm => elm.url)"
            theme-color="var(--cui-info)"
            :show-prev-button="false"
            :show-next-button="false"
            :show-play-button="false"
            :show-volume-button="false"
            :show-playback-rate="false"
            :is-loop="false"
        >
        </audio-player>
      </div>
      <small class="player-time wd-40-force flex-shrink-none tx-10 fw-300 tx-left" v-html="fullTimeString"></small>
    </div>
    <div class="d-flex flex-column align-items-start justify-content-start mt-4 wd-100p-force" v-if="upload">
      <div class="d-flex flex-row align-items-start justify-content-between mb-3 wd-100p-force">
        <b class="d-flex flex-row align-items-center justify-content-start fw-400">
          <vue-feather class="mg-rem-r-1 tx-danger" type="alert-circle" v-if="!uploadInProgress && (!file.path || !file.allowed)" :size="14"></vue-feather>
          <vue-feather class="mg-rem-r-1 tx-info" type="loader" v-if="uploadInProgress" :size="14"></vue-feather>
          <vue-feather class="mg-rem-r-1 tx-success" type="check" v-if="!uploadInProgress && file.path && file.allowed" :size="14"></vue-feather>
          <div style="border-bottom: 1px solid var(--cui-gray-300)">Данные о файле:</div>
        </b>
        <a class="p-0 d-flex flex-row align-items-center justify-content-start flex-shrink-none pos-relative">
          <vue-feather class="mg-rem-r-1" type="upload" :size="14"></vue-feather>
          Загрузить
          <input class="form-control hidden-invisible" @change="uploadFile($event)" type="file" accept=".wav,.wave">
        </a>
      </div>
      <div class="d-flex flex-column align-items-start justify-content-start wd-100p-force fw-300 lh-em-3">
        <div class="d-flex flex-row align-items-start justify-content-start wd-100p-force">
          <b>Имя:</b>
          <div class="mg-l-10 one-line-width" :class="{'tx-secondary': !file.filename}">{{file.filename ?? 'не задано'}}</div>
        </div>
        <div class="d-flex flex-row align-items-start justify-content-start wd-100p-force">
          <b>Формат:</b>
          <div class="mg-l-10 one-line-width" :class="{'tx-secondary': !file.extension}">{{file.extension ?? 'не задано'}}</div>
        </div>
        <div class="d-flex flex-row align-items-start justify-content-start wd-100p-force">
          <b>Размер:</b>
          <div class="mg-l-10 one-line-width" :class="{'tx-secondary': !file.size}">{{file.size ? formatBytes(file.size) : 'не задано'}}</div>
        </div>
        <div class="d-flex flex-row align-items-start justify-content-start wd-100p-force">
          <b>Критерии:</b>
          <div class="mg-l-10 one-line-width" :class="{'tx-secondary': file.allowed === null, 'tx-danger': file.allowed !== null && !file.allowed, 'tx-success': file.allowed !== null && file.allowed}">{{file.allowed ? 'Да' : (file.allowed !== null ? 'Нет' : '(не задано)')}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '@/styles/player.css';
import AudioPlayer from '@liripeng/vue-audio-player';
import VueFeather from "vue-feather";
import SoundClass from "@/entities/Sounds/SoundClass";
import IconButton from "@/elements/IconLink";
import IconLink from "@/elements/IconLink";
export default {
  components: {IconLink, IconButton, AudioPlayer, VueFeather },
  props: {
    file: { type: Object, default: null },
    upload: { type: Boolean, default: false },
    active: { type: Boolean, default: true }
  },
  computed: {
    playlist: {
      get() { return this.$props.active && this.fileModel && this.fileModel.path ? [
        {
          url: this.fileModel.path
        }
      ] : []}
    },
    fileModel: {
      get() { return this.$props.file },
      set(val) { return this.$emit('update:file', val)}
    },
    currentTimeString: {
      get() {
        const minutes = Math.floor(this.currentTime / 60);
        const seconds = Math.floor(this.currentTime - 60 * minutes);
        return this.pad(minutes, 2) + ':' + this.pad(seconds, 2);
      }
    },
    fullTimeString: {
      get() {
        const minutes = Math.floor(this.fullTime / 60);
        const seconds = Math.floor(this.fullTime - 60 * minutes);
        return this.pad(minutes, 2) + ':' + this.pad(seconds, 2);
      }
    }
  },
  data() {
    return {
      uploadAllowed: true,
      uploadInProgress: false,
      fileContent: null,

      isPlaying: false,
      currentTime: 0,
      fullTime: 0,

      zeroPointer: true
    }
  },
  methods: {
    pad(num, size) {
      num = num.toString();
      while (num.length < size) num = "0" + num;
      return num;
    },
    playPause() {
      if (this.file.path) {
        if (!this.$refs.audioPlayer.isPlaying) {
          this.emitter.emit('stop-player');
        }
       this.$refs.audioPlayer.isPlaying ? this.$refs.audioPlayer.pause() : this.$refs.audioPlayer.play();
      }
    },
    uploadFile($event) {
      if (this.uploadAllowed) {
        const target = $event.target;
        if (target && target.files && target.files.length) {
          this.uploadInProgress = true;
          this.uploadAllowed = false;

          const file = target.files[0];
          (new SoundClass(this)).upload({
            file: file
          }, (response) => {
            this.fileModel = response;
            this.uploadInProgress = false;
            setTimeout(() => {
              this.uploadAllowed = true;
            }, 1000);
          });
        }
      }
    },
    formatBytes(bytes, decimals = 2) {
      if (!+bytes) return '0 Bytes'

      const k = 1024
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']

      const i = Math.floor(Math.log(bytes) / Math.log(k))

      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    }
  },
  mounted() {
    this.emitter.on('stop-player', () => {
      if (this.$refs.audioPlayer) {
        this.$refs.audioPlayer.pause();
      }
    });

    this.$watch(() => { return this.$refs.audioPlayer.isPlaying }, (val) => { this.isPlaying = val; });
    this.$watch(() => { return this.$refs.audioPlayer.currentTime }, (val) => {
      this.currentTime = val + 1;
      const left = this.$refs.audioPlayer.$refs.audioProgressPoint.style.left;
      this.zeroPointer = parseInt(left) < 0;
    });
    this.$watch(() => { return this.$refs.audioPlayer.duration }, (val) => { this.fullTime = val + 1; });
  }

}
</script>