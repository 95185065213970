import BaseClass from "@/entities/BaseClass";

export default class RegionClass extends BaseClass {
    urls = {
        list:   '/rest/regions/list',
        listSimple:   '/rest/regions/list-simple',
        count:  '/rest/regions/count',
    }

    list(resolve = null, reject = null, customFilter = {}) {
        super.count(() => {
            super.list(resolve, reject, customFilter)
        }, null, customFilter);
    }

    listSimple(resolve = null, reject = null, customFilter = {}) {
        this.instance.loading = true;
        this.instance.items = [];

        this.makeRequest('listSimple', null, Object.assign({}, this.filter, customFilter), (data) => {
            this.instance.loading = false;
            this.instance.items = JSON.parse(JSON.stringify(data));

            if (typeof resolve === 'function') {
                resolve(data);
            }
        }, reject);
    }

    listItem(pk, resolve = null, reject = null, customFilter = {}) {
        this.makeRequest('list', null, Object.assign({filter: {id: pk}}, this.filter, customFilter), (data) => {
            const items = JSON.parse(JSON.stringify(data));
            const item = items.length > 0 ? items[0] : null;
            if (typeof resolve === 'function') {
                resolve(item);
            }
        }, reject);
    }
}
