<template>
  <div class="global-modal-window" v-bind:data-position="position" v-bind:data-id="identity">
    <div class="global-modal-window_background" v-if="bgActive" v-on:click="closeByBackdrop"></div>
    <transition name="slide-right">
      <div class="global-modal-window_body" v-show="bdActive" v-bind:style="{'width': width}">
        <div class="modal-header pd-20 pd-l-30-force pd-r-30-force d-flex flex-row align-items-start justify-content-between "
             style="border-bottom: 1px solid var(--cui-light);"
        >
          <modal-header :header="header" :element="element" :identity="identity"></modal-header>
        </div>
        <div class="modal-body p-5">
          <component v-if="view && view.type" :is="view" :element="element" class="mg-b-40-force"></component>
        </div>
      </div>
    </transition>
  </div>
</template>

<style>
  .global-modal-window .modal-body { background-color: white; height: 100vh; position: relative; }
  .global-modal-window {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1040;
    background-color: transparent;
  }
  .global-modal-window_background {
    background-color: rgba(0,0,15,0.6);
    width: 100%;
    height: 100%;
  }
  .global-modal-window_body {
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    height: 100vh;
    background-color: white;
  }
</style>

<script>
import ModalHeader from "@/elements/ModalHeader";
import {createConfirmDialog} from "vuejs-confirm-dialog";
import {h} from "vue";
import DialogForm from "@/elements/DialogForm";
export default {
  components: { ModalHeader },
  props: {
      identity: { type: String },
      position: { type: Number },
      options: { type: Object },
  },
  data() {
    return {
      active: false,

      bgActive: false,
      bdActive: false,

      element: {},
      header: [],
      view: null,
      detailUrl: '',

      width: null,

      closeBreakPoint: false,
      closeReveal: false,
    }
  },
  methods: {
    getWidth() {
      const smallTableWidth = 500;
      const tableWidth = smallTableWidth;
      const isMobile = screen.width <= 760;
      return isMobile ? '100vw' : ((this.$props.options.small ? smallTableWidth : tableWidth) - this.position * 15) + 'px';
    },
    recalculateParams() {
      this.width = this.getWidth();
    },

    open() {
      this.active = true;
      this.bgActive = true;
      this.header = this.$props.options.header;
      this.element = {id: this.$props.options.id};
      this.view = this.$props.options.view;
      this.recalculateParams();
      this.bdActive = true;
    },

    closeAction() {
      this.closeBreakPoint = false;
      this.closeReveal = false;
      this.bdActive = false;
      setTimeout(() => {
        this.element = {};
        this.header = [];
        this.view = null;
        this.bgActive = false;
        this.active = false;
        this.emitter.emit('remove-global-modal', {identity: this.identity});
      }, 400);
    },

    close() { this.closeAction(); },

    closeByHeader() {
      if (this.closeBreakPoint) {
        if (!this.closeReveal) {
          this.closeReveal = true;
          const { reveal } = createConfirmDialog(h(DialogForm, {
            header: 'Данные были изменены',
            description: 'Вы внесли на странице изменения. Если закрыть страницу без сохранения данных, то они будут утеряны. Вы уверены?'
          }));
          reveal().then((resp) => {
            if (!resp.isCanceled) {
              this.closeAction();
            } else {
              this.closeReveal = false;
            }
          });
        }

      } else {
        this.closeAction();
      }
    },

    closeByBackdrop() {
      if (this.closeBreakPoint) {
        const { reveal } = createConfirmDialog(h(DialogForm, {
          header: 'Данные были изменены',
          description: 'Вы внесли на странице изменения. Если закрыть страницу без сохранения данных, то они будут утеряны. Вы уверены?'
        }));
        reveal().then((resp) => {
          if (!resp.isCanceled) {
            this.closeAction();
          }
        });
      } else {
        this.closeAction();
      }
    }
  },
  mounted() {
    this.open();
    this.emitter.on('recalculate-params', (options) => {
      if (options.identity === this.identity) {
        this.recalculateParams();
      }
    });

    this.emitter.on('close-global-modal-from-header', (options) => {
      if (options.identity === this.identity && this.active) {
        this.closeByHeader();
      }
    });

    this.emitter.on('close-global-modal', (options) => {
      if (options.identity === this.identity && this.active) {
        this.closeByHeader();
      }
    });

    this.emitter.on('close-global-modal-force', (options) => {
      if (options.identity === this.identity && this.active) {
        this.closeAction();
      }
    });

    this.emitter.on('add-close-break-point', (options) => {
      if (options.identity === this.identity && this.active) {
        this.closeBreakPoint = options.closeBreakPoint;
      }
    });
  },
}
</script>
