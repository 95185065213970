<template>
  <vue-feather v-if="item.locked == '1'" class="mg-rem-r-1 tx-warning" type="lock" :size="12"></vue-feather>
  <vue-feather v-if="item.locked == '0'" class="mg-rem-r-1 tx-black" style="opacity: 0" type="unlock" :size="12"></vue-feather>
</template>

<script>
import VueFeather from "vue-feather";
export default {
  components: { VueFeather },
  props: {
    item: { type: Object },
  }
}
</script>