<template>
  <div>
    <modal-item v-for="(options, identity, index) in stack" :key="identity" :position="index" :options="options" :identity="options.identity" ></modal-item>
  </div>
</template>

<script>
import ModalItem from "@/elements/ModalItem";
export default {
  components: { ModalItem },
  data() {
    return {
      stack: {}
    }
  },
  mounted() {
    this.emitter.on('open-global-modal', (options) => {
      // eslint-disable-next-line no-prototype-builtins
      const isExists = this.stack.hasOwnProperty(options.identity);
      const isOnTop = isExists && this.stack[options.identity].index === this.stack.length;

      if (!isOnTop) {
        delete this.stack[options.identity];

        setTimeout(() => {
          this.stack[options.identity] = Object.assign({}, {index: this.stack.length}, options);

          setTimeout(() => {
            if (isExists) {
              Object.keys(this.stack).forEach((item) => {
                this.emitter.emit('recalculate-params', {identity: item});
              });
            }
          }, 100);
        }, 100);
      }
    });

    this.emitter.on('remove-global-modal', (options) => {
      delete this.stack[options.identity];
    });
  }
}
</script>
