<template>
  <data-table :columns="columns" :items="items" :on-create-click="createHandler" :loading="loading" :items-cnt="count"></data-table>
</template>

<script>
import {h} from "vue";
import ProjectsClass from "@/entities/Projects/ProjectsClass";
import DataTable from "@/elements/Table";
import EditButton from "@/entities/Projects/EditButton";
import ActualIcon from "@/entities/Projects/ActualIcon.vue";
import NumberFormatted from "@/elements/NumberFormatted.vue";

export default {
  components: { DataTable, NumberFormatted },
  data() {
    return {
      Projects: null,
      loading: false,
      count: 1,

      columns: [
        {
          header: 'ID',
          width: '100px',
          value: (item) => { return h(NumberFormatted, {class: 'wd-100p-force text-align-center', value: item.identifier}); }
        },
        {
          header: '',
          width: '50px',
          value: (item) => { return h(ActualIcon, {item: item}) }
        },
        {
          header: 'Название',
          value: (item) => { return item.name; }
        },
        {
          header: '',
          width: '30px',
          float: 'right',
          value: (item) => { return h(EditButton, {item: item}) }
        },
      ],
      items: [],
      createHandler: null
    }
  },
  methods: {
    init() {
      this.Projects.list();
    }
  },
  mounted() {
    this.Projects = new ProjectsClass(this);
    this.init();
    this.createHandler = () => {this.Projects.createForm()};

    this.emitter.on('projects-list-changed', () => {
      this.init();
    });

    this.emitter.on('project-changed', (options) => {
      if (options.id) {
        this.Projects.listItem(options.id, (changedItem) => {
          if (this.items.length && changedItem) {
            this.items.forEach((item, index) => {
              if (parseInt(item.id) === parseInt(changedItem.id)) {
                this.items[index] = changedItem;
              }
            })
          }
        })
      }
    })
  }
}
</script>