<template>
    <blacklist-list></blacklist-list>
</template>
<script>
import BlacklistList from "@/entities/Blacklist/List";
export default {
  name: 'Blacklist',
  components: { BlacklistList }
}
</script>
