import {h} from 'vue';
import BaseClass from "@/entities/BaseClass";
import ModalCard from "@/elements/ModalCard";
import Create from "@/entities/Sounds/Create";
import Edit from "@/entities/Sounds/Edit";

export default class SoundClass extends BaseClass {
    urls = {
        list:   '/rest/sounds/list',
        single: '/rest/sounds/single',
        create: '/rest/sounds/save',
        update: '/rest/sounds/save',
        remove: '/rest/sounds/delete',

        count:  '/rest/sounds/count',
        empty:  '/rest/sounds/empty',
        upload: '/rest/sounds/upload'
    }

    list(resolve = null, reject = null, customFilter = {}) {
        super.count(() => {
            super.list(resolve, reject, customFilter)
        }, null, customFilter);
    }

    upload(content, resolve, reject) {
        super.makeRequest('upload', content, null,(data) => {
            if (typeof resolve === 'function') {
                resolve(data);
            }
        }, reject);
    }

    update(resolve, reject) {
        super.update({model: this.instance.model}, (data) => {
            if (parseInt(data)) {
                this.instance.emitter.emit('sound-changed', {id: data});
            }
            if (typeof resolve === 'function') {
                resolve(data);
            }
        }, reject);
    }

    create(resolve, reject) {
        super.create({model: this.instance.model}, (data) => {
            this.instance.emitter.emit('sounds-list-changed');
            this.instance.emitter.emit('close-global-modal-force', {identity: 'create-sound'});
            if (typeof resolve === 'function') {
                resolve(data);
            }
        }, reject);
    }

    remove(pk, resolve, reject) {
        super.remove(pk, () => {
            this.instance.emitter.emit('sounds-list-changed');
            this.instance.emitter.emit('close-global-modal-force', {identity: 'sound-edit-' + pk});
        }, reject);
    }

    createForm() {
        return this.instance.emitter.emit(
            'open-global-modal',
            {
                view: h(Create),
                header: h(ModalCard, {main: 'Создание новой аудиозаписи', additional: 'Заполните все обязательные поля'}),
                identity: 'create-sound'
            }
        );
    }
    detailForm(item) {
        return this.instance.emitter.emit(
            'open-global-modal',
            {
                view: h(Edit),
                header: h(ModalCard, {main: item.name, additional: item.created_at}),
                identity: 'sound-edit-' + item.id,
                id: item.id,
            }
        );
    }
}
