<template>
  <drop-down-button label="Действия" :items="links"></drop-down-button>
</template>

<script>
import DatabankClass from "@/entities/Databanks/DatabanksClass";
import VueFeather from "vue-feather";
import DropDownButton from "@/elements/DropDownButton";
import {createConfirmDialog} from "vuejs-confirm-dialog";
import {h} from "vue";
import DialogDownloadDb from "@/elements/DialogDownloadDb";
export default {
  components: {DropDownButton, VueFeather},
  props: {
    item: { type: Object },
  },
  data() {
    return {
      links: [
        {
          label: 'Управление номерами',
          click: () => { (new DatabankClass(this)).detailForm(this.$props.item); }
        },
        {
          label: 'Скачать базу данных',
          click: () => {
            const { reveal } = createConfirmDialog(h(DialogDownloadDb, {
              databank: this.item
            }));
            reveal();
          }
        }
      ]
    }
  },
  methods: {
    click() {
      (new DatabankClass(this)).detailForm(this.$props.item);
    }
  }
}
</script>